import { millisecondsToHours } from "date-fns";

const convertMinsToHrsMins = (mins: number): string => {
	const h = Math.floor(mins / 60);
	const m = Math.floor(mins % 60);
	const s = Math.floor(((mins % 60) - m) * 60);
	const hour = h < 10 ? "0" + h : h;
	const min = m < 10 ? "0" + m : m;
	return `${hour}h ${!!min ? `${min}min` : ""} ${s ? `${s}sec` : ""}`;
};

export const convertMillisecondsToHours = (ms: number): number => {
	return millisecondsToHours(ms);
};

export const convertMillisecondsToMinutes = (ms: number): number => {
	return Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
};

export const getHoursLabel = (hours: number): string => {
	return hours === 1 ? "hr" : "hrs";
};

export const getMinutesLabel = (minutes: number): string => {
	return minutes === 1 ? "min" : "mins";
};

export default convertMinsToHrsMins;
