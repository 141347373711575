import React, { useCallback, useEffect, useState } from "react";

import { Box, Breadcrumbs, Button, makeStyles } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import { ReactComponent as FolderSvg } from "@remar/shared/dist/assets/icons/icon-folder.svg";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { CustomInputType } from "@remar/shared/dist/components/CustomInput/customInput.model";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";

import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import {
	createSubFolder,
	deleteFolder as deleteSubFolderAction,
	fetchFolders,
	fetchSubFolders,
	getFullState,
	initialState,
	populateInputs,
	setStateValue,
	validateForm
} from "store/features/FileVault/FileVault.slice";
import { FileVaultSubFolderFormRawData } from "store/features/FileVault/models";
import { _emit } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";
import theme from "theme/default";

import {
	ActiveLink,
	ButtonsRow,
	CheckboxContainer,
	CheckboxLabel,
	CircularProgress,
	DeleteCancelButton,
	DeleteConfirmButton,
	DeleteConfirmContent,
	EmptyPanel,
	FieldLabel,
	Folder,
	FolderIconContainer,
	FolderTitle,
	FoldersContainer,
	FormModal,
	HeaderSplit,
	Link,
	RoundedIcon,
	SearchPanel,
	SectionHeader,
	StyledCheckbox,
	CustomInput as StyledInput,
	SubFolderInputLabel,
	SvgIcon,
	TransparentBgContainer,
	VaultContainer
} from "../styles";

const useStyles = makeStyles(() => ({
	folder: {
		background: "rgb(23, 46, 75, 0.4);",
		margin: "10px"
	}
}));

const FileVaultSubfolder = () => {
	const { folderId } = useParams<{ folderId: string }>();
	const dispatch = useDispatch();
	const [showModal, setShowModal] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const [deleteFolder, setDeleteFolder] = useState<FileVaultSubFolderFormRawData | undefined>();
	const { fileVaultSubFolderForm, isLoading, subFolders: folders, content } = useSelector(getFullState);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const currentFolder = folderId && content.find(({ id }) => id === +folderId);
	const classes = useStyles();
	useEffect(() => {
		content.length === 0 && dispatch(fetchFolders(""));
		dispatch(fetchSubFolders({ parentId: +folderId, searchText: "" }));
	}, []);

	const handleDelete = folder => {
		setShowDeleteConfirm(true);
		setDeleteFolder(folder);
	};
	const handleSearchBarChange = useCallback((searchText: string) => {
		dispatch(fetchSubFolders({ parentId: +folderId, searchText }));
	}, []);
	return (
		<VaultContainer>
			<HeaderSplit>
				<div>
					<SectionHeader>FileVault</SectionHeader>
					<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
						<Link to="/">Dashboard</Link>
						{currentFolder ? (
							<Link to={routes.fileVault.getPath()}>File Vault</Link>
						) : (
							<ActiveLink to={routes.fileVault.getPath()}>File Vault</ActiveLink>
						)}
						{currentFolder && (
							<ActiveLink to={`${routes.fileVault.getPath()}/${currentFolder.id}`}>{currentFolder.name}</ActiveLink>
						)}
					</Breadcrumbs>
				</div>
				<div>
					<Button
						color="primary"
						variant={"contained"}
						onClick={() => {
							setShowModal(true);
							dispatch(setStateValue({ key: "fileVaultSubFolderForm.valid", value: false }));
						}}
						disabled={isLoading}
					>
						Create Sub Folder
					</Button>
				</div>
			</HeaderSplit>
			<SearchPanel>
				<div>{currentFolder && currentFolder.name}</div>
				<div>
					{currentFolder && (
						<SearchBarComponent
							inputValue={inputValue}
							setInputValue={setInputValue}
							onChange={handleSearchBarChange}
							isDebounced={true}
							debounceTime={1000}
							placeHolder={"Type to filter by keywords"}
						/>
					)}
				</div>
			</SearchPanel>

			{isLoading ? (
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			) : !folders.length ? (
				<EmptyPanel>
					<SvgIcon>
						<FolderSvg />
					</SvgIcon>
					There are no folders yet
				</EmptyPanel>
			) : (
				<FoldersContainer>
					{folders?.map(folder => (
						<Box key={`folder-${folder.id}`} className={classes.folder}>
							<Folder>
								<RoundedIcon>
									<FolderSvg />
								</RoundedIcon>
								<FolderTitle to={`${routes.fileVault.getPath()}/${folderId}/subFolders/${folder.id}/files`}>
									{folder.name}
								</FolderTitle>
								<ActionMenu
									customMenuItems={[
										{
											label: "Delete",
											onClick: () => handleDelete(folder),
											disabled: false,
											visible: true
										},
										{
											label: "Edit",
											onClick: () => {
												setShowModal(true);
												const { name, id, isNameVisible, isDateVisible, includesLessonContent, isVisible } = folder;
												dispatch(setStateValue({ key: "fileVaultSubFolderForm.valid", value: false }));
												dispatch(
													populateInputs({
														inputsStatePath: "fileVaultSubFolderForm.inputs",
														templatesStatePath: "fileVaultSubFolderForm.template",
														values: { name, id, isNameVisible, isDateVisible, includesLessonContent, isVisible }
													})
												);
											},
											disabled: false,
											visible: true
										}
									]}
								/>
							</Folder>
						</Box>
					))}
				</FoldersContainer>
			)}
			<SimpleModal
				open={showModal}
				theme={theme}
				title={fileVaultSubFolderForm?.inputs?.id?.value ? "Edit Sub Folder" : "Create new Sub Folder"}
				text={""}
				onClose={() => {
					dispatch(setStateValue({ key: "fileVaultSubFolderForm", value: initialState.fileVaultSubFolderForm }));
					setShowModal(false);
				}}
				extraContent={
					<>
						<SubFolderInputLabel>SubFolder Name</SubFolderInputLabel>
						<StyledInput
							width={150}
							mr={2}
							mt={2}
							theme={theme}
							options={{ ...defaultCustomInputOptions, inputData: fileVaultSubFolderForm.inputs.name }}
						/>
						<SubFolderInputLabel>What this folder should contain?</SubFolderInputLabel>
						<TransparentBgContainer>
							<CheckboxContainer>
								<StyledCheckbox
									width={150}
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: fileVaultSubFolderForm.inputs.isNameVisible }}
								/>
								<CheckboxLabel>
									<span>Lesson Name</span>
								</CheckboxLabel>
								<StyledCheckbox
									width={150}
									theme={theme}
									options={{
										...defaultCustomInputOptions,
										inputData: { value: true, type: CustomInputType.Checkbox, readonly: true, error: null }
									}}
								/>
								<CheckboxLabel>
									<span>Date</span>
								</CheckboxLabel>
								<StyledCheckbox
									width={150}
									theme={theme}
									options={{
										...defaultCustomInputOptions,
										inputData: { value: true, type: CustomInputType.Checkbox, readonly: true, error: null }
									}}
								/>
								<CheckboxLabel>
									<span>Files</span>
								</CheckboxLabel>
							</CheckboxContainer>
						</TransparentBgContainer>
						{!fileVaultSubFolderForm?.inputs?.id?.value && (
							<FieldLabel>
								<CheckboxContainer>
									<CheckboxLabel>
										<div>Allow Vit Files to be a part of this Sub-Folder</div>
										<span>
											By checking this, the files that under the main folder subscription will be a part of this
											sub-folder
										</span>
									</CheckboxLabel>
									<StyledCheckbox
										width={150}
										theme={theme}
										disabled={true}
										options={{
											...defaultCustomInputOptions,
											inputData: fileVaultSubFolderForm.inputs.includesLessonContent
										}}
									/>
								</CheckboxContainer>
							</FieldLabel>
						)}
					</>
				}
				footer={
					<ButtonsRow>
						<Button
							disabled={!fileVaultSubFolderForm.valid}
							color="primary"
							variant={"contained"}
							onClick={() => {
								if (fileVaultSubFolderForm.valid) {
									dispatch(createSubFolder({ parentId: +folderId, sideEffect: setShowModal }));
								}
							}}
						>
							{fileVaultSubFolderForm?.inputs?.id?.value ? "Done" : "Create Sub folder"}
						</Button>
					</ButtonsRow>
				}
				disableEnforceFocus
				disableAutoFocus
			/>
			<FormModal
				open={showDeleteConfirm}
				onClose={() => {
					setShowDeleteConfirm(false);
				}}
				disableEnforceFocus
				disableAutoFocus
			>
				<DeleteConfirmContent>
					<FolderIconContainer>
						<SvgIcon>
							<FolderSvg />
						</SvgIcon>
						<Box mt={2}>
							Are you sure you want to delete <b>&laquo;{deleteFolder?.name}&raquo;</b> sub folder?
						</Box>
					</FolderIconContainer>
					<ButtonsRow>
						<DeleteCancelButton variant={"contained"} onClick={() => setShowDeleteConfirm(false)}>
							Cancel
						</DeleteCancelButton>
						<DeleteConfirmButton
							variant={"contained"}
							subFolder
							onClick={() => {
								setShowDeleteConfirm(false);
								deleteFolder && dispatch(deleteSubFolderAction({ folderId: deleteFolder.id, parentId: +folderId }));
							}}
						>
							Delete Sub Folder
						</DeleteConfirmButton>
					</ButtonsRow>
				</DeleteConfirmContent>
			</FormModal>
		</VaultContainer>
	);
};

export default FileVaultSubfolder;
