import React from "react";

import { Box, TextField as MuiTextField } from "@material-ui/core";

import { Field } from "formik";

export const TextField = props => <MuiTextField {...props} variant="filled" hiddenLabel />;

import {
	BtnGroup,
	Checkbox,
	DatePickerComponent,
	InputNumber,
	InputNumberWithControls,
	MultiSelect,
	RadioInput,
	SelectInput,
	TextAreaInput,
	TimePicker
} from "./index";

const FormikField = ({
	name,
	placeholder,
	type,
	disabled = false,
	options,
	fullWidth = true,
	onChange,
	value,
	label,
	endAdornment,
	height,
	checked
}: {
	name: string;
	type: string;
	placeholder?: string;
	disabled?: boolean;
	options?: Record<string, string | number>[] | undefined;
	fullWidth?: boolean;
	onChange?: (val: string) => void;
	value?: unknown;
	label?: string;
	checked?: boolean;
	endAdornment?: React.ReactNode;
	height?: string;
}) => (
	<Field name={name} disabled={false}>
		{({ field }) => {
			switch (type) {
				case "text":
				case "number": {
					return (
						<TextField
							InputProps={{
								disableUnderline: true,
								style: {
									height: height || "48px"
								}
							}}
							placeholder={placeholder}
							type={type}
							fullWidth={fullWidth}
							disabled={disabled}
							{...field}
						/>
					);
				}
				case "textArea": {
					return <TextAreaInput placeholder={placeholder} disabled={disabled} {...field} />;
				}
				case "radio": {
					return <RadioInput options={options!} disabled={disabled} {...field} />;
				}
				case "checkbox": {
					return <Checkbox label={label} disabled={disabled} checked={checked} {...field} />;
				}
				case "btnGroup": {
					return <BtnGroup options={options!} onClick={onChange!} value={value as string} />;
				}
				case "select": {
					return <SelectInput options={options} {...field} value={value!} placeholder={placeholder} />;
				}
				case "multiSelect": {
					return (
						<MultiSelect options={options} disabled={disabled} {...field} value={value!} placeholder={placeholder} />
					);
				}
				case "date": {
					return <DatePickerComponent name={name} value={value} onChange={onChange} />;
				}
				case "time": {
					return <TimePicker value={value} onChange={onChange} />;
				}
				case "inputNumber": {
					return (
						<InputNumber
							{...field}
							InputProps={{
								disableUnderline: true,
								...(endAdornment && {
									endAdornment: endAdornment
								})
							}}
							inputProps={{
								style: {
									textAlign: "center"
								}
							}}
							disabled={disabled}
						/>
					);
				}
				case "inputNumberWithControls": {
					return (
						<Box display={"flex"} justifyContent={"flex-end"}>
							<InputNumberWithControls
								value={value}
								InputProps={{
									disableUnderline: true
								}}
								inputProps={{
									style: {
										textAlign: "center"
									}
								}}
								onChange={onChange}
								disabled={disabled}
								style={{
									width: "60px"
								}}
							/>
						</Box>
					);
				}
				default:
					return;
			}
		}}
	</Field>
);

export default FormikField;
