import * as Yup from "yup";

export const editBannerSchema = Yup.object().shape({
	trigger: Yup.string().required(),
	audience: Yup.string().required(),
	hourDuration: Yup.number().required(),
	minDuration: Yup.number().required(),
	isDismissible: Yup.boolean().required(),
	countDown: Yup.boolean().required(),
	messageText: Yup.string().required(),
	primaryButton: Yup.string().required(),
	couponId: Yup.number().required()
});
