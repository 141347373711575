import { BaseApiService, BaseDeleteDto, BaseFindDto, BaseFindOneDto } from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { UserSubscriptionType } from "@remar/shared/dist/models";

import {
	UserSubscriptionTypeAppleDataResponseDto,
	UserSubscriptionTypeCreateDto,
	UserSubscriptionTypeGetPaymentProviderPriceDataResponseDto,
	UserSubscriptionTypeGetStockKeepingDataResponseDto,
	UserSubscriptionTypeUpdateDto
} from "./dto";

import { URLS } from "../services.constants";

export class UserSubscriptionTypesService extends BaseApiService<
	UserSubscriptionType,
	UserSubscriptionTypeCreateDto,
	BaseFindDto,
	BaseFindOneDto,
	UserSubscriptionTypeUpdateDto,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}

	getPaymentProviderPriceData(
		integrationId: number,
		planId: string
	): Promise<UserSubscriptionTypeGetPaymentProviderPriceDataResponseDto> {
		return request(
			`${this.baseUrl}/payment-provider-price-data/${integrationId}/${encodeURIComponent(planId)}`,
			"GET"
		) as Promise<UserSubscriptionTypeGetPaymentProviderPriceDataResponseDto>;
	}

	getStockKeepingProductData(
		integrationId: number,
		productSKU: string
	): Promise<UserSubscriptionTypeGetStockKeepingDataResponseDto> {
		return request(
			`${this.baseUrl}/stock-keeping-product-data/${integrationId}/${encodeURIComponent(productSKU)}`,
			"GET"
		) as Promise<UserSubscriptionTypeGetStockKeepingDataResponseDto>;
	}

	getAppleProductData(productKey: string): Promise<UserSubscriptionTypeAppleDataResponseDto> {
		return request(
			`${this.baseUrl}/get-apple-product-info/${encodeURIComponent(productKey)}`,
			"GET"
		) as Promise<UserSubscriptionTypeAppleDataResponseDto>;
	}
}

export const userSubscriptionTypesService = new UserSubscriptionTypesService(URLS.USER_SUBSCRIPTION_TYPES);
