import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, IconButton, Modal, Tooltip } from "@material-ui/core";
import { Language } from "@material-ui/icons";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import { Avatar } from "@remar/shared/dist/components/Avatar";
import { IColumn } from "@remar/shared/dist/components/MaterialTable";
import { RemarBadge } from "@remar/shared/dist/components/RemarBadge";

import { ColumnHeader, StyledCellText, StyledCellWrapper } from "@remar/shared/dist/components/Table/styles";
import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import { EmptyState } from "@remar/shared/dist/layouts";
import WithTableContentLayout from "@remar/shared/dist/layouts/TableContentLayout";
import { Country, ILocation, UserSubscription } from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";

import { formatAdminDate, getBadgeTooltip } from "@remar/shared/dist/utils/myAccountUtils";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { RootState } from "store";
import { fetchCountries } from "store/features/Auth/auth.slice";
import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";
import { fetchLocations } from "store/features/ManageLocations/manageLocations.slice";
import {
	clearResetPwdLink,
	getAllStudents,
	getFullState,
	getTotalStudentsByCourse,
	resetUserPassword
} from "store/features/Students/students.slice";

import { UserModelDto } from "store/services";

import { UserGroupSvg, UsersSvg } from "assets/icons";
import { routes } from "core/constants";

import AddCATExams from "./components/AddCATExams";
import AddDaysInSubscription from "./components/AddDaysInSubscription";
import ChangeEmail from "./components/ChangeEmail";
import SubscriptionStatus from "./components/SubscriptionStatus";

import { PaidBlueIcon, PaidYellowIcon, StyledLocationIcon, TrialGreenIcon, TrialPinkIcon } from "./styles";

import ChangeSubscriptionModal from "../../../../Components/ChangeSubscriptionModal";

const MAX_TITLE_LENGTH = 15;
const trimText = (text: string) => {
	return text.length > MAX_TITLE_LENGTH ? `${text.slice(0, MAX_TITLE_LENGTH)}...` : text;
};

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Students", key: 1 }
];

interface IAddDays {
	fullName: string;
	userId: number;
	userEmail: string;
	subscription: UserSubscription;
}
interface IChangeEmail {
	fullName: string;
	userEmail: string;
	userId: number;
}

const LOCATION_PER_PAGE = 20;
const AddDaysModal = ({
	setShowAddDaysModal,
	showAddDaysModal: { fullName, userId, userEmail, subscription }
}: {
	setShowAddDaysModal: React.Dispatch<React.SetStateAction<IAddDays | null>>;
	showAddDaysModal: IAddDays;
}) => {
	const classes = useStyles();
	return (
		<Modal
			className={classes.modal}
			disableEnforceFocus
			disableAutoFocus
			open={true}
			onClose={() => setShowAddDaysModal(null)}
		>
			<AddDaysInSubscription
				fullName={fullName}
				userId={userId}
				userEmail={userEmail}
				subscription={subscription}
				fetchAccount={false}
				onClose={() => setShowAddDaysModal(null)}
			/>
		</Modal>
	);
};

const ManageStudents = () => {
	const dispatch = useDispatch();

	const history = useHistory();
	const [selectedCountries, setSelectedCountries] = useState<Array<Country>>([]);
	const [selectedLocations, setSelectedLocations] = useState<Array<ILocation>>([]);
	const [searchText, setSearchText] = useState("");
	const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
	const [showChangeEmailModal, setShowChangeEmailModal] = useState<IChangeEmail | null>(null);
	const [showAddDaysModal, setShowAddDaysModal] = useState<IAddDays | null>(null);
	const [showAddCATModal, setShowAddCATModal] = useState<IAddDays | null>(null);

	const {
		isLoading,
		students,
		page,
		perPage,
		totalItems,
		isStudentsCountLoading,
		studentStats,
		resetUserPasswordLoadingId
	} = useSelector(getFullState);
	const { countries } = useSelector((state: RootState) => state.auth);
	const { locations, page: locationPage } = useSelector(selectManageLocationsFullState);
	const { courses } = useSelector(getFullCourseState);
	const countriesHasObj = useMemo(
		() =>
			countries?.reduce((a, i) => {
				const { id, code, name } = i;
				a[id] = { code, name };
				return a;
			}, {}),
		[countries]
	);

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(getAllStudents({ searchText, page: 1, selectedCountries, selectedLocations }));
		},
		[dispatch, selectedCountries, selectedLocations]
	);

	useEffect(() => {
		dispatch(getTotalStudentsByCourse());
	}, [dispatch]);

	useEffect(() => {
		dispatch(getAllStudents({ selectedCountries, selectedLocations }));
	}, [selectedCountries, selectedLocations, dispatch]);

	useEffect(() => {
		if (!courses) {
			dispatch(fetchAllCourses(true));
		}
	}, [courses, dispatch]);

	useEffect(() => {
		if (!countries.length) {
			dispatch(fetchCountries(0));
		}
	}, [countries, dispatch]);

	useEffect(() => {
		if (!locations.length) {
			dispatch(fetchLocations({ perPage: LOCATION_PER_PAGE, page: locationPage, searchKeyword: "", filters: {} }));
		}
	}, [locations, dispatch]);

	const tableColumns: Array<IColumn<UserModelDto>> = useMemo(
		() => [
			{
				alignment: "left",
				width: 400,
				label: <ColumnHeader>Students</ColumnHeader>,
				Cell: ({ rowData }: { rowData: UserModelDto }) => {
					const { id, firstName, lastName, profileImageUrl, email, userShippingDetails, badges } = rowData;
					const fullName = [firstName, lastName].join(" ");
					const { createdAt, typeId } = badges[0] || {};
					const toolTipText = getBadgeTooltip(badges, createdAt);

					return (
						<StyledCellWrapper>
							<Avatar
								variant="rounded"
								fullName={fullName}
								style={{ cursor: "pointer" }}
								profileImageUrl={profileImageUrl!}
								onClick={() =>
									history.push({
										pathname: `${routes.manageStudents.getPath()}/${id}`,
										state: {
											studentInfo: rowData
										}
									})
								}
							/>
							<Box>
								<StyledCellText margin="5px 0 0 0">
									{fullName}
									{!!badges?.length && (
										<Tooltip title={toolTipText}>
											<IconButton size="small">
												<RemarBadge type={typeId} />
											</IconButton>
										</Tooltip>
									)}
								</StyledCellText>
								<StyledCellText margin="0 0 5px 0">{email}</StyledCellText>
								<StyledCellText margin="0 0 5px 0">
									{userShippingDetails && countriesHasObj && countriesHasObj[userShippingDetails.countryId]?.name}
								</StyledCellText>
							</Box>
						</StyledCellWrapper>
					);
				},
				dataKey: "name"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subscription</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => (
					<StyledCellWrapper>
						<StyledCellText>
							<SubscriptionStatus subscriptions={subscriptions!} />
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "subscription"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Location</ColumnHeader>,
				Cell: ({ rowData: { isMainLocation, allowedLocations } }) => (
					<StyledCellWrapper>
						<StyledCellText>
							{isMainLocation ? (
								"Main"
							) : (
								<Box display={"flex"} flexDirection={"column"}>
									{(allowedLocations || []).map(loc => (
										<Box key={loc.id}>{loc.name}</Box>
									))}
								</Box>
							)}
						</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "location"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Enrolled Course</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => {
					const type = getCurrentSubscription(subscriptions).type;
					const allowedCourseName = type ? type.allowedCourses![0].name : "-";
					return (
						<StyledCellWrapper>
							<StyledCellText>{allowedCourseName.toUpperCase()}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "course"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Role</ColumnHeader>,
				Cell: ({}) => (
					<StyledCellWrapper>
						<StyledCellText>Student</StyledCellText>
					</StyledCellWrapper>
				),
				dataKey: "role"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Enrollment Date</ColumnHeader>,
				Cell: ({ rowData: { createdAt } }) => {
					return (
						<StyledCellWrapper>
							<StyledCellText>{createdAt && formatAdminDate(createdAt)}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "day"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subscription Start Date</ColumnHeader>,
				Cell: ({ rowData: { subscriptions } }) => {
					const { createdAt, data } = getCurrentSubscription(subscriptions);
					const subStartDate = data?.subscriptionStartDate ?? createdAt;
					return (
						<StyledCellWrapper>
							<StyledCellText>{subStartDate ? formatAdminDate(subStartDate) : "-"}</StyledCellText>
						</StyledCellWrapper>
					);
				},
				dataKey: "startDate"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData: { subscriptions, firstName, lastName, id, email, isCATAvailable, resetPwdLink } }) => {
					const fullName = [firstName, lastName].join(" ");
					const subscription = getCurrentSubscription(subscriptions);
					const { type } = subscription;
					const { isTrial } = type ?? {};
					return (
						<ActionMenu
							customMenuItems={[
								{
									label: "Change Subscription",
									onClick: () => setSelectedUserId(id),
									visible: !!type,
									disabled: type && type.userSubscriptionTypeCategoryId !== UserSubscriptionTypeCategoriesEnum.Course
								},
								{
									label: "Change Email",
									onClick: () => setShowChangeEmailModal({ fullName, userEmail: email, userId: id }),
									visible: true,
									disabled: false
								},
								{
									label: "Add Days",
									onClick: () =>
										setShowAddDaysModal({
											fullName,
											userId: id,
											userEmail: email,
											subscription
										}),
									visible: !!type && !isTrial,
									disabled: false
								},
								{
									label: "Add CAT exams",
									onClick: () => setShowAddCATModal({ fullName, userId: id, userEmail: email, subscription }),
									visible: !isTrial && isCATAvailable,
									disabled: false
								},
								{
									label: resetPwdLink ? "Copy Link" : "Reset Password",
									onClick: resetPwdLink
										? async () => {
												await navigator.clipboard.writeText(resetPwdLink).then(() => {
													dispatch(clearResetPwdLink(id));
												});
										  }
										: () => dispatch(resetUserPassword(id)),
									visible: true,
									disabled: id === resetUserPasswordLoadingId
								}
							]}
						/>
					);
				},
				dataKey: "menu"
			}
		],
		[countriesHasObj, dispatch, history, resetUserPasswordLoadingId]
	);

	const showChangeSubscriptionModal = useMemo(() => {
		if (!selectedUserId) return null;
		const selectedUser = students?.find(s => s.id === selectedUserId);
		if (!selectedUser) return null;
		const { subscriptions, firstName, lastName, id } = selectedUser;
		const fullName = [firstName, lastName].join(" ");
		const subscription = getCurrentSubscription(subscriptions);
		const { id: subscriptionId, hasExpired, isCancelled, type } = subscription;
		const { isTrial, allowedCourses } = type ?? {};
		const notAllowedCourses = allowedCourses
			? courses?.filter(course => course.id !== allowedCourses![0].id) || []
			: courses;
		return {
			fullName,
			userId: id,
			subscriptionId,
			hasExpired,
			isCancelled,
			isTrial,
			allowedCourses,
			notAllowedCourses,
			subscription
		};
	}, [courses, selectedUserId, students]);

	const infoBlockItems = useMemo(() => {
		return !isStudentsCountLoading && studentStats
			? [
					{
						title: "Total Students",
						text: studentStats?.totalStudents,
						customBgColor: "hsla(213, 53%, 19%, 0.4)",
						IconSvg: UsersSvg
					},
					{
						title: "Recurring",
						text: studentStats?.totalRecurring,
						customBgColor: "hsla(280, 31%, 31%, 0.4)",
						IconSvg: UserGroupSvg
					},

					...studentStats?.courses.reduce((a, c, i) => {
						const { courseName, trialUsersCount, initialUsersCount, recurringUsersCount } = c;
						a.push({
							title: `${trimText(courseName)} Paid`,
							text: initialUsersCount + recurringUsersCount,
							customBgColor: i % 2 ? "hsla(200, 75%, 21%, 0.4)" : "hsla(45, 58%, 19%, 0.4)",
							IconSvg: i % 2 ? PaidBlueIcon : PaidYellowIcon
						});
						a.push({
							title: `${trimText(courseName)} Trial`,
							text: trialUsersCount,
							customBgColor: i % 2 ? "hsla(309, 31%, 31%, 0.4)" : "hsla(154, 83%, 21%, 0.4)",
							IconSvg: i % 2 ? TrialPinkIcon : TrialGreenIcon
						});
						return a;
					}, [])
			  ]
			: undefined;
	}, [isStudentsCountLoading, studentStats]);

	return (
		<>
			<WithTableContentLayout
				heading="Manage Students"
				breadcrumb={breadcrumb}
				isLoading={isLoading}
				emptyState={<EmptyState description="No Students yet" />}
				infoBlockItems={infoBlockItems}
				onChangePage={page =>
					dispatch(
						getAllStudents({
							page,
							selectedCountries,
							selectedLocations,
							...(searchText && { searchText })
						})
					)
				}
				tableTitle="Student Accounts"
				filterBlock={
					<>
						<Box mx={1}>
							<AutocompleteFilter
								options={locations}
								value={selectedLocations}
								setValue={setSelectedLocations}
								filterName={"Locations"}
								filterIcon={<StyledLocationIcon />}
								noOptionsText={"No Location Selected"}
								loadMoreResults={() => {
									dispatch(
										fetchLocations({
											perPage: LOCATION_PER_PAGE,
											page: locationPage + 1,
											searchKeyword: "",
											filters: {},
											infinite: true
										})
									);
								}}
								onTextInputChange={text => {
									dispatch(
										fetchLocations({
											perPage: LOCATION_PER_PAGE,
											page: 1,
											searchKeyword: text,
											filters: {}
										})
									);
								}}
							/>
						</Box>
						<Box mx={1}>
							<AutocompleteFilter
								options={countries as Country[]}
								value={selectedCountries}
								setValue={setSelectedCountries}
								filterName={"Countries"}
								filterIcon={<Language htmlColor="#C5CEE0" />}
								noOptionsText={"No countries found"}
							/>
						</Box>
					</>
				}
				totalItems={totalItems}
				totalEntities={studentStats?.totalStudents}
				perPage={perPage}
				setSearchText={setSearchText}
				searchText={searchText}
				handleSearchBarChange={handleSearchBarChange}
				page={page}
				tableColumns={tableColumns}
				data={students || []}
			/>
			{!!showChangeEmailModal && (
				<ChangeEmail showChangeEmailModal={showChangeEmailModal} onClose={() => setShowChangeEmailModal(null)} />
			)}
			{!!showChangeSubscriptionModal && (
				<ChangeSubscriptionModal
					setShowChangeSubscriptionModal={setSelectedUserId}
					showChangeSubscriptionModal={showChangeSubscriptionModal}
				/>
			)}
			{!!showAddDaysModal && (
				<AddDaysModal setShowAddDaysModal={setShowAddDaysModal} showAddDaysModal={showAddDaysModal} />
			)}
			{!!showAddCATModal && <AddCATExams showAddCATModal={showAddCATModal} onClose={() => setShowAddCATModal(null)} />}
		</>
	);
};

export default ManageStudents;
