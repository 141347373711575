import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, Grid, Typography, useTheme } from "@material-ui/core";

import ButtonShared from "@remar/shared/dist/components/Button";
import AccordionLessonsProgress from "@remar/shared/dist/components/CourseCompletion/AccordionLessonsProgress";
import CertificateCard from "@remar/shared/dist/components/CourseCompletion/CertificateCard";
import CourseProgressCard from "@remar/shared/dist/components/CourseCompletion/CourseProgressCard";
import NextCourseCard from "@remar/shared/dist/components/CourseCompletion/NextCourseCard";
import { useCourseCompletionDetails } from "@remar/shared/dist/components/CourseCompletion/useCourseCompletionDetails";
import { UserTypes } from "@remar/shared/dist/constants";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import { IUserCourseCompletionDetailForAdmin } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { pick } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { RootState } from "store";

import {
	fetchStudentCertificateLogs,
	getStudentAccount,
	getStudentCourseCompletion,
	resetCourseProgress
} from "store/features/Students/students.slice";

import { routes } from "core/constants";

const DetailedCourseCompletion = () => {
	const dispatch = useDispatch();
	const { id } = useParams<{ id: number }>();
	const theme = useTheme<IExtendedTheme>();
	const [confirmModal, setConfirmModal] = useState(false);

	const {
		studentAccountInfo: studentInfo,
		studentSubscription,
		studentAccountLoading,
		courseCompletion,
		courseCompletionLoading,
		certificateLogs,
		isResettingCourseProgress
	} = useSelector((state: RootState) => state.students);
	const { nextLesson, sections } = useCourseCompletionDetails(courseCompletion as IUserCourseCompletionDetailForAdmin);

	const { firstName, lastName } = useMemo(() => {
		if (!studentInfo) {
			return {};
		}

		return pick(studentInfo, ["firstName", "lastName", "email"]);
	}, [studentInfo]);

	const breadcrumb = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Manage Students", key: 1, link: routes.manageStudents.getPath() },
			{ title: `${firstName} ${lastName}`, key: 2, link: `${routes.manageStudents.getPath()}/${id}` },
			{ title: "Course Completion", key: 3 }
		],
		[firstName, lastName, id]
	);

	useEffect(() => {
		dispatch(getStudentAccount({ id }));
		dispatch(fetchStudentCertificateLogs({ userId: id, typeId: 2 }));
	}, [dispatch, id]);

	const courseId = useMemo(() => studentSubscription?.subscription.type?.allowedCourses[0].id, [studentSubscription]);
	useEffect(() => {
		if (id && studentSubscription?.subscription) {
			dispatch(
				getStudentCourseCompletion({
					userId: id,
					courseId: studentSubscription.subscription.type?.allowedCourses[0].id as number
				})
			);
		}
	}, [id, courseId]);

	const actions = (
		<Button variant={"contained"} color={"primary"} onClick={() => setConfirmModal(true)}>
			Reset Course Progress
		</Button>
	);

	const onCloseConfirm = () => {
		setConfirmModal(false);
	};

	if (studentAccountLoading || courseCompletionLoading) {
		return <ContentLoader />;
	}

	return (
		<Wrapper heading={"Course Completion"} breadcrumb={breadcrumb} actions={actions}>
			<Box mt={2}>
				<Grid container spacing={2}>
					<Grid container spacing={2} item xs={12}>
						<Grid item xs={12} lg={3}>
							<CourseProgressCard
								courseCompleted={courseCompletion?.isCompleted}
								percentage={courseCompletion?.percentage}
								courseName={studentSubscription?.subscription?.subscriptionName as string}
								userType={UserTypes.Admin}
							/>
						</Grid>
						<Grid item xs={12} lg={3}>
							<NextCourseCard courseCompleted={courseCompletion?.isCompleted} nextLesson={nextLesson} />
						</Grid>
						<Grid item xs={12} lg={6}>
							<CertificateCard
								courseCompleted={courseCompletion?.isCompleted as boolean}
								certificate={courseCompletion?.certificatePdfUrl as string}
								certificateLogs={certificateLogs}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Card>
							<Box mb={2}>
								<Typography style={{ fontSize: "18px", fontWeight: 700 }}>
									Lessons Required for Course Completion
								</Typography>
							</Box>
							{sections && <AccordionLessonsProgress sections={sections} nextLesson={nextLesson} />}
						</Card>
					</Grid>
				</Grid>
			</Box>
			<SimpleModal
				theme={theme}
				title="Reset Progress"
				open={confirmModal}
				onClose={onCloseConfirm}
				text="Are you sure you want to reset student's course progress?"
				footer={
					<Box display={"flex"} gridGap={6}>
						<ButtonShared
							loading={false}
							variant="outlined"
							color="basic"
							disabled={isResettingCourseProgress}
							size="medium"
							onClick={onCloseConfirm}
						>
							Cancel
						</ButtonShared>
						<ButtonShared
							loading={isResettingCourseProgress}
							variant={"filled"}
							color={"primary"}
							size="medium"
							disabled={isResettingCourseProgress}
							onClick={() =>
								dispatch(
									resetCourseProgress({
										userId: id,
										cb: () => {
											onCloseConfirm();
											dispatch(
												getStudentCourseCompletion({
													userId: id,
													courseId: studentSubscription?.subscription.type?.allowedCourses[0].id as number
												})
											);
										}
									})
								)
							}
						>
							Yes, Reset
						</ButtonShared>
					</Box>
				}
			/>
		</Wrapper>
	);
};

export default DetailedCourseCompletion;
