import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearJwt, getJwt, setJwt } from "@remar/shared/dist/api/jwt";
import { Country, User } from "@remar/shared/dist/models";
// import { LoginType } from "Store/types";

import { AppThunk, RootState } from "store";
import { UserLoginDto, countriesService, usersService } from "store/services";

interface AuthState {
	token: string | null;
	user: User | null;
	isLoading: boolean;
	isLoggedIn: boolean;
	errorMessage: string;
	countries: Country[];
}

const initialToken = getJwt();

const initialState: AuthState = {
	token: initialToken || null,
	user: null,
	isLoading: false,
	isLoggedIn: !!initialToken,
	errorMessage: "",
	countries: []
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setLoading: state => {
			state.errorMessage = "";
			state.isLoading = true;
		},
		setUser: (state, action: PayloadAction<{ user: User }>) => {
			state.user = action.payload.user;
		},
		success: state => {
			state.errorMessage = "";
			state.isLoading = false;
		},
		failed: (state, action: PayloadAction<{ message: string }>) => {
			state.errorMessage = action.payload.message;
			state.isLoading = false;
		},
		setAuth: (state, action: PayloadAction<{ token: string; user: User }>) => {
			state.isLoggedIn = true;
			state.token = action.payload.token;
			state.user = action.payload.user;
		},
		clearAuth: state => {
			state.isLoggedIn = false;
			state.token = null;
			state.user = null;
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchCountries.fulfilled, (state, action) => {
			const countries = action.payload.items as Country[];
			const USA = countries.find(({ code }) => code === "US")!;
			if (USA) {
				countries.unshift(USA);
			}
			state.countries = countries;
		});
	}
});

export const { setAuth, clearAuth, failed, success, setLoading, setUser } = authSlice.actions;

export const login =
	(data: UserLoginDto): AppThunk =>
	dispatch => {
		dispatch(setLoading());
		usersService
			.login(data)
			.then(r => {
				setJwt(r.sessionToken, "", r.refreshToken);
				dispatch(setAuth({ token: r.sessionToken, user: r.user }));
				dispatch(success());
			})
			.catch(e => {
				dispatch(failed(e));
			});
	};

export const whoami = (): AppThunk => dispatch => {
	dispatch(setLoading());
	usersService
		.whoami()
		.then(({ user }) => {
			dispatch(setUser({ user }));
		})
		.catch(e => {
			dispatch(failed(e));
		});
};

export const logout = (): AppThunk => dispatch => {
	usersService
		.logout()
		.then(() => {
			clearJwt();
			dispatch(clearAuth());
			dispatch(success());
		})
		.catch(e => {
			dispatch(failed(e));
		});
};

export const fetchCountries = createAsyncThunk(
	"students/fetchCountries",
	async (subscriptionTypeId: number, { rejectWithValue }) => {
		return countriesService
			.find({
				...(subscriptionTypeId && { filters: { "shippingPlans.subscriptionTypes.id": subscriptionTypeId } }),
				orderBy: { name: "ASC" },
				findAll: true
			})
			.catch(error => rejectWithValue(error.message));
	}
);

export const selectUser = (state: RootState): User | null => state.auth.user;
export const selectAuth = (state: RootState): { errorMessage: string; isLoading: boolean; isLoggedIn: boolean } => {
	const { errorMessage, isLoading, isLoggedIn } = state.auth;
	return { errorMessage, isLoading, isLoggedIn };
};

export default authSlice.reducer;
