import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	CustomInputType,
	DefaultValueGetter,
	DefaultValueGettersObject
} from "@remar/shared/dist/components/CustomInput/customInput.model";
import {
	ExternalIntegrationIds,
	LocationPackagesTypeEnum,
	UserSubscriptionTypeCategoriesEnum
} from "@remar/shared/dist/constants";
import { Package, UserSubscriptionType } from "@remar/shared/dist/models";
import {
	CreateFormOptionsInputItem,
	CreateFormOptionsInputsObject,
	FormStateModel
} from "@remar/shared/dist/utils/form/form.model";
import {
	createForm,
	getBaseCreateFormSubItemConfig,
	parseTemplate,
	validateFormAction as utilsValidateFormAction
} from "@remar/shared/dist/utils/form/form.utils";
import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { cloneDeep, omit } from "lodash";
import get from "lodash/get";
import set from "lodash/set";
import { AppThunk, RootState } from "store";
import { UserSubscriptionTypeExternalIntegrationDataItemUpsertDto, userSubscriptionTypesService } from "store/services";
import { LocationPackageCreateDto, LocationPackageUpdateDto } from "store/services/packages/dto";
import { packagesService } from "store/services/packages/packages.service";

import { v4 as uuid } from "uuid";

import {
	DefaultLocationPackage,
	LocationPackageData,
	ManagePackageFormInputs,
	ManagePackageFormRawData,
	PackageCourseFeaturesFormInputs,
	PackageCourseFeaturesFormRawData,
	PackageUserSubscriptionTypesFormAddonInputsItem,
	PackageUserSubscriptionTypesFormInputs,
	PackageUserSubscriptionTypesFormRawData,
	PackageUserSubscriptionTypesFormTemplates
} from "./models";

import { PAYMENT_CONSTANTS } from "../CourseAddEdit/courseAddEdit.constants";

import { getDigitalAssetInputsConfig, getShipStationInputsConfig } from "../CourseAddEdit/courseAddEdit.slice";

import { UserSubscriptionTypesFormRawData } from "../CourseAddEdit/models";

import { _emit, emit } from "../notifications/notifications.slice";

const defaultValueGetters: DefaultValueGettersObject = {
	uuidV4: (() => uuid()) as DefaultValueGetter
};

export interface ManagePackageState {
	isLoading: boolean;
	errorMessage: string;
	data: LocationPackageData;
	courseId: string;
	isDeleteLoading: boolean;
	isPublishLoading: boolean;
	isSaveLoading: boolean;
	externalIntegrationData: {
		currency: string;
		planName: string;
	};
	managePackageForm: FormStateModel<ManagePackageFormInputs, ManagePackageFormRawData, {}>;
	userSubscriptionTypesForm: FormStateModel<
		PackageUserSubscriptionTypesFormInputs,
		PackageUserSubscriptionTypesFormRawData,
		PackageUserSubscriptionTypesFormTemplates
	>;
	userSubscriptionTypesFormPerSeat: FormStateModel<
		PackageUserSubscriptionTypesFormInputs,
		PackageUserSubscriptionTypesFormRawData,
		PackageUserSubscriptionTypesFormTemplates
	>;
	packageFeaturesForm: FormStateModel<PackageCourseFeaturesFormInputs, PackageCourseFeaturesFormRawData, {}>;
	packages: Package[];
}
type MappedListType = { path: string; value: number | string | string[] | boolean }[];
function getStripeInputsConfig(options: { isRequired: boolean }): {
	[inputName: string]:
		| CreateFormOptionsInputItem<unknown>
		| { [inputName: string]: CreateFormOptionsInputItem<unknown> }[];
} {
	const { isRequired } = options;
	return {
		...getBaseCreateFormSubItemConfig(),
		duration: { type: CustomInputType.Text },
		isLoading: {
			defaultValue: false,
			type: CustomInputType.Checkbox
		},
		name: { type: CustomInputType.Text },
		planId: {
			label: "Paste Link Here",
			placeholder: "Paste Link Here",
			type: CustomInputType.Text,
			validations: isRequired ? { required: true } : undefined
		},
		price: { defaultValue: 0, type: CustomInputType.Number }
	};
}

function getSubscriptionInputsConfig(
	key: string,
	options: {
		isRecurring: boolean;
		isTrial: boolean;
		isRequired: boolean;
		withAddons?: boolean;
	}
): CreateFormOptionsInputsObject {
	const { isTrial, isRecurring, isRequired, withAddons } = options;
	const item: CreateFormOptionsInputsObject = {
		id: { type: CustomInputType.Number },
		draftId: {
			type: CustomInputType.Text,
			useDefaultValueGetterName: "uuidV4"
		},
		nextSubscriptionTypeId: { type: CustomInputType.Number },
		nextSubscriptionTypeDraftId: { type: CustomInputType.Text },
		isRecurring: {
			defaultValue: isRecurring,
			type: CustomInputType.Checkbox
		},
		isTrial: {
			defaultValue: isTrial,
			type: CustomInputType.Checkbox
		},
		stripeItem: getStripeInputsConfig({ isRequired }),
		addons: []
	};
	if (withAddons) {
		item.addons = [
			{
				shipStationItem: getShipStationInputsConfig({ isRequired: false }),
				digitalAssetItem: getDigitalAssetInputsConfig({ isRequired: false }),
				isDigital: {
					defaultValue: false,
					type: CustomInputType.Checkbox
				},
				mainImageKey: {
					imageExtensionName: "png",
					imageFileType: "image/png",
					isImageFile: true,
					label: "Upload  Media",
					placeholder: "Upload  Media",
					type: CustomInputType.File,
					uploaderIconName: "cloud-upload",
					uploaderStateLoaderKey: "uploading"
				},
				mainImageUrl: {
					type: CustomInputType.Text
				}
			}
		];
	}
	return item;
}

const initialState: ManagePackageState = {
	isLoading: false,
	isDeleteLoading: false,
	isPublishLoading: false,
	isSaveLoading: false,
	errorMessage: "",
	packages: [],
	courseId: "",
	data: {
		...DefaultLocationPackage,
		externalIntegrationData: { initial: {}, recurring: {}, trial: {} }
	},
	externalIntegrationData: {
		currency: "",
		planName: ""
	},
	managePackageForm: createForm<ManagePackageFormInputs, ManagePackageFormRawData, {}>({
		defaultValueGetters,
		inputs: {
			packageName: {
				placeholder: "Package Name",
				type: CustomInputType.Text,
				validations: { required: true, maxLength: 25 }
			},
			isActive: {
				defaultValue: false,
				type: CustomInputType.Checkbox
			}
		},
		statePath: "managePackageForm"
	}),
	userSubscriptionTypesForm: createForm<
		PackageUserSubscriptionTypesFormInputs,
		PackageUserSubscriptionTypesFormRawData,
		PackageUserSubscriptionTypesFormTemplates
	>({
		defaultValueGetters,
		generateTemplatesFromPaths: [
			"initial.stripeItem.counterpartIds",
			"recurring.stripeItem.counterpartIds",
			"trial.stripeItem.counterpartIds"
		],
		inputs: {
			initial: getSubscriptionInputsConfig("initial", {
				isRecurring: false,
				isRequired: true,
				isTrial: false
			}),
			recurring: getSubscriptionInputsConfig("recurring", {
				isRecurring: true,
				isRequired: true,
				isTrial: false
			}),
			trial: getSubscriptionInputsConfig("trial", {
				isRecurring: false,
				isRequired: false,
				isTrial: true
			}),
			hasTrial: {
				defaultValue: false,
				label: "Enable Free Trial",
				type: CustomInputType.Switch
			}
		},
		statePath: "userSubscriptionTypesForm"
	}),
	userSubscriptionTypesFormPerSeat: createForm<
		PackageUserSubscriptionTypesFormInputs,
		PackageUserSubscriptionTypesFormRawData,
		PackageUserSubscriptionTypesFormTemplates
	>({
		defaultValueGetters,
		generateTemplatesFromPaths: [
			"initial.stripeItem.counterpartIds",
			"recurring.stripeItem.counterpartIds",
			"initial.addons"
		],
		inputs: {
			initial: getSubscriptionInputsConfig("initial", {
				isRecurring: false,
				isRequired: true,
				isTrial: false,
				withAddons: true
			}),
			recurring: getSubscriptionInputsConfig("recurring", {
				isRecurring: true,
				isRequired: true,
				isTrial: false
			})
		},
		statePath: "userSubscriptionTypesFormPerSeat"
	}),
	packageFeaturesForm: createForm<PackageCourseFeaturesFormInputs, PackageCourseFeaturesFormRawData, {}>({
		defaultValueGetters,
		inputs: {
			feature: {
				placeholder: "Enter Package Feature",
				type: CustomInputType.Text
			}
		},
		statePath: "packageFeaturesForm"
	})
};

const utilsResetState = getResetState<ManagePackageState>(initialState);

export const managePackageSlice = createSlice({
	name: "managePackage",
	initialState,
	reducers: {
		createAddonItem: (state, action: PayloadAction<string>) => {
			const addonTemplatePath = `${action.payload}.addons`;
			const addonItemsPath = `userSubscriptionTypesFormPerSeat.inputs.${addonTemplatePath}`;
			const addons = get(state, addonItemsPath) as PackageUserSubscriptionTypesFormAddonInputsItem[];
			const newAddonItem = parseTemplate(
				state.userSubscriptionTypesFormPerSeat.templates[addonTemplatePath],
				`${addonItemsPath}.${addons.length}`,
				{ defaultValueGetters }
			) as PackageUserSubscriptionTypesFormAddonInputsItem;
			newAddonItem.digitalAssetItem.draftId.value = uuid();
			newAddonItem.shipStationItem.draftId.value = uuid();
			addons!.push(newAddonItem);
			set(state, addonItemsPath, addons);
		},
		setAddonItem: (state, action: PayloadAction<UserSubscriptionType>) => {
			const {
				payload: { subTypeEIDItems }
			} = action;
			const bookCollection = subTypeEIDItems?.filter(item => item.parentId);
			bookCollection?.forEach(item => {
				const { integrationId, mainImageKey, mainImageUrl, id, data } = item;
				const addonTemplatePath = `${PAYMENT_CONSTANTS.INITIAL}.addons`;
				const addonItemsPath = `userSubscriptionTypesFormPerSeat.inputs.${addonTemplatePath}`;
				const addons = get(state, addonItemsPath) as PackageUserSubscriptionTypesFormAddonInputsItem[];
				const newAddonItem = parseTemplate(
					state.userSubscriptionTypesFormPerSeat.templates[addonTemplatePath],
					`${addonItemsPath}.${addons.length}`,
					{ defaultValueGetters }
				) as PackageUserSubscriptionTypesFormAddonInputsItem;

				newAddonItem.mainImageUrl.value = mainImageUrl;
				newAddonItem.mainImageKey.imageUrl = mainImageUrl;
				newAddonItem.mainImageKey.value = mainImageKey!;
				if (integrationId == ExternalIntegrationIds.DigitalAsset) {
					const { name: digitalAssetsName, productKey } = data;
					newAddonItem.digitalAssetItem.id = id;
					newAddonItem.digitalAssetItem.name.value = digitalAssetsName;
					newAddonItem.digitalAssetItem.name.label = digitalAssetsName;
					newAddonItem.digitalAssetItem.productKey.name = digitalAssetsName as string;
					newAddonItem.digitalAssetItem.productKey.value = productKey;
					newAddonItem.isDigital.value = true;
				} else if (integrationId == ExternalIntegrationIds.PBS) {
					const { name, productSKU, clientSKU } = data;
					newAddonItem.shipStationItem.id = id;
					newAddonItem.shipStationItem.name.value = name as string;
					newAddonItem.shipStationItem.productSKU.value = productSKU;
					newAddonItem.shipStationItem.clientSKU.value = clientSKU;
					newAddonItem.isDigital.value = false;
				}
				addons!.push(newAddonItem);
				set(state, addonItemsPath, addons);
			});
		},
		removeAddonItem: (state, action: PayloadAction<{ addonParent: string; addonIndex: number }>) => {
			const { addonParent, addonIndex } = action.payload;
			const addonItemsPath = `userSubscriptionTypesFormPerSeat.inputs.${addonParent}.addons.${addonIndex}`;
			set(state, `${addonItemsPath}.shipStationItem.deleted.value`, true);
			set(state, `${addonItemsPath}.digitalAssetItem.deleted.value`, true);
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setisDeleteLoading: (state, action: PayloadAction<boolean>) => {
			state.isDeleteLoading = action.payload;
		},
		setisPublishLoading: (state, action: PayloadAction<boolean>) => {
			state.isPublishLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		initializeForm: (state, action: PayloadAction<MappedListType>) => {
			const value = action.payload;
			value.forEach(({ path, value }) => {
				set(state, path, value);
			});
		},
		addPackageFeature: (state, action: PayloadAction<string>) => {
			const value = action.payload;
			value && state.data.description.push(action.payload);
			state.packageFeaturesForm.inputs.feature.value = "";
		},
		deletePackageFeature: (state, action: PayloadAction<string>) => {
			const value = action.payload;
			state.data.description = state.data.description.filter(marketingPoint => marketingPoint !== value);
		},
		updatePackageFeatures: (state, action: PayloadAction<{ id: number; feature: string }[]>) => {
			const arr = action.payload;
			const filteredArray = arr.filter(item => !item.id);
			state.data.description = filteredArray as unknown as string[];
		},
		resetPackages: state => {
			state.packages = [];
		},
		setCourseId: (state, action: PayloadAction<string>) => {
			state.courseId = action.payload;
		},
		resetState: utilsResetState,
		setStateValue: utilsSetStateValue,
		validateForm: utilsValidateFormAction
	}
});

const getUserSubscriptionTypes = (
	formName,
	getState,
	dispatch,
	statePackageData,
	packageData,
	externalIntegrationData,
	body,
	userSubscriptionTypeCategoryId,
	locationPackageTypeId,
	processTrial = false,
	checkAddons = false
) => {
	dispatch(validateForm({ formStatePath: formName, markInputsAsDirty: true }));
	const form = getState().managePackage[formName];
	const state = getState().managePackage;
	if (!form.valid) {
		dispatch(_emit("Please fill in all fields correctly and try again.", "error"));
		return false;
	}
	const { initial: originalInitialExternalIntegrationData, recurring: originalRecurringExternalIntegrationData } =
		form.data?.externalIntegrationData || {};

	const {
		managePackageForm: { rawData }
	} = state;
	if (form.valid) {
		const { initial, recurring, trial, hasTrial } = cloneDeep<UserSubscriptionTypesFormRawData>(form.rawData);
		const { stripeItem: initialStripeItem } = initial;
		const { stripeItem: recurringStripeItem } = recurring;
		const addons: UserSubscriptionTypeExternalIntegrationDataItemUpsertDto[] = [];
		if (checkAddons && initial.addons?.length) {
			for (let index = 0; index < initial.addons.length; index++) {
				const addonItem = initial.addons[index];
				const {
					shipStationItem: addonShipStationItem,
					digitalAssetItem: addonDigitalAssetItem,
					mainImageKey,
					isDigital
				} = addonItem;
				if (!isDigital && !addonShipStationItem.name && !addonShipStationItem.deleted) {
					dispatch(_emit("Acutrack Product Name should not be empty", "error"));
					return;
				}
				if (isDigital && !addonDigitalAssetItem.productKey && !addonDigitalAssetItem.deleted) {
					dispatch(_emit("Digital Asset should not be empty", "error"));
					return;
				}
				if (!mainImageKey && !(addonDigitalAssetItem.deleted || addonShipStationItem.deleted)) {
					dispatch(_emit("Book image is required", "error"));
					return;
				}
				let deleted = false;
				const digitalAssetAddonItem: UserSubscriptionTypeExternalIntegrationDataItemUpsertDto = {
					draftId: addonDigitalAssetItem.draftId,
					integrationId: ExternalIntegrationIds.DigitalAsset,
					subscriptionTypeId: 0, // this gets overwritten in the back-end
					data: {
						name: addonDigitalAssetItem.name,
						productKey: addonDigitalAssetItem.productKey
					},
					deleted: addonDigitalAssetItem.deleted,
					mainImageKey: mainImageKey
				};
				const shipStationApiAddonItem: UserSubscriptionTypeExternalIntegrationDataItemUpsertDto = {
					draftId: addonShipStationItem.draftId,
					integrationId: ExternalIntegrationIds.PBS,
					subscriptionTypeId: 0, // this gets overwritten in the back-end
					data: {
						name: addonShipStationItem.name,
						productSKU: addonShipStationItem.productSKU,
						clientSKU: addonShipStationItem.clientSKU
					},
					deleted: addonShipStationItem.deleted,
					mainImageKey: mainImageKey
				};

				digitalAssetAddonItem.parentDraftId = initialStripeItem.draftId;
				if (addonShipStationItem.productSKU) {
					shipStationApiAddonItem.parentDraftId = initialStripeItem.draftId;
				}
				if (isDigital && addonDigitalAssetItem.deleted) {
					deleted = true;
				}
				if (!isDigital && addonShipStationItem.deleted) {
					deleted = true;
				}
				if (!deleted) {
					isDigital && addons.push(digitalAssetAddonItem);
					!isDigital && addons.push(shipStationApiAddonItem);
				}
			}
		}
		const addonsUpdate =
			initial.addons?.filter(f => (f.isDigital ? !f.digitalAssetItem.deleted : !f.shipStationItem.deleted)).length !==
				originalInitialExternalIntegrationData?.addons?.length ||
			!initial.addons?.every(ia =>
				originalInitialExternalIntegrationData?.addons?.some(
					oa =>
						oa.stripeItem.mainImageKey == ia.mainImageKey &&
						(oa.shipStationProductSKU == ia.shipStationItem.productSKU ||
							oa.digitalAssetProductKey == ia.digitalAssetItem.productKey)
				)
			);
		const addInitial =
			!originalInitialExternalIntegrationData?.stripePlanId ||
			initialStripeItem.planId !== originalInitialExternalIntegrationData?.stripePlanId ||
			addonsUpdate;
		const addRecurring =
			!originalRecurringExternalIntegrationData?.stripePlanId ||
			recurringStripeItem.planId !== originalRecurringExternalIntegrationData?.stripePlanId;
		let addTrial = false;

		if (processTrial) {
			if (!hasTrial) {
				if (trial.id) {
					addTrial = true;
					trial.isActive = false;
				} else {
					addTrial = false;
				}
			} else {
				addTrial = !(trial.id && !addInitial);
			}

			if (addInitial) {
				//We are replacing subscription with new one so we add draft id
				trial.nextSubscriptionTypeDraftId = initial.draftId;
			} else {
				// We have an existing initial subscription so we use id
				trial.nextSubscriptionTypeId = initial.id;
			}
			trial.name = `${UserSubscriptionTypeCategoriesEnum[userSubscriptionTypeCategoryId].toString()} ${
				rawData.packageName
			} - ${LocationPackagesTypeEnum[locationPackageTypeId].toString()} (Trial)`;
			trial.description = trial.name;
		}

		const { price, name: initialPlanName } = initialStripeItem;
		const { price: recuringPrice, name: recurringPlanName } = recurringStripeItem;

		if (!price) {
			dispatch(_emit("Initial price should not be zero", "error"));
			return false;
		}
		if (!recuringPrice) {
			dispatch(_emit("Recurring charge should not be zero", "error"));
			return false;
		}
		if (addRecurring) {
			initial.nextSubscriptionTypeDraftId = recurring.draftId;
		} else {
			initial.nextSubscriptionTypeId = recurring.id;
		}

		initial.name = `${UserSubscriptionTypeCategoriesEnum[userSubscriptionTypeCategoryId].toString()} ${
			rawData.packageName
		} - ${initialPlanName} ${LocationPackagesTypeEnum[locationPackageTypeId].toString()}`;
		initial.description = initial.name;
		recurring.name = `${UserSubscriptionTypeCategoriesEnum[userSubscriptionTypeCategoryId].toString()} ${
			rawData.packageName
		} - ${recurringPlanName} ${LocationPackagesTypeEnum[locationPackageTypeId].toString()} (Recurring)`;
		recurring.description = recurring.name;

		if (addInitial) {
			const counterpartIds: { value: number; deleted: boolean }[] = [];
			const counterpartDraftIds: string[] = [];
			// Mark original subscription as inactive
			if (initial.id) {
				body.userSubscriptionTypes.push({
					id: initial.id,
					draftId: initial.draftId,
					nextSubscriptionTypeId: initial.nextSubscriptionTypeId,
					nextSubscriptionTypeDraftId: initial.nextSubscriptionTypeDraftId,
					userSubscriptionTypeCategoryId: userSubscriptionTypeCategoryId,
					name: initial.name,
					description: initial.description,
					isRecurring: initial.isRecurring,
					isActive: false,
					deleted: !!initial.deleted
				});
			}
			// Add new subscription
			body.userSubscriptionTypes.push({
				id: 0,
				draftId: initial.draftId,
				nextSubscriptionTypeId: initial.nextSubscriptionTypeId,
				nextSubscriptionTypeDraftId: initial.nextSubscriptionTypeDraftId,
				userSubscriptionTypeCategoryId: userSubscriptionTypeCategoryId,
				name: initial.name,
				description: initial.description,
				isRecurring: initial.isRecurring,
				isActive: true,
				deleted: !!initial.deleted,
				externalIntegrationDataItems: [
					{
						id: 0,
						draftId: initialStripeItem.draftId,
						integrationId: ExternalIntegrationIds.Stripe,
						subscriptionTypeId: 0,
						counterpartIds,
						counterpartDraftIds,
						data: { planId: initialStripeItem.planId }
					},
					...addons.filter(f => f.deleted === false)
				]
			});
		} else if (addRecurring) {
			//Update nextSubscriptionTypeId
			body.userSubscriptionTypes.push({
				id: initial.id,
				draftId: initial.draftId,
				nextSubscriptionTypeId: initial.nextSubscriptionTypeId,
				nextSubscriptionTypeDraftId: initial.nextSubscriptionTypeDraftId,
				userSubscriptionTypeCategoryId: userSubscriptionTypeCategoryId,
				name: initial.name,
				description: initial.description,
				isRecurring: initial.isRecurring,
				isActive: initial.isActive,
				deleted: !!initial.deleted
			});
		}
		if (addRecurring) {
			// Mark original subscription as inactive
			if (!!recurring.id) {
				body.userSubscriptionTypes.push({
					id: recurring.id,
					draftId: recurring.draftId,
					nextSubscriptionTypeId: recurring.nextSubscriptionTypeId,
					nextSubscriptionTypeDraftId: recurring.nextSubscriptionTypeDraftId,
					userSubscriptionTypeCategoryId: userSubscriptionTypeCategoryId,
					name: recurring.name,
					description: recurring.description,
					isRecurring: recurring.isRecurring,
					isActive: false,
					deleted: !!recurring.deleted
				});
			}
			// Add new subscription
			body.userSubscriptionTypes.push({
				id: 0,
				draftId: recurring.draftId,
				nextSubscriptionTypeId: recurring.nextSubscriptionTypeId,
				nextSubscriptionTypeDraftId: recurring.nextSubscriptionTypeDraftId,
				userSubscriptionTypeCategoryId: userSubscriptionTypeCategoryId,
				name: recurring.name,
				description: recurring.description,
				isRecurring: recurring.isRecurring,
				isActive: true,
				deleted: !!recurring.deleted,
				externalIntegrationDataItems: [
					{
						...recurringStripeItem,
						id: 0,
						integrationId: ExternalIntegrationIds.Stripe,
						subscriptionTypeId: 0,
						counterpartIds: [],
						counterpartDraftIds: [],
						data: { planId: recurringStripeItem.planId }
					}
				]
			});
		}
		if (addTrial) {
			body.userSubscriptionTypes.push({
				...omit(trial, ["isDigital", "shipStationItem", "digitalAssetItem", "stripeItem", "addons"]),
				userSubscriptionTypeCategoryId: userSubscriptionTypeCategoryId,
				deleted: !!trial.deleted
			});
		}
	}
	return true;
};

export const fetchAllPackages =
	(courseId: number): AppThunk =>
	dispatch => {
		dispatch(setLoading(true));
		packagesService
			.find({
				findAll: true,
				include: [
					"allowedUST.userSubscriptionTypeCategory",
					"allowedUST.subTypeEIDItems",
					"allowedUST.subTypeEIDItems.children.counterparts",
					"allowedUST.subTypeEIDItems.counterparts"
				],
				orderBy: { createdAt: "ASC" },
				filters: { courseId, isDeleted: false }
			})
			.then(r => {
				dispatch(setStateValue({ key: "packages", value: r.items }));
			})
			.catch(err => {
				dispatch(failed(err));
			})
			.finally(() => {
				dispatch(setLoading(false));
			});
	};

export const fetchShipStationData = (productSKU: string, innerPath: string, formName: string): AppThunk => {
	return async (dispatch, getState) => {
		if (!productSKU) {
			return;
		}
		const basePath = `${formName}.inputs.${innerPath}.shipStationItem`;
		try {
			const { managePackage: state } = getState();
			const { value: inputValue } = get(state, `${basePath}.clientSKU`);
			if (inputValue !== productSKU) {
				return;
			}
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: "" }));
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: true }));
			const shipStationData = await userSubscriptionTypesService.getStockKeepingProductData(
				ExternalIntegrationIds.PBS,
				productSKU
			);
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: shipStationData.name }));
			dispatch(setStateValue({ key: `${basePath}.productSKU.value`, value: shipStationData.productSKU }));
		} catch (e) {
			const err = e as { message: string };
			console.error(e);
			if (err && err.message) {
				dispatch(_emit(err.message, "error"));
				return;
			}
			dispatch(_emit("An error has occurred while retrieving data from Acutrack.", "error"));
		} finally {
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: false }));
		}
	};
};

export const fetchStripeData = (planId: string, innerPath: string, formName: string): AppThunk => {
	return async (dispatch, getState) => {
		if (!planId) {
			return;
		}
		const basePath = `${formName}.inputs.${innerPath}.stripeItem`;
		try {
			await new Promise<void>(resolve => {
				setTimeout(() => resolve(), 500);
			});
			const { managePackage: state } = getState();
			// TODO for edit
			// const { externalIntegrationData } = state;
			const { value: inputValue } = get(state, `${basePath}.planId`);
			if (inputValue !== planId) {
				return;
			}
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: true }));
			///Reset the values before get call
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: "" }));
			dispatch(setStateValue({ key: `${basePath}.price.value`, value: 0 }));
			dispatch(
				setStateValue({
					key: `${basePath}.duration.value`,
					value: undefined
				})
			);
			const stripeAPIData = await userSubscriptionTypesService.getPaymentProviderPriceData(
				ExternalIntegrationIds.Stripe,
				planId
			);
			if (PAYMENT_CONSTANTS.RECURRING === innerPath && stripeAPIData.type === PAYMENT_CONSTANTS.ONE_TIME) {
				dispatch(_emit("Invalid entry for recurring subscription", "error"));
				return;
			}
			if (PAYMENT_CONSTANTS.INITIAL === innerPath && stripeAPIData.type === PAYMENT_CONSTANTS.ONE_TIME) {
				dispatch(_emit("Invalid entry for initial subscription", "error"));
				return;
			}
			dispatch(setStateValue({ key: `${basePath}.name.value`, value: stripeAPIData.name }));
			dispatch(setStateValue({ key: `${basePath}.price.value`, value: stripeAPIData.price }));
			if (stripeAPIData.duration) {
				dispatch(
					setStateValue({
						key: `${basePath}.duration.value`,
						value: stripeAPIData.duration
					})
				);
			}
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: false }));
		} catch (e) {
			const err = e as { message: string };
			console.error(e);
			if (err && err.message) {
				dispatch(_emit(err.message, "error"));
				return;
			}
			dispatch(_emit("An error has occurred while retrieving data from Stripe.", "error"));
		} finally {
			dispatch(setStateValue({ key: `${basePath}.isLoading.value`, value: false }));
		}
	};
};

export const saveForm = (options: {
	courseId: number;
	locationPackageTypeId: number;
	sideEffect: () => void;
}): AppThunk => {
	const { courseId, locationPackageTypeId, sideEffect } = options;
	return async (dispatch, getState) => {
		try {
			dispatch(setStateValue({ key: "isSaveLoading", value: true }));
			const { managePackage: state } = getState();
			const {
				data: statePackageData,
				externalIntegrationData,
				managePackageForm: { rawData }
			} = state;
			let body: LocationPackageCreateDto | LocationPackageUpdateDto | null = null;
			const packageData = { ...statePackageData };
			body = {
				name: rawData.packageName,
				isActive: rawData.isActive,
				isDeleted: false,
				courseId: courseId,
				locationPackageTypeId: locationPackageTypeId,
				userSubscriptionTypes: [],
				description: []
			};

			dispatch(validateForm({ formStatePath: "managePackageForm", markInputsAsDirty: true }));
			const { managePackageForm } = getState().managePackage;
			if (!managePackageForm.valid) {
				dispatch(_emit("Please fill in all fields correctly and try again.", "error"));
				return;
			}
			body.userSubscriptionTypes = [];
			if (
				!getUserSubscriptionTypes(
					"userSubscriptionTypesForm",
					getState,
					dispatch,
					statePackageData,
					packageData,
					externalIntegrationData,
					body,
					UserSubscriptionTypeCategoriesEnum.LocationPackage,
					locationPackageTypeId,
					true
				)
			) {
				return;
			}
			if (
				!getUserSubscriptionTypes(
					"userSubscriptionTypesFormPerSeat",
					getState,
					dispatch,
					statePackageData,
					packageData,
					externalIntegrationData,
					body,
					UserSubscriptionTypeCategoriesEnum.LocationPerSeat,
					locationPackageTypeId,
					false,
					true
				)
			) {
				return;
			}
			dispatch(validateForm({ formStatePath: "packageFeaturesForm", markInputsAsDirty: true }));
			const { packageFeaturesForm } = getState().managePackage;
			const { description } = getState().managePackage.data;
			if (!packageFeaturesForm.valid) {
				dispatch(_emit("Please fill in all of the package features fields correctly and try again.", "error"));
				return;
			} else {
				body = {
					...body,
					description: description
				} as LocationPackageCreateDto;
			}
			if (!packageData.id) {
				const newLocationPackage = await packagesService.create(body as LocationPackageCreateDto);
				dispatch(setStateValue({ key: "newLocationPackageId", value: newLocationPackage.id }));
				dispatch(_emit("Package created successfully.", "success"));
				sideEffect();
				return;
			}
			await packagesService.update({
				filters: { id: packageData.id },
				data: body as LocationPackageUpdateDto
			});
			dispatch(_emit("Course updated successfully.", "success"));
			sideEffect();
		} catch (e) {
			console.error(e);
			dispatch(_emit("An error has occurred while saving your data.", "error"));
		} finally {
			dispatch(setStateValue({ key: "isSaveLoading", value: false }));
		}
	};
};

export const publishPackage =
	(publishPackagePayload: { id: number; isActive: boolean }): AppThunk =>
	(dispatch, getState) => {
		const { managePackage: state } = getState();
		dispatch(setisPublishLoading(true));
		packagesService
			.publishOrUnpublishPackage({
				filters: { id: publishPackagePayload.id },
				data: { isActive: !publishPackagePayload.isActive }
			})
			.then(() => {
				dispatch(
					emit({
						message: `Package has been ${!publishPackagePayload.isActive ? "published" : "unpublished"}.`,
						color: "success"
					})
				);
			})
			.then(() => dispatch(fetchAllPackages(+state.courseId)))
			.catch(() => {
				dispatch(emit({ message: "Sorry, there was an error while publishing the package", color: "error" }));
			})
			.finally(() => {
				dispatch(setisPublishLoading(false));
			});
	};

export const deletePackage =
	(id: number): AppThunk =>
	(dispatch, getState) => {
		const { managePackage: state } = getState();
		dispatch(setisDeleteLoading(true));
		packagesService
			.delete({
				filters: { id }
			})
			.then(() => {
				dispatch(
					emit({
						message: "Package has been deleted successfully.",
						color: "success"
					})
				);
			})
			.then(() => dispatch(fetchAllPackages(+state.courseId)))
			.catch(() => {
				dispatch(emit({ message: "Sorry, there was an error while deleting the package", color: "error" }));
			})
			.finally(() => {
				dispatch(setisDeleteLoading(false));
			});
	};

export function getFullState(state: RootState): ManagePackageState {
	return state.managePackage;
}

export const {
	setLoading,
	setisDeleteLoading,
	setisPublishLoading,
	failed,
	setStateValue,
	validateForm,
	initializeForm,
	addPackageFeature,
	deletePackageFeature,
	updatePackageFeatures,
	resetPackages,
	setCourseId,
	resetState,
	createAddonItem,
	setAddonItem,
	removeAddonItem
} = managePackageSlice.actions;

export default managePackageSlice.reducer;
