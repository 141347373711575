import React from "react";

import { ArcElement, Chart } from "chart.js";

import { Doughnut as DoughnutCjs } from "react-chartjs-2";

Chart.register(ArcElement);

const Doughnut = ({ options, data }) => <DoughnutCjs options={options} data={data} />;

export default Doughnut;
