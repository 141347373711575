import React, { useCallback, useEffect } from "react";

import { Box, Card, CircularProgress, IconButton, InputAdornment, Typography } from "@material-ui/core";

import { Add, Delete, Edit } from "@material-ui/icons";
import { CustomInput } from "@remar/shared/dist/components/CustomInput/CustomInput";
import { QuestionTypes } from "@remar/shared/dist/constants";
import { useDraggedScroll } from "@remar/shared/dist/utils/useDraggedScroll";

import { useDispatch, useSelector } from "react-redux";
import {
	addOneMoreQuestionAnswerOption,
	clearArrayOfQuestionAnswerOption,
	createGroupAnswerOptionItem,
	createQuestionAnswerOptionItem,
	createQuestionGap,
	createQuestionGroup,
	createSingleMatrixQuestionAnswerOptions,
	createSubItem,
	createTab,
	duplicatePreviousTabs,
	editSequencingAnswerOptions,
	getFullState,
	initBowtieGroups,
	initCaseStudyQuestion,
	initGroupingQuestion,
	initSingleChoiceQuestion,
	moveGroupQuestionAnswerOption,
	reduceAnswerOptsLength,
	removeGap,
	removeGapOption,
	removeQuestionAnswerOptionItem,
	removeQuestionAttachment,
	removeQuestionData,
	removeQuestionGroup,
	removeQuestionItem,
	removeTab,
	setArrayOfQuestionAnswerOption,
	setStateValue,
	updateAnswerOptsFlag,
	updateCorrectFlag,
	updateMatrixQuestionGroup,
	updateOptionOrder,
	updateValidationCriteria,
	uploadQuestionMaterial,
	validateForm
} from "store/features/CreateLesson/createLesson.slice";
import { _emit } from "store/features/notifications/notifications.slice";

import theme from "theme/default";

import { useTestOnlyQuestionStyles } from "./testOnlyQuestions.styles";

import { BowTieQuestion } from "../../QuestionBank/AddQuestion/BowTieQuestion";
import { CaseStudyQuestion } from "../../QuestionBank/AddQuestion/CaseStudyQuestion";
import { ClozeDropDownQuestion } from "../../QuestionBank/AddQuestion/ClozeDropDownQuestion";
import { DragAndDropQuestion } from "../../QuestionBank/AddQuestion/DragAndDropQuestion";
import { DropDownTableQuestion } from "../../QuestionBank/AddQuestion/DropDownTableQuestion";
import { GroupingQuestion } from "../../QuestionBank/AddQuestion/GroupingQuestion";
import { HotspotHighlightQuestion } from "../../QuestionBank/AddQuestion/HotspotHighlight";
import { HotspotHighlightTableQuestion } from "../../QuestionBank/AddQuestion/HotspotHighlightTableQuestion";
import { MatrixMultipleChoiceQuestion } from "../../QuestionBank/AddQuestion/MatrixMultipleChoiceQuestion";
import { MatrixSingleChoiceQuestion } from "../../QuestionBank/AddQuestion/MatrixSingleChoiceQuestion";
import { MultipleChoiceQuestion } from "../../QuestionBank/AddQuestion/MultipleChoiceQuestion";
import { MultipleResponseGroupQuestion } from "../../QuestionBank/AddQuestion/MultipleResponseGroupQuestion";
import SequencingQuestion from "../../QuestionBank/AddQuestion/SequencingQuestion";
import { SingleChoiceQuestion } from "../../QuestionBank/AddQuestion/SingleChoiceQuestion";

export const TestOnlyQuestions = ({ editIndex, setEditIndex }) => {
	const classes = useTestOnlyQuestionStyles();
	const dispatch = useDispatch();
	useDraggedScroll();
	const { isLoading, saveIsLoading, testOnlyQuestionsForm } = useSelector(getFullState);

	const { passingThreshold: passingThresholdInput, questions } = testOnlyQuestionsForm.inputs;

	useEffect(() => {
		dispatch(editSequencingAnswerOptions());
	}, [dispatch, questions.length]);

	useEffect(() => {
		if (editIndex > -1) {
			const element = document.getElementById(`createLesson-questionItem-${editIndex}`);
			if (element && window) {
				// Will scroll smoothly to the top of the next section
				const location = element.offsetTop;
				window.scrollTo({ top: location - 64, behavior: "smooth" });
			}
		}
	}, [editIndex]);

	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	let nonDeletedQuestionCount = 0;

	const getLessonQuestion = useCallback(
		(typeId, questionIndex) => {
			if (editIndex !== questionIndex) {
				return <Typography>{questions[questionIndex].text.value}</Typography>;
			}
			switch (typeId) {
				case QuestionTypes.Grouping:
					return (
						<GroupingQuestion
							moveGroupQuestionAnswerOption={moveGroupQuestionAnswerOption}
							initGroupingQuestion={initGroupingQuestion}
							createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
							getFullState={getFullState}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							key={`questions.${questionIndex}`}
							statePath={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.Sequencing:
					return (
						<SequencingQuestion
							createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
							getFullState={getFullState}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							uploadQuestionMaterial={uploadQuestionMaterial}
							updateOptionOrder={updateOptionOrder}
							validateForm={validateForm}
							key={`questions.${questionIndex}`}
							statePath={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.MultipleChoiceSN:
				case QuestionTypes.MultipleChoiceSATA:
					return (
						<MultipleChoiceQuestion
							key={`questions.${questionIndex}`}
							createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
							getFullState={getFullState}
							removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							statePath={`questions.${questionIndex}`}
							formName="testOnlyQuestionsForm"
							templatePath="questions.answerOptions"
						/>
					);
				case QuestionTypes.MatrixMultipleChoice:
					return (
						<MatrixMultipleChoiceQuestion
							key={`questions.${questionIndex}`}
							addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
							createQuestionGroup={createQuestionGroup}
							createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
							getFullState={getFullState}
							reduceAnswerOptsLength={reduceAnswerOptsLength}
							removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
							removeQuestionAttachment={removeQuestionAttachment}
							removeQuestionGroup={removeQuestionGroup}
							setStateValue={setStateValue}
							updateMatrixQuestionGroup={updateMatrixQuestionGroup}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							templatePath="questions.answerOptions"
						/>
					);
				case QuestionTypes.MatrixSingleChoice:
					return (
						<MatrixSingleChoiceQuestion
							createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
							reduceAnswerOptsLength={reduceAnswerOptsLength}
							createQuestionGroup={createQuestionGroup}
							removeQuestionGroup={removeQuestionGroup}
							getFullState={getFullState}
							setStateValue={setStateValue}
							validateForm={validateForm}
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							templatePath="questions.answerOptions"
							uploadQuestionMaterial={uploadQuestionMaterial}
							removeQuestionAttachment={removeQuestionAttachment}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.SingleChoice:
					return (
						<SingleChoiceQuestion
							createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
							getFullState={getFullState}
							initSingleChoiceQuestion={initSingleChoiceQuestion}
							removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							updateAnswerOptsFlag={updateAnswerOptsFlag}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							formName="testOnlyQuestionsForm"
							templatePath="questions.answerOptions"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.DragAndDrop:
				case QuestionTypes.RationalScoringDragAndDrop:
					return (
						<DragAndDropQuestion
							addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
							createQuestionGap={createQuestionGap}
							getFullState={getFullState}
							removeGap={removeGap}
							removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							templatePath="questions.answerOptions"
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.ClozeDropDown:
				case QuestionTypes.RationalScoringDropDown:
					return (
						<ClozeDropDownQuestion
							createGroupAnswerOptionItem={createGroupAnswerOptionItem}
							createQuestionGap={createQuestionGap}
							getFullState={getFullState}
							removeGap={removeGap}
							removeGapOption={removeGapOption}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							updateCorrectFlag={updateCorrectFlag}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.DropDownTable:
					return (
						<DropDownTableQuestion
							createGroupAnswerOptionItem={createGroupAnswerOptionItem}
							createQuestionGroup={createQuestionGroup}
							getFullState={getFullState}
							removeGap={removeGap}
							removeGapOption={removeGapOption}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							updateCorrectFlag={updateCorrectFlag}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.MultipleResponseGroup:
					return (
						<MultipleResponseGroupQuestion
							createGroupAnswerOptionItem={createGroupAnswerOptionItem}
							createQuestionGap={createQuestionGap}
							getFullState={getFullState}
							removeGap={removeGap}
							removeGapOption={removeGapOption}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.HighlightTable:
					return (
						<HotspotHighlightTableQuestion
							clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
							createQuestionGap={createQuestionGap}
							getFullState={getFullState}
							removeGap={removeGap}
							removeQuestionAttachment={removeQuestionAttachment}
							setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
							setStateValue={setStateValue}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							templatePath="questions.answerOptions"
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.HotspotHighlight:
					return (
						<HotspotHighlightQuestion
							clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
							getFullState={getFullState}
							removeQuestionAttachment={removeQuestionAttachment}
							setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
							setStateValue={setStateValue}
							updateValidationCriteria={updateValidationCriteria}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							templatePath="questions.answerOptions"
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.BowTie:
					return (
						<BowTieQuestion
							createGroupAnswerOptionItem={createGroupAnswerOptionItem}
							getFullState={getFullState}
							initBowtieGroups={initBowtieGroups}
							removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
							removeQuestionAttachment={removeQuestionAttachment}
							setStateValue={setStateValue}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							formName="testOnlyQuestionsForm"
							statePath={`questions.${questionIndex}`}
							key={`questions.${questionIndex}`}
						/>
					);
				case QuestionTypes.CaseStudy:
					return (
						<CaseStudyQuestion
							initGroupingQuestion={initGroupingQuestion}
							moveGroupQuestionAnswerOption={moveGroupQuestionAnswerOption}
							templatePath="questions.answerOptions"
							addOneMoreQuestionAnswerOption={addOneMoreQuestionAnswerOption}
							clearArrayOfQuestionAnswerOption={clearArrayOfQuestionAnswerOption}
							createGroupAnswerOptionItem={createGroupAnswerOptionItem}
							createQuestionAnswerOptionItem={createQuestionAnswerOptionItem}
							createQuestionGap={createQuestionGap}
							createQuestionGroup={createQuestionGroup}
							createSingleMatrixQuestionAnswerOptions={createSingleMatrixQuestionAnswerOptions}
							createTab={createTab}
							duplicatePreviousTabs={duplicatePreviousTabs}
							getFullState={getFullState}
							initCaseStudyQuestion={initCaseStudyQuestion}
							reduceAnswerOptsLength={reduceAnswerOptsLength}
							removeGap={removeGap}
							removeGapOption={removeGapOption}
							removeQuestionAnswerOptionItem={removeQuestionAnswerOptionItem}
							removeQuestionAttachment={removeQuestionAttachment}
							removeQuestionData={removeQuestionData}
							removeQuestionGroup={removeQuestionGroup}
							removeTab={removeTab}
							setArrayOfQuestionAnswerOption={setArrayOfQuestionAnswerOption}
							setStateValue={setStateValue}
							updateAnswerOptsFlag={updateAnswerOptsFlag}
							updateCorrectFlag={updateCorrectFlag}
							updateMatrixQuestionGroup={updateMatrixQuestionGroup}
							updateValidationCriteria={updateValidationCriteria}
							uploadQuestionMaterial={uploadQuestionMaterial}
							validateForm={validateForm}
							statePath={`questions.${questionIndex}`}
							formName="testOnlyQuestionsForm"
							key={`questions.${questionIndex}`}
						/>
					);
				default:
					return null;
			}
		},
		[editIndex, questions]
	);

	const questionItems = questions.map((questionInputs, questionIndex) => {
		const { _expanded, deleted, text, typeId } = questionInputs;
		if (deleted.value) {
			return null;
		}
		const expanded = _expanded.value;
		nonDeletedQuestionCount++;

		return (
			<Box key={`createLesson-questionItem-${questionIndex}`} id={`createLesson-questionItem-${questionIndex}`}>
				<Card>
					<Box display="flex" flexDirection="row" alignItems="center">
						<Box display="flex" justifyContent="space-between" alignItems="center" width="89%">
							<Typography className={classes.header}>{nonDeletedQuestionCount}</Typography>
							{!expanded ? (
								<CustomInput
									style={{ flex: 1 }}
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: text }}
								/>
							) : (
								<Typography style={{ flex: 1 }} className={classes.header}>
									Question
								</Typography>
							)}
							<CustomInput
								className={classes.questionTypeInput}
								width={247}
								ml={6}
								theme={theme}
								inputProps={{
									onChange: () => {
										dispatch(
											removeQuestionData({
												statePath: `testOnlyQuestionsForm.inputs.questions.${questionIndex}`
											})
										);
										dispatch(editSequencingAnswerOptions());
										setTimeout(() => {
											dispatch(
												validateForm({
													formStatePath: "testOnlyQuestionsForm",
													markInputsAsDirty: true
												})
											);
										}, 100);
									}
								}}
								options={{ ...defaultCustomInputOptions, inputData: typeId }}
							/>
						</Box>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<IconButton
								size="medium"
								style={{ color: theme.palette.colors.basic[100] }}
								onClick={() =>
									dispatch(
										removeQuestionItem({
											formName: "testOnlyQuestionsForm",
											inputsPath: `inputs.questions.${questionIndex}`
										})
									)
								}
							>
								<Delete fontSize="large" color="disabled" />
							</IconButton>
							<IconButton
								size="medium"
								style={{ color: theme.palette.colors.basic[100] }}
								onClick={() => setEditIndex(index => (index === questionIndex ? -1 : questionIndex))}
							>
								<Edit fontSize="large" color="disabled" />
							</IconButton>
						</Box>
					</Box>
					{getLessonQuestion(typeId.value, questionIndex)}
				</Card>
				<Box className={classes.divider}>
					<Box
						className={classes.button}
						onClick={() => {
							dispatch(
								createSubItem({
									formName: "testOnlyQuestionsForm",
									index: questionIndex + 1,
									inputsPath: "inputs.questions",
									templatePath: "questions"
								})
							);
							setEditIndex(questionIndex + 1);
						}}
					>
						<Add style={{ fill: "white" }} fontSize="large" />
					</Box>
				</Box>
			</Box>
		);
	});

	return (
		<Box pt={3} pr={4}>
			{isLoading ? (
				<Box display="flex" alignItems="center" justifyContent="center" height={450} width="100%">
					<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
				</Box>
			) : (
				<Box>
					<Box>
						{saveIsLoading && (
							<Box
								display="flex"
								alignItems="center"
								width={80}
								justifyContent="space-between"
								position="absolute"
								top={-35}
								left={0}
							>
								<Typography variant="caption">Saving...</Typography>
								<CircularProgress size={24} />
							</Box>
						)}
					</Box>

					<Box display="flex">
						<Box width={"30%"}>
							<Box display="flex" alignItems="center" className={classes.fullHeight} component={Card}>
								<Box ml={4} width={"80%"}>
									<Typography variant="body1">Score to pass</Typography>
								</Box>
								<CustomInput
									className={classes.passingThresholdInput}
									width={120}
									theme={theme}
									InputProps={{
										endAdornment: <InputAdornment position="end">%</InputAdornment>
									}}
									options={{ ...defaultCustomInputOptions, inputData: passingThresholdInput }}
								/>
							</Box>
						</Box>
						<Box ml={2} style={{ flex: 1 }}>
							<Box component={Card} display="flex" justifyContent="flex-end">
								<Box>
									<Typography align="right" className={classes.questionsCount}>
										{nonDeletedQuestionCount}
									</Typography>
									<Typography align="right" className={classes.questionsCountSub}>
										Total Questions
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>

					<Box className={classes.divider}>
						<Box
							className={classes.button}
							onClick={() => {
								dispatch(
									createSubItem({
										formName: "testOnlyQuestionsForm",
										inputsPath: "inputs.questions",
										templatePath: "questions"
									})
								);
								setEditIndex(questions.length);
							}}
						>
							<Add style={{ fill: "white" }} fontSize="large" />
						</Box>
					</Box>
					{questionItems}
				</Box>
			)}
		</Box>
	);
};
