import React from "react";

import { Box, Card, Typography, useTheme } from "@material-ui/core";

import { Email, Home, Language, Phone } from "@material-ui/icons";

import { CheckSvgIcon, CrossSvgIcon, IconWrapper, TestSvgIcon, useStyles } from "./styles";

import CrossSvg from "../../assets/icons/icon-close-red.svg";
import TestSvg from "../../assets/icons/icon-filled-test.svg";
import CheckSvg from "../../assets/icons/icon-message-check-green.svg";

import { Country, UserBadgeTypeEnum, UserShippingDetailsItem } from "../../models";
import { IExtendedTheme } from "../../theme/default";
import { getAccountAddress } from "../../utils/getAccountAddress";
import { ProfileAvatar } from "../Avatar";
import InfoBlock from "../InfoBlock";
import { RemarBadge } from "../RemarBadge";

interface IStudentAccountInfoCard {
	profilePictureUrl?: string;
	firstName?: string;
	lastName?: string;
	email?: string;
	schoolName?: string;
	phoneNumber?: string;
	address?: UserShippingDetailsItem;
	countries?: Country[];
	testsInfo?: UserTestsInfoResponseDto | null;
	badgeType?: UserBadgeTypeEnum;
}

interface UserTestsInfoResponseDto {
	totalQuizzes: 0;
	totalPassed: 0;
	totalFailed: 0;
}

const StudentAccountInfoCard = ({
	profilePictureUrl,
	firstName,
	lastName,
	testsInfo,
	email,
	schoolName,
	phoneNumber,
	address,
	badgeType,
	countries
}: IStudentAccountInfoCard) => {
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();

	const testsData = [
		{ title: "Total Tests", text: testsInfo?.totalQuizzes, Icon: <TestSvgIcon src={TestSvg} /> },
		{ title: "Passed", text: testsInfo?.totalPassed, Icon: <CheckSvgIcon src={CheckSvg} /> },
		{ title: "Failed", text: testsInfo?.totalFailed, Icon: <CrossSvgIcon src={CrossSvg} /> }
	];

	return (
		<Box className={classes.userInfoContainer}>
			<Card className={classes.userInfoCard}>
				<Box className={classes.cardHeader}>
					<Box display="flex">
						<Box className={classes.imgAndIconContainer}>
							<ProfileAvatar
								profilePictureUrl={profilePictureUrl}
								firstName={firstName || "A"}
								lastName={lastName || ""}
							/>
						</Box>
						<Box className={classes.nameContainer}>
							<Typography className={classes.name}>{`${firstName} ${lastName}`}</Typography>
							{badgeType && <RemarBadge type={badgeType} size="large" />}
						</Box>
					</Box>
					<Box className={classes.summaryCard}>
						{testsInfo &&
							testsData.map(({ Icon, text, title }) => (
								<InfoBlock
									key={title}
									customBgColor={theme.palette.background.default}
									title={title}
									text={text || 0}
									IconSvg={() => <IconWrapper $bgColor={theme.palette.colors.primary[500]}>{Icon}</IconWrapper>}
									containerCustomStyle={{
										margin: 0,
										maxWidth: "initial",
										borderRadius: "4px 0 0 4px",
										display: "flex",
										alignItems: "center"
									}}
									textContainerCustomStyle={{ display: "flex", flexDirection: "column-reverse" }}
									titleCustomStyle={{ fontSize: 13 }}
									textCustomStyle={{ fontSize: 18 }}
								/>
							))}
					</Box>
				</Box>
				<Box className={classes.cardBody}>
					<Box className={classes.bodyRow}>
						<Box className={classes.infoContainer}>
							<Email style={{ width: "30px", height: "30px", fill: theme.palette.colors.basic[600] }} />
							<Box>
								<Typography className={classes.infoText}>{email || "-"}</Typography>
								<Typography className={classes.infoName}>Email address</Typography>
							</Box>
						</Box>
						<Box className={classes.infoContainer}>
							<Language style={{ width: "30px", height: "30px", fill: theme.palette.colors.basic[600] }} />
							<Box>
								<Typography className={classes.infoText}>{schoolName || "-"}</Typography>
								<Typography className={classes.infoName}>School</Typography>
							</Box>
						</Box>
					</Box>
					<Box className={classes.bodyRow}>
						<Box className={classes.infoContainer}>
							<Phone style={{ width: "30px", height: "30px", fill: theme.palette.colors.basic[600] }} />
							<Box>
								<Typography className={classes.infoText}>{phoneNumber || "-"}</Typography>
								<Typography className={classes.infoName}>Phone Number</Typography>
							</Box>
						</Box>
						{address && countries && (
							<Box className={classes.infoContainer}>
								<Home style={{ width: "30px", height: "30px", fill: theme.palette.colors.basic[600] }} />
								<Box>
									<Typography className={classes.infoText}>{getAccountAddress(address, countries) || "-"}</Typography>
									<Typography className={classes.infoName}>Address</Typography>
								</Box>
							</Box>
						)}
					</Box>
				</Box>
			</Card>
		</Box>
	);
};

export default StudentAccountInfoCard;
