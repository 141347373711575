import {
	BaseApiService,
	BaseDeleteDto,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto
} from "@remar/shared/dist/api/baseApiService";
import { request } from "@remar/shared/dist/api/request";
import { AssignCourse, CancelSubscription } from "@remar/shared/dist/models";

import { AddDaysDto, IStudentChangeStartDateResponse, UserChangeEmailDto, UserChangeEmailResponseDto } from "./dto";

import { URLS } from "../services.constants";
import { UserModelDto } from "../users";

export class StudentsService extends BaseApiService<
	UserModelDto,
	Record<string, unknown>,
	BaseFindDto,
	BaseFindOneDto,
	BaseUpdateDto<Record<string, unknown>>,
	BaseDeleteDto
> {
	constructor(baseUrl: string) {
		super(baseUrl);
	}
	cancelSubscription(data: CancelSubscription): Promise<Record<string, unknown>> {
		return request(
			`${this.baseUrl}/subscription/cancel`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<Record<string, unknown>>;
	}

	subscriptionAssignCourse(data: AssignCourse): Promise<Record<string, unknown>> {
		return request(
			`${this.baseUrl}/subscription/assign-course`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<Record<string, unknown>>;
	}
	changeEmail(data: UserChangeEmailDto): Promise<UserChangeEmailResponseDto> {
		return request(
			`${this.baseUrl}/change-email`,
			"POST",
			this.emptyStringToNull(data) as Record<string, unknown>
		) as Promise<UserChangeEmailResponseDto>;
	}
	addDays(data: AddDaysDto): Promise<UserChangeEmailResponseDto> {
		return request(
			`${this.baseUrl}/add-days-subscription`,
			"POST",
			this.emptyStringToNull(data) as Record<number, unknown>
		) as Promise<UserChangeEmailResponseDto>;
	}

	changeSubStartDate({
		userId,
		subscriptionId,
		startDate
	}: {
		userId: string;
		subscriptionId: number;
		startDate: string;
	}): Promise<IStudentChangeStartDateResponse> {
		return request(
			`${this.baseUrl}/${userId}/subscription`,
			"PATCH",
			this.emptyStringToNull({ subscriptionId, startDate }) as Record<number, unknown>
		) as Promise<IStudentChangeStartDateResponse>;
	}

	pauseSubscription({ userId, noOfDays }: { userId: number; noOfDays: number }): Promise<unknown> {
		return request(
			`${this.baseUrl}/pause-subscription`,
			"POST",
			this.emptyStringToNull({ userId, noOfDays }) as Record<number, unknown>
		) as Promise<unknown>;
	}

	resumeSubscription({ userId }: { userId: number }): Promise<unknown> {
		return request(
			`${this.baseUrl}/resume-subscription`,
			"POST",
			this.emptyStringToNull({ userId }) as Record<number, unknown>
		) as Promise<unknown>;
	}
}

export const studentsService = new StudentsService(URLS.USERS);
