import React, { useEffect, useState } from "react";

import { Box, Button, Card, CircularProgress, Container, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { useStyles } from "@remar/shared/dist/styles";
import { useDispatch, useSelector } from "react-redux";
import { addStudentCATQuota, getFullState, getStudentCATQuota } from "store/features/Students/students.slice";

import { AddCATWrapper, Label } from "./styles";

import { InputNumberWithControls } from "../../../../../../Components/FormikFields";

const MAX_CAT_EXAMS = 5;
const MIN_CAT_EXAMS = 0;
const AddCatExams = ({ showAddCATModal, onClose }) => {
	const { fullName, userId } = showAddCATModal;
	const [CAT, setCAT] = useState(1);
	const classes = useStyles();
	const dispatch = useDispatch();
	const { catQuota, catQuotaLoading, addCatQuotaLoading } = useSelector(getFullState);
	const availableCATQuota = catQuota?.totalAvailable || 0;
	useEffect(() => {
		dispatch(getStudentCATQuota({ userId }));
	}, [dispatch]);

	const handleAddCATChange = val => {
		setCAT(val);
	};
	const handleSubmit = () => {
		dispatch(
			addStudentCATQuota({
				userId,
				quantity: CAT,
				cb: () => {
					onClose();
				}
			})
		);
	};
	return (
		<Modal className={classes.modal} disableEnforceFocus disableAutoFocus open={true} onClose={onClose}>
			<Container maxWidth="md">
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Card style={{ width: "550px" }}>
						{catQuotaLoading ? (
							<ContentLoader height={250} />
						) : (
							<>
								<Box height={"85%"}>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<Typography className={classes.title} variant="h6">
											Assign CAT test
										</Typography>
										<IconButton onClick={onClose}>
											<CloseIcon color="disabled" />
										</IconButton>
									</Box>
									<Box>
										<Box mt={3} display={"flex"} alignItems={"center"}>
											<Box mr={1}>
												<Label variant="caption">{fullName} currently has</Label>
											</Box>
											<Box>
												<Typography style={{ fontWeight: 700, fontSize: "15px" }}>
													{availableCATQuota} CAT tests
												</Typography>
											</Box>
										</Box>
										<AddCATWrapper mt={3}>
											<Box display={"column"}>
												<Typography className={"title"}>Add CAT tests</Typography>
												<Typography className={"info"}>Maximum 5 tests per student</Typography>
											</Box>
											<Box display={"flex"} justifyContent={"flex-end"}>
												<InputNumberWithControls
													value={CAT}
													InputProps={{
														disableUnderline: true
													}}
													inputProps={{
														min: MIN_CAT_EXAMS,
														max: MAX_CAT_EXAMS,
														style: {
															textAlign: "center"
														}
													}}
													onChange={handleAddCATChange}
													disabled={false}
													style={{
														width: "60px"
													}}
												/>
											</Box>
										</AddCATWrapper>
									</Box>
								</Box>
								<Box display="flex" justifyContent="flex-end" mt={3}>
									<Button variant="outlined" className={classes.cancelBtn} onClick={onClose}>
										Cancel
									</Button>
									<Button
										variant="contained"
										color="primary"
										className={classes.save}
										onClick={handleSubmit}
										disabled={!CAT || addCatQuotaLoading || CAT > MAX_CAT_EXAMS || CAT < MIN_CAT_EXAMS}
										startIcon={
											addCatQuotaLoading && (
												<CircularProgress size="1rem" color="inherit" thickness={5} variant="indeterminate" />
											)
										}
									>
										Assign
									</Button>
								</Box>
							</>
						)}
					</Card>
				</Box>
			</Container>
		</Modal>
	);
};

export default AddCatExams;
