import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Chip, CircularProgress, FormControl, Input, MenuItem, Modal, Select } from "@material-ui/core";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { AutocompleteFilter } from "@remar/shared/dist/components/AutocompleteFilter";
import HeaderWrapper from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";
import InfoBlock from "@remar/shared/dist/components/InfoBlock";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import ReadMore from "@remar/shared/dist/components/ReadMore";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";

import {
	ColumnHeader,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { Question, Subjects } from "@remar/shared/dist/models";
import { getSanitizedText } from "@remar/shared/dist/utils/textUtils";

import { ThumbsDownSvg, ThumbsUpSvg } from "admin/src/assets/icons";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
	deleteCommunityQuestion,
	fetchCommunityQuestions,
	fetchQuestionTypes,
	fetchSubjectCategories,
	getFullState
} from "store/features/ManageCommunityPool/ManageCommunityPool.slice";

import { IconTotalSvg } from "assets/icons";

import { routes } from "core/constants";

import { DeleteModalContent } from "./DeleteModalContent";
import { StyledListIcon, THeaderWrapper, VoteText, useStyles } from "./styles";

import PreviewQuestion from "../QuestionBank/PreviewQuestion";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Community Pool", key: 1 }
];

const CommunityPool: React.FC = () => {
	const [questionTypes, setQuestionTypes] = useState<string[]>([]);
	const [selectedSubjects, setSelectedSubjects] = useState<Subjects[]>([]);
	const [questionToBeDeleted, setQuestionToBeDeleted] = useState<number>(0);
	const [questionToPreview, setQuestionToPreview] = useState<Question | null>(null);
	const classes = useStyles();
	const [searchText, setSearchText] = useState("");
	const history = useHistory();
	const dispatch = useDispatch();
	const { isLoading, perPage, page, totalItems, questions, subjects, questionTypeOptions, isDeleteLoading } =
		useSelector(getFullState);
	const parsedQuestionTypes = useMemo(
		() => (!!questionTypes.length ? questionTypes.map(q => JSON.parse(q).value) : []),
		[questionTypes]
	);

	useEffect(() => {
		if (!!parsedQuestionTypes.length) {
			dispatch(
				fetchCommunityQuestions({
					page: 1,
					questionTypes: parsedQuestionTypes
				})
			);
		} else {
			dispatch(fetchCommunityQuestions({}));
			dispatch(fetchQuestionTypes());
			dispatch(fetchSubjectCategories());
		}
	}, [dispatch, parsedQuestionTypes]);

	const handleSelectQuestionType = val => {
		setQuestionTypes(val);
	};

	const handleSelectSubject = useCallback(
		sbjcts => {
			setSelectedSubjects(sbjcts);
			dispatch(fetchCommunityQuestions({ page: 1, questionTypes: parsedQuestionTypes, subjects: sbjcts }));
		},
		[dispatch, parsedQuestionTypes]
	);

	const handleSelectPagination = useCallback(
		(event, page) => {
			dispatch(
				fetchCommunityQuestions({ page, searchText, questionTypes: parsedQuestionTypes, subjects: selectedSubjects })
			);
		},
		[parsedQuestionTypes, selectedSubjects]
	);

	const handleSearchBarChange = useCallback(
		searchText => {
			return dispatch(
				fetchCommunityQuestions({
					searchText,
					page: 1,
					questionTypes: parsedQuestionTypes,
					subjects: selectedSubjects
				})
			);
		},
		[dispatch, parsedQuestionTypes, selectedSubjects]
	);

	const actions = (
		<Button
			variant={"contained"}
			color={"primary"}
			onClick={() => history.push(routes.communityPool.getPath() + "/manageSubjects")}
		>
			Manage Subjects
		</Button>
	);

	const tableColumns = [
		{
			alignment: "center",
			label: <ColumnHeader>Rate</ColumnHeader>,
			width: 140,
			Cell: ({ rowData: { totalDislike, totalLike } }) => (
				<StyledCellWrapper center>
					<VoteText>{totalLike}</VoteText>
					<ThumbsUpSvg />
					<VoteText>{totalDislike}</VoteText>
					<ThumbsDownSvg />
				</StyledCellWrapper>
			),
			dataKey: "rate"
		},
		{
			alignment: "left",
			label: <ColumnHeader>Question</ColumnHeader>,
			width: 800,
			Cell: ({ rowData: { text, ...rest } }) => (
				<StyledCellWrapper>
					<ReadMore onClick={() => setQuestionToPreview(rest as Question)}>{text}</ReadMore>
				</StyledCellWrapper>
			),
			ChildCell: ({ rowData: { text } }) => (
				<StyledCellWrapper style={{ paddingLeft: "20px" }}>
					<ReadMore>{text}</ReadMore>
				</StyledCellWrapper>
			),
			dataKey: "question"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Added By</ColumnHeader>,
			width: 100,
			Cell: ({ rowData: { owner } }) => (
				<StyledCellWrapper center>
					<StyledCellText>{getSanitizedText(owner.name)}</StyledCellText>
				</StyledCellWrapper>
			),
			ChildCell: ({ parentRowData: { owner } }) => (
				<StyledCellWrapper center>
					<StyledCellText>{getSanitizedText(owner.name)}</StyledCellText>
				</StyledCellWrapper>
			),
			dataKey: "owner"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Subject</ColumnHeader>,
			width: 200,
			Cell: ({ rowData: { subjectCategories } }) => (
				<StyledCellWrapper center>
					{!!subjectCategories.length ? (
						subjectCategories.map(c => <StyledCellText key={c.id}>{c.name}</StyledCellText>)
					) : (
						<StyledCellText>{"N/A"}</StyledCellText>
					)}
				</StyledCellWrapper>
			),
			ChildCell: ({ parentRowData: { subjectCategories } }) => (
				<StyledCellWrapper center>
					{!!subjectCategories.length ? (
						subjectCategories.map(c => <StyledCellText key={c.id}>{c.name}</StyledCellText>)
					) : (
						<StyledCellText>{"N/A"}</StyledCellText>
					)}
				</StyledCellWrapper>
			),
			dataKey: "subject"
		},
		{
			alignment: "center",
			label: <ColumnHeader>Question Type</ColumnHeader>,
			Cell: ({
				rowData: {
					type: { name }
				}
			}) => (
				<StyledCellWrapper center>
					<StyledCellText>{name}</StyledCellText>
				</StyledCellWrapper>
			),
			ChildCell: ({
				rowData: {
					type: { name }
				}
			}) => (
				<StyledCellWrapper center>
					<StyledCellText>{name}</StyledCellText>
				</StyledCellWrapper>
			),
			dataKey: "type"
		},
		{
			alignment: "left",
			width: 90,
			Cell: ({ rowData: { id } }) => (
				<ActionMenu
					customMenuItems={[
						{ label: "Delete", onClick: () => setQuestionToBeDeleted(id), disabled: false, visible: true }
					]}
				/>
			),
			dataKey: "menu"
		}
	];

	return (
		<>
			<HeaderWrapper heading="Community pool" breadcrumb={breadcrumb} actions={actions} />
			<Box display={"flex"} flexWrap={"wrap"} gridGap="24px">
				<InfoBlock customBgColor={"#141F30"} title={"Total Questions"} text={totalItems} IconSvg={IconTotalSvg} />
			</Box>
			<Box mr={3} mt={4}>
				<THeaderWrapper>
					<Box display={"flex"} flex={1}>
						<THeaderTitle>Questions</THeaderTitle>
					</Box>
					<Box mr={1}>
						<StyledListIcon />
					</Box>
					<Box mr={2}>
						<FormControl hiddenLabel size="small" variant="filled">
							<Select
								className={classes.multiSelectBox}
								multiple
								disableUnderline
								displayEmpty
								value={questionTypes}
								onChange={event => handleSelectQuestionType(event.target.value)}
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
								input={<Input />}
								renderValue={selected => {
									if ((selected as string[]).length === 0) return <div className={classes.chips}>Question type</div>;
									return (
										<div className={classes.chips}>
											{(selected as string[]).map((s, i) => {
												const { text } = JSON.parse(s);
												return <Chip key={i} label={text} className={classes.chip} />;
											})}
										</div>
									);
								}}
							>
								<MenuItem value="" disabled>
									Question type
								</MenuItem>
								{questionTypeOptions.map(({ text, value }) => (
									<MenuItem key={value} value={JSON.stringify({ text, value })}>
										{text}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<Box mr={1}>
						<StyledListIcon />
					</Box>
					<Box mr={2}>
						<FormControl hiddenLabel size="small" variant="filled">
							<AutocompleteFilter
								options={subjects}
								value={selectedSubjects}
								setValue={handleSelectSubject}
								filterName="Subjects"
							/>
						</FormControl>
					</Box>
					<SearchBarComponent
						inputValue={searchText}
						setInputValue={setSearchText}
						onChange={handleSearchBarChange}
						isDebounced={true}
						debounceTime={1000}
						placeHolder={"Type to filter by keywords"}
					/>
				</THeaderWrapper>
				{isLoading ? (
					<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</Box>
				) : (
					<>
						<MaterialTable
							columns={tableColumns as IColumn<Question>[]}
							data={questions || []}
							height={800}
							hasNestedRows={true}
						/>

						{perPage < totalItems && (
							<TablePagination count={totalItems} page={page} onChange={handleSelectPagination} rowsPerPage={perPage} />
						)}
					</>
				)}
			</Box>
			<Modal
				className={classes.modal}
				disableEnforceFocus
				disableAutoFocus
				open={!!questionToBeDeleted}
				onClose={() => setQuestionToBeDeleted(0)}
			>
				<DeleteModalContent
					onClose={() => setQuestionToBeDeleted(0)}
					title="Delete Question"
					message="Are you sure you want to delete this question?"
					isLoading={isDeleteLoading}
					onDelete={() => {
						dispatch(
							deleteCommunityQuestion({
								id: questionToBeDeleted,
								cb: () => {
									setQuestionToBeDeleted(0);
									dispatch(
										fetchCommunityQuestions({
											page,
											searchText,
											questionTypes: parsedQuestionTypes,
											subjects: selectedSubjects
										})
									);
								}
							})
						);
					}}
				/>
			</Modal>
			{!!questionToPreview && (
				<PreviewQuestion question={questionToPreview} onClose={() => setQuestionToPreview(null)} />
			)}
		</>
	);
};

export default CommunityPool;
