import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@remar/shared/dist/components/Button";
import Uploader from "@remar/shared/dist/components/Uploader";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import DialogModal from "@remar/shared/dist/modals/DialogModal/DialogModal";
import { ButtonActionEnum, NotificationAudienceTypeIdEnum, NotificationTypeId } from "@remar/shared/dist/models";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { isBefore } from "date-fns";
import { useFormik } from "formik";

import { debounce, isEmpty, isNil, omitBy } from "lodash";
import { Subject } from "rxjs";
import { cleanError, createUpdateNotification, selectBannersFullState } from "store/features/Banners/banners.slice";
import { getCouponTypes, getCoupons, selectCouponFullState } from "store/features/Coupons/coupons.slice";
import { Coupon } from "store/features/Coupons/models";
import { emit } from "store/features/notifications/notifications.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

import { CreateNotificationDto } from "store/services/notifications/dto";

import { AnnouncementNotificationSvg, BannerNotificationSvg, IconInfo, PushNotificationSvg } from "assets/icons";

import { IFormValues, validationSchema } from "./const";
import { TypeItem, TypeTitle } from "./styles";

import {
	Checkbox,
	DatePickerComponent,
	RadioInput,
	SelectInput,
	TextAreaInput,
	TextField,
	TimePicker
} from "../../../../../Components/FormikFields";
import CreateEditCoupons, { FormValuesType } from "../../Coupons/CreateEditCoupons";
import { Label } from "../../Coupons/styles";

const icons = {
	[NotificationTypeId.AdminGeneratedBanner]: <BannerNotificationSvg />,
	[NotificationTypeId.AdminGeneratedPush]: <PushNotificationSvg />,
	[NotificationTypeId.AdminGeneratedAnnouncement]: <AnnouncementNotificationSvg />
};

const allowedFormats = [".jpeg", ".jpe", ".jpg", ".png"];

const notificationTypes = [
	{ label: "Banner", value: NotificationTypeId.AdminGeneratedBanner },
	{ label: "Push", value: NotificationTypeId.AdminGeneratedPush },
	{ label: "Announcement", value: NotificationTypeId.AdminGeneratedAnnouncement }
];

const actions = [
	{ label: "Apply coupon to payment", value: ButtonActionEnum.ApplyCoupon },
	{ label: "Dismiss", value: ButtonActionEnum.Dismiss },
	{ label: "Open Link", value: ButtonActionEnum.OpenLink }
];

const initialValues = {
	sendAtDate: new Date(Date.now() + 5 * 60 * 1000),
	expiresAtDate: new Date(Date.now() + 10 * 60 * 1000),
	isDismissible: false,
	notificationTypeId: NotificationTypeId.AdminGeneratedBanner,
	notificationAudienceTypeId: NotificationAudienceTypeIdEnum.AllUsers.toString()
};

type CreateNotificationProps = {
	notification: CreateNotificationDto | null;
	onClose: () => void;
};
/**
 * CreateEditNotification Component
 *
 * @param {Object} notification - The notification object
 * @param {function} onClose - The function to close the notification
 *
 * @returns {ReactElement} - The CreateEditNotification component
 */
const CreateEditNotification = ({ notification, onClose }: CreateNotificationProps) => {
	const dispatch = useAppDispatch();
	const theme = useTheme<IExtendedTheme>();

	useEffect(() => {
		dispatch(getCoupons({}));
		dispatch(getCouponTypes());
	}, [dispatch]);

	const { isLoading, error } = useAppSelector(selectBannersFullState);
	const { Coupons, couponsLoading } = useAppSelector(selectCouponFullState);

	const {
		isValid,
		touched,
		resetForm,
		setValues,
		errors,
		handleSubmit,
		handleBlur,
		setFieldValue,
		handleChange,
		values: {
			buttonAction,
			notificationTypeId,
			buttonSuccessText,
			buttonCancelText,
			addDatePeriod,
			isDismissible,
			notificationAudienceTypeId,
			sendAtDate,
			expiresAtDate,
			body,
			title,
			mobileBody,
			mobileTitle,
			buttonUrl
		}
	} = useFormik<IFormValues>({
		initialValues,
		validationSchema,
		onSubmit: values => {
			dispatch(createUpdateNotification(values!)).then(r => {
				if (r.error) return;
				resetForm();
				onClose();
			});
		}
	});

	const coupon = useMemo(() => {
		return Coupons?.items.find(c => c.id === notification?.couponId);
	}, [Coupons?.items, notification?.couponId]);

	const [selectedCoupon, setSelectedCoupon] = useState<Coupon | undefined>(coupon);
	const [showCreateCoupon, setShowCreateCoupon] = useState(false);

	useEffect(() => {
		if (notification?.id) {
			setValues({
				...omitBy(notification, isNil),
				notificationAudienceTypeId: notification.notificationAudienceTypeId.toString(),
				sendAtDate: new Date(notification.sendAt),
				expiresAtDate: notification.expiresAt ? new Date(notification.expiresAt) : undefined,
				buttonAction: notification.data?.primaryButton?.action,
				buttonUrl: notification.data?.primaryButton?.url,
				buttonSuccessText: notification.data?.primaryButton?.text,
				buttonCancelText: notification.data?.secondaryButton?.text
			});

			if (coupon) {
				setSelectedCoupon(coupon);
			}
		}
	}, [notification, setValues]);

	const actionTitle = useMemo(() => {
		switch (buttonAction) {
			case ButtonActionEnum.ApplyCoupon:
				return "Linked Coupon";
			case ButtonActionEnum.OpenLink:
				return "Url";
		}
	}, [buttonAction]);

	const clearFileInputSubject = new Subject<void>();

	const debouncedSearch = useCallback(
		debounce((searchText: string) => {
			dispatch(getCoupons({ page: 1, searchText }));
		}, 500),
		[]
	);

	const handleClose = _ => {
		if (_.relatedTarget?.id === "create_coupon") {
			setShowCreateCoupon(true);
		}
	};

	return (
		<>
			<DialogModal
				open={!!notification}
				title={`${!!notification?.id ? "Edit" : "Create New"} Notification`}
				maxWidth={1048}
				cancelBtnText="Cancel"
				successBtnText={`${!!notification?.id ? "Edit" : "Create New"} Notification`}
				onClose={() => {
					resetForm();
					dispatch(cleanError());
					onClose();
				}}
				onSuccess={handleSubmit}
				successBtnDisabled={!isValid || isLoading || isEmpty(touched)}
			>
				{isLoading ? (
					<ContentLoader />
				) : (
					<Box
						p="16px 24px"
						component="form"
						onSubmit={e => handleSubmit(e as unknown as React.FormEvent<HTMLFormElement>)}
					>
						<Grid container spacing={2}>
							<Grid item md={6} xs={12}>
								<Box display="flex" justifyContent="space-between">
									{notificationTypes.map(v => {
										const color = v.value === notificationTypeId ? theme.palette.primary.main : "";
										return (
											<Box key={v.value} display="flex" flexDirection="column" alignItems="center">
												<TypeItem onClick={() => setFieldValue("notificationTypeId", v.value)} color={color}>
													{icons[v.value]}
												</TypeItem>
												<TypeTitle color={color}>{v.label}</TypeTitle>
											</Box>
										);
									})}
								</Box>
								<Box display="flex" justifyContent="space-between" gridGap={16} mt={4}>
									<IconInfo width={20} height={20} fill={theme.palette.colors.basic[500]} />
									<Typography style={{ fontSize: 13, fontWeight: 400, color: theme.palette.colors.basic[500] }}>
										Banner is a prominent message at the top. It appears without sound and it can be persistent or
										dismissible. Only one banner can be active at the same time.
									</Typography>
								</Box>
								<Box mt={4}>
									<Box mt={1} mb={1}>
										<Label>Audience Users</Label>
									</Box>
									<RadioInput
										name="notificationAudienceTypeId"
										options={[
											{
												label: "All Users",
												value: NotificationAudienceTypeIdEnum.AllUsers.toString()
											},
											{
												label: "Paid Users",
												value: NotificationAudienceTypeIdEnum.PaidUsers.toString()
											},
											{
												label: "Trial",
												value: NotificationAudienceTypeIdEnum.TrialUsers.toString()
											}
										]}
										value={notificationAudienceTypeId}
										onBlur={handleBlur}
										onChange={handleChange}
									/>
								</Box>
								<Box mt={4}>
									<Box mt={1} mb={1}>
										<Label>Send Date & Time</Label>
									</Box>
									<Grid container spacing={2}>
										<Grid item xs={6}>
											<DatePickerComponent
												name="sendAtDate"
												minDate={new Date()}
												value={sendAtDate}
												onChange={d => {
													setFieldValue("sendAtDate", d);
													handleBlur({ target: { name: "sendAtDate" } });
												}}
											/>
											{notificationTypeId !== NotificationTypeId.AdminGeneratedPush && isDismissible && (
												<Checkbox
													name="addDatePeriod"
													disabled={!isDismissible}
													label="Add Date Period"
													value={addDatePeriod}
													checked={addDatePeriod}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											)}
										</Grid>
										<Grid item xs={6}>
											<TimePicker
												name="sendAtDate"
												value={sendAtDate}
												onChange={t => {
													setFieldValue("sendAtDate", t);
													handleBlur({ target: { name: "sendAtDate" } });
												}}
											/>
											{notificationTypeId == NotificationTypeId.AdminGeneratedBanner && (
												<Checkbox
													disabled={false}
													name="isDismissible"
													label="Dismissible"
													value={isDismissible}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
											)}
										</Grid>
									</Grid>
								</Box>
								{notificationTypeId !== NotificationTypeId.AdminGeneratedPush && (addDatePeriod || !isDismissible) && (
									<Box mt={4}>
										<Box mt={1} mb={1}>
											<Label>Till Date & Time</Label>
										</Box>
										<Grid container spacing={2}>
											<Grid item xs={6}>
												<DatePickerComponent
													name="expiresAtDate"
													label="Expires At"
													value={expiresAtDate}
													onChange={d => {
														setFieldValue("expiresAtDate", d);
														handleBlur({ target: { name: "expiresAtDate" } });
													}}
												/>
											</Grid>
											<Grid item xs={6}>
												<TimePicker
													name="expiresAtDate"
													label="Expires At"
													value={expiresAtDate}
													onChange={t => {
														setFieldValue("expiresAtDate", t);
														handleBlur({ target: { name: "expiresAtDate" } });
													}}
												/>
											</Grid>
										</Grid>
									</Box>
								)}
							</Grid>

							<Grid item md={6} xs={12}>
								{notificationTypeId === NotificationTypeId.AdminGeneratedAnnouncement && (
									<>
										<Uploader
											accept={allowedFormats.join(",")}
											clearFileInputSubject={clearFileInputSubject}
											error={undefined}
											header={"Upload Photo"}
											// header={logo ? "" : "Upload Logo"}
											onRemove={() => {
												handleChange(undefined);
												clearFileInputSubject.next();
											}}
											icon={undefined}
											loading={false}
											onAdd={files => console.log(files[0])}
											progress={undefined}
											dragFileError="File type is not allowed."
											title="Drag and Drop File Here or Browse to Choose a File. JPEG, JGP, PNG types are allowed. Recommended Size: 128x128"
											confirmDescription="Are you sure you want to remove this logo?"
											emit={emit}
										/>
										<br />
									</>
								)}
								<Box display="flex" flexDirection="column" gridGap={16}>
									<Box mb={-1}>
										<Label>
											{notificationTypeId === NotificationTypeId.AdminGeneratedPush
												? "Desktop Title & Message"
												: "Desktop Message"}{" "}
										</Label>
									</Box>
									{notificationTypeId === NotificationTypeId.AdminGeneratedPush && (
										<TextField
											name="title"
											fullWidth
											placeholder="Enter Title"
											onChange={handleChange}
											onBlur={handleBlur}
											value={title}
										/>
									)}
									<TextAreaInput name="body" onChange={handleChange} onBlur={handleBlur} value={body} />
								</Box>
								<Box display="flex" flexDirection="column" gridGap={16} mb={4}>
									<Box mt={4} mb={-1}>
										<Label>
											{notificationTypeId === NotificationTypeId.AdminGeneratedPush
												? "Mobile Title & Message"
												: "Mobile Message"}{" "}
										</Label>
									</Box>
									{notificationTypeId === NotificationTypeId.AdminGeneratedPush && (
										<TextField
											name="mobileTitle"
											fullWidth
											placeholder="Enter Title"
											onChange={handleChange}
											onBlur={handleBlur}
											value={mobileTitle}
										/>
									)}
									<TextAreaInput name="mobileBody" onChange={handleChange} onBlur={handleBlur} value={mobileBody} />
								</Box>
								<Grid container spacing={2}>
									<Grid item xs={6} style={{ display: "flex", flexDirection: "column", gap: 16 }}>
										<Box mt={1} mb={-1}>
											<Label>Button Action</Label>
										</Box>
										<SelectInput
											name="buttonAction"
											options={actions}
											placeholder="Select Action"
											value={buttonAction}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{notificationTypeId === NotificationTypeId.AdminGeneratedPush && (
											<>
												<Button variant="filled" color="primary" fullWidth size="large">
													{!!buttonSuccessText ? buttonSuccessText : "Primary Button"}
												</Button>
												<br />
												<Button variant="outlined" color="basic" fullWidth size="large">
													{!!buttonCancelText ? buttonCancelText : "Secondary Button"}
												</Button>
											</>
										)}
									</Grid>
									<Grid
										item
										xs={6}
										style={{ display: "flex", flexDirection: "column", gap: 16, justifyContent: "flex-end" }}
									>
										{buttonAction && buttonAction !== ButtonActionEnum.Dismiss && (
											<>
												<Box mt={1} mb={-1}>
													<Label>{actionTitle}</Label>
												</Box>
												{buttonAction === ButtonActionEnum.ApplyCoupon && (
													<Autocomplete
														value={selectedCoupon}
														// classes={{ paper: classes.paper }}
														onChange={(_, newValue) => {
															setSelectedCoupon(newValue);
															setFieldValue("couponId", newValue.id);
														}}
														onInputChange={(_, newInputValue) => debouncedSearch(newInputValue)}
														filterOptions={options => {
															const currentDate = new Date();
															return options.filter(({ expiresOn, totalUsed, quantity }) => {
																const isExpired = isBefore(new Date(expiresOn), currentDate);
																const hasLimit = !!quantity ? totalUsed! < quantity : true;
																return !isExpired && hasLimit;
															});
														}}
														disableClearable
														clearOnBlur
														handleHomeEndKeys
														loading={couponsLoading}
														id="school"
														options={Coupons?.items || []}
														getOptionLabel={option => option.name}
														renderOption={option => option.name}
														onClose={handleClose}
														PaperComponent={({ children, ...rest }) => (
															<Paper elevation={8} {...rest}>
																{children}
																<Box m={1}>
																	<Button fullWidth variant={"filled"} color={"primary"} id={"create_coupon"}>
																		Create Coupon
																	</Button>
																</Box>
															</Paper>
														)}
														renderInput={params => (
															<TextField
																{...params}
																InputProps={{
																	...params.InputProps,
																	style: {
																		padding: "4px 8px",
																		color: theme.palette.text.primary,
																		background: "#2a2e37",
																		borderRadius: "5px",
																		height: "50px"
																	},
																	disableUnderline: true
																}}
															/>
														)}
													/>
												)}
												{buttonAction === ButtonActionEnum.OpenLink && (
													<TextField
														fullWidth
														placeholder="Enter Url"
														name="buttonUrl"
														onChange={handleChange}
														onBlur={handleBlur}
														value={buttonUrl}
													/>
												)}
											</>
										)}

										{notificationTypeId === NotificationTypeId.AdminGeneratedPush && (
											<>
												<TextField
													fullWidth
													placeholder="Get Coupon"
													name="buttonSuccessText"
													onChange={handleChange}
													onBlur={handleBlur}
													value={buttonSuccessText}
												/>
												<TextField
													fullWidth
													placeholder="Not Now"
													name="buttonCancelText"
													onChange={handleChange}
													onBlur={handleBlur}
													value={buttonCancelText}
												/>
											</>
										)}
									</Grid>
								</Grid>
								{notificationTypeId !== NotificationTypeId.AdminGeneratedPush && (
									<Box>
										<Box mt={1} mb={1}>
											<Label>Button Text</Label>
										</Box>
										<TextField
											fullWidth
											name="buttonSuccessText"
											onChange={handleChange}
											onBlur={handleBlur}
											value={buttonSuccessText}
										/>
									</Box>
								)}
							</Grid>
						</Grid>
						{(error || (!isEmpty(errors) && Object.keys(touched).some(k => Object.keys(errors).includes(k)))) && (
							<Grid container>
								<Grid xs={12} style={{ marginTop: 24 }}>
									{error && <Alert severity="error">Api Error: {error}</Alert>}
									{!isEmpty(errors) && (
										<Alert severity="warning">
											<ul>
												{Object.keys(errors)
													.filter(k => touched[k])
													.map(k => (
														<li key={k}>{errors[k]}</li>
													))}
											</ul>
										</Alert>
									)}
								</Grid>
							</Grid>
						)}
					</Box>
				)}
			</DialogModal>
			{showCreateCoupon && (
				<CreateEditCoupons initObj={{} as FormValuesType} onClose={() => setShowCreateCoupon(false)} />
			)}
		</>
	);
};

export default CreateEditNotification;
