import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	MenuItem,
	Select
} from "@material-ui/core";

import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { DropFilter, IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import { FilterTypes } from "@remar/shared/dist/components/MaterialTable/columnFilters/types";
import ReadMore from "@remar/shared/dist/components/ReadMore";
import SearchBarComponent from "@remar/shared/dist/components/SearchBar";
import {
	ColumnHeader,
	Container,
	StyledCellText,
	StyledCellWrapper,
	THeaderTitle
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import CloneQuestionModal from "@remar/shared/dist/modals/CloneQuestionModal/CloneQuestionModal";
import DeleteModal from "@remar/shared/dist/modals/DeleteModal/DeleteModal";
import { Question } from "@remar/shared/dist/models";
import { LocationEnum } from "@remar/shared/dist/services/subjectCategories/modal";
import Highlighter from "react-highlighter";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "store";
import {
	addQuestionToCommunity,
	fetchQuestionTypes,
	fetchQuestions,
	fetchQuestionsCount,
	filterType,
	getFullState,
	questionDelete,
	resetCourses
} from "store/features/QuestionBank/questionBank.slice";
import {
	fetchAllSubjectCategories,
	getFullState as getFullSubjectsState
} from "store/features/SubjectCategories/subjectCategories.slice";

import { IconListSvg as TotalIconSvg } from "assets/icons";

import { routes } from "core/constants";

import PreviewQuestion from "./PreviewQuestion";
import QuestionInfo from "./components/QuestionInfo";
import { StyledListIcon, THeaderWrapper } from "./styles";

import { Header, HeaderContainer } from "../ManageStudents/styles";

const QuestionBank = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { user } = useSelector((state: RootState) => state.auth);
	const {
		isLoading,
		perPage,
		filter,
		page,
		questions,
		totalItems,
		isCloning,
		isDeleting,
		questionsCount: { totalQuestionsCount, courses },
		questionTypes
	} = useSelector(getFullState);
	const {
		subjectCategories: { items: subjects }
	} = useSelector(getFullSubjectsState);
	const [searchText, setSearchText] = useState("");
	const [debouncedSearchText, setDebouncedSearchText] = useState("");
	const [deleteModal, setDeleteModal] = useState(false);
	const [addToCommunityPoolModal, setAddToCommunityPoolModal] = useState(0);
	const [deleteQuestion, setDeleteQuestion] = useState<{ id: number; clonedBy: Question[] }>();
	const [currentCourseId, setCurrentCourseId] = useState("");
	const [questionTypeFilter, setQuestionTypeFilter] = useState<string[]>([]);
	const [isQuestionPreview, setQuestionPreview] = useState(false);
	const [questionToPreview, setQuestionToPreview] = useState<Question | null>(null);
	const [deleteClones, setDeleteClones] = useState(false);

	useEffect(() => {
		dispatch(fetchAllSubjectCategories(LocationEnum.Common));
		dispatch(fetchQuestionTypes());
	}, [dispatch]);

	const checkboxFiltersValue = useMemo(
		() =>
			questionTypes.map(type =>
				questionTypeFilter.includes("" + type.id)
					? {
							...type,
							checked: true
					  }
					: type
			),
		[questionTypes, questionTypeFilter]
	);

	const tableColumns = useMemo(
		() => [
			{
				alignment: "left",
				label: <ColumnHeader>Question</ColumnHeader>,
				width: 800,
				Cell: ({ rowData }) => (
					<StyledCellWrapper>
						<ReadMore
							onClick={() => {
								setQuestionToPreview(rowData as Question);
								setQuestionPreview(true);
							}}
						>
							{rowData.text}
						</ReadMore>
					</StyledCellWrapper>
				),
				ChildCell: ({ rowData: { text } }) => (
					<StyledCellWrapper style={{ paddingLeft: "105px" }}>
						<Highlighter search={debouncedSearchText}>{text}</Highlighter>
					</StyledCellWrapper>
				),
				dataKey: "question"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Subject</ColumnHeader>,
				Cell: ({ rowData: { subjectLessons } }) => (
					<StyledCellWrapper>
						{subjectLessons.length > 0 ? (
							subjectLessons.map(subjectLesson => (
								<StyledCellText key={subjectLesson.subject?.id}>{subjectLesson.subject?.name}</StyledCellText>
							))
						) : (
							<StyledCellText>{"N/A"}</StyledCellText>
						)}
					</StyledCellWrapper>
				),
				ChildCell: ({ parentRowData: { subjectLessons } }) => (
					<StyledCellWrapper>
						{subjectLessons.length > 0 ? (
							subjectLessons.map(subjectLesson => (
								<StyledCellText key={subjectLesson.subject?.id}>{subjectLesson.subject?.name}</StyledCellText>
							))
						) : (
							<StyledCellText>{"N/A"}</StyledCellText>
						)}
					</StyledCellWrapper>
				),
				dataKey: "subject"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Lesson</ColumnHeader>,
				Cell: ({ rowData: { subjectLessons } }) => (
					<StyledCellWrapper>
						{subjectLessons.length > 0 ? (
							subjectLessons.map(subjectLesson => (
								<StyledCellText key={subjectLesson.id}>{subjectLesson.name}</StyledCellText>
							))
						) : (
							<StyledCellText>{"N/A"}</StyledCellText>
						)}
					</StyledCellWrapper>
				),
				ChildCell: ({ parentRowData: { subjectLessons } }) => (
					<StyledCellWrapper>
						{subjectLessons.length > 0 ? (
							subjectLessons.map(subjectLesson => (
								<StyledCellText key={subjectLesson.id}>{subjectLesson.name}</StyledCellText>
							))
						) : (
							<StyledCellText>{"N/A"}</StyledCellText>
						)}
					</StyledCellWrapper>
				),
				dataKey: "lesson"
			},
			{
				alignment: "left",
				label: <ColumnHeader>Question Type</ColumnHeader>,
				Cell: ({
					rowData: {
						type: { name }
					}
				}) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				ChildCell: ({
					rowData: {
						type: { name }
					}
				}) => (
					<StyledCellWrapper>
						<StyledCellText>{name}</StyledCellText>
					</StyledCellWrapper>
				),
				filter: {
					type: FilterTypes.MultipleCheckbox,
					data: {
						allFilters: {
							label: "All Question Types",
							checked: false
						},
						checkboxFilters: checkboxFiltersValue
					},
					width: "550px",
					onChange: value => {
						const typeId = value.allFilters.checked
							? value.checkboxFilters.map(({ id }) => "" + id)
							: value.checkboxFilters.filter(item => item.checked).map(({ id }) => "" + id);

						setQuestionTypeFilter(typeId);
						dispatch(
							fetchQuestions({
								searchText,
								courseId: +currentCourseId,
								page: 1,
								locationId: user?.managedLocations.locationId,
								typeId
							})
						);
					}
				},
				dataKey: "question_type"
			},
			{
				alignment: "right",
				label: "",
				width: 100,
				Cell: ({ rowData: { id, ...rest } }) => (
					<ActionMenu
						customMenuItems={[
							{
								label: "Delete",
								onClick: () => onDeleteClick(id, rest.clonedBy),
								visible: true,
								disabled: false
							},
							{
								label: "Edit",
								onClick: () => window.open(`${routes.questionbank.getPath()}/${id}`, "_blank")?.focus(),
								visible: true,
								disabled: false
							},
							{
								label: "Add to community pool",
								onClick: () => setAddToCommunityPoolModal(id),
								visible: user?.managedLocations?.id === rest.ownerId,
								disabled: false
							}
						]}
					/>
				),
				dataKey: "menu"
			}
		],
		[user?.managedLocations, currentCourseId, checkboxFiltersValue, debouncedSearchText]
	);

	const questionRequest = useCallback(() => {
		if (user?.managedLocations) {
			dispatch(
				fetchQuestions({
					searchText,
					courseId: +currentCourseId,
					page: 1,
					locationId: user?.managedLocations.locationId,
					typeId: questionTypeFilter
				})
			);
		}
		dispatch(fetchQuestionsCount());
	}, [currentCourseId, searchText, user?.managedLocations, questionTypeFilter, dispatch]);

	useEffect(() => {
		questionRequest();
		return () => {
			dispatch(resetCourses());
		};
	}, [user?.managedLocations]);

	const handleSearchBarChange = useCallback(
		searchText => {
			setDebouncedSearchText(searchText);
			if (user?.managedLocations) {
				return dispatch(
					fetchQuestions({
						searchText,
						courseId: +currentCourseId,
						page: 1,
						locationId: user?.managedLocations.locationId,
						typeId: questionTypeFilter
					})
				);
			}
		},
		[dispatch, currentCourseId, user?.managedLocations, questionTypeFilter]
	);

	const refetchQuestions = useCallback(() => {
		if (user?.managedLocations)
			dispatch(
				fetchQuestions({
					searchText,
					courseId: +currentCourseId,
					page,
					locationId: user?.managedLocations.locationId,
					typeId: questionTypeFilter
				})
			);
	}, [dispatch, user?.managedLocations, questionTypeFilter, searchText, page, currentCourseId]);

	const onDeleteClick = (id, clonedBy) => {
		setDeleteQuestion({ id, clonedBy });
		setDeleteModal(true);
	};

	const questionInfos = useMemo(
		() =>
			courses.map(({ questionCount, name }) => {
				return {
					title: name,
					text: questionCount
				};
			}),
		[courses.length]
	);

	const handleSelect = useCallback(
		id => {
			if (user?.managedLocations) {
				setCurrentCourseId(id);
				dispatch(
					fetchQuestions({
						searchText,
						courseId: id,
						page: 1,
						locationId: user?.managedLocations.locationId,
						typeId: questionTypeFilter
					})
				);
			}
		},
		[dispatch, user?.managedLocations, questionTypeFilter, searchText]
	);
	const addQuestionToCommunityHandler = subjectId => {
		subjectId &&
			dispatch(
				addQuestionToCommunity({
					id: addToCommunityPoolModal,
					subjectId,
					cb: () => {
						setAddToCommunityPoolModal(0);
						refetchQuestions();
					}
				})
			);
	};

	const deleteQuestionHandler = useCallback(() => {
		if (deleteQuestion?.id && deleteClones !== undefined) {
			dispatch(
				questionDelete({
					id: deleteQuestion.id,
					deleteClones,
					cb: () => {
						questionRequest();
						setDeleteModal(false);
						setDeleteClones(false);
					}
				})
			);
		}
	}, [deleteClones, deleteQuestion?.id]);

	const memoizedTable = useMemo(
		() => (
			<MaterialTable
				columns={tableColumns as IColumn<Question>[]}
				data={questions || []}
				height={800}
				hasNestedRows={true}
			/>
		),
		[tableColumns, questions]
	);

	if (!user || !courses.length) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" height={150} width="100%">
				<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
			</Box>
		);
	}

	const filterOptions: Array<{ label: string; value: filterType }> = [
		{ label: "All", value: "all" },
		{ label: "CAT Questions", value: "cat" },
		{ label: "Trial Questions", value: "trial" }
	];

	return (
		<Container>
			<HeaderContainer>
				<Header>Question Bank</Header>
				<Box display={"flex"} flexDirection={"row"} ml={"auto"}>
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={() => history.push(`${routes.manageSubjects.getPath()}`)}
					>
						Manage Subjects
					</Button>
				</Box>
				<Box ml={2} mr={7}>
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={() => history.push(`${routes.questionbank.getPath()}/add-new-question`)}
					>
						Add New Question
					</Button>
				</Box>
			</HeaderContainer>
			<Box display={"flex"} flexWrap={"wrap"}>
				<>
					<QuestionInfo
						customBgColor={"#141F30"}
						title={"Total Questions"}
						text={totalQuestionsCount}
						IconSvg={TotalIconSvg}
					/>
					{questionInfos.map(({ title, text }, i) => (
						<QuestionInfo key={i} title={title} text={text} IconSvg={false} />
					))}
				</>
			</Box>
			<Box mr={4} mt={2} mb={2}>
				<THeaderWrapper>
					<Box display={"flex"} flex={1}>
						<THeaderTitle>Questions</THeaderTitle>
					</Box>
					<Box mr={2} display="flex" gridGap={16} alignItems="center">
						<DropFilter
							options={filterOptions}
							onChange={v => {
								dispatch(
									fetchQuestions({
										searchText,
										courseId: +currentCourseId,
										page: 1,
										locationId: user?.managedLocations.locationId,
										typeId: questionTypeFilter,
										filter: v
									})
								);
							}}
							value={filter}
							mr={3}
						/>
						<StyledListIcon />
					</Box>
					<Box mr={2}>
						<FormControl hiddenLabel size="small" variant="filled">
							<Select
								disableUnderline
								value={currentCourseId}
								onChange={event => handleSelect(event.target.value)}
								displayEmpty
								MenuProps={{
									anchorOrigin: {
										vertical: "bottom",
										horizontal: "left"
									},
									transformOrigin: {
										vertical: "top",
										horizontal: "left"
									},
									getContentAnchorEl: null
								}}
							>
								<MenuItem value="">All courses</MenuItem>
								{courses.map(({ id, name }) => (
									<MenuItem key={id} value={id}>
										{name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Box>
					<SearchBarComponent
						inputValue={searchText}
						setInputValue={setSearchText}
						onChange={handleSearchBarChange}
						isDebounced={true}
						debounceTime={1000}
						placeHolder={"Type to filter by keywords"}
					/>
				</THeaderWrapper>
				{isLoading ? (
					<Box display="flex" alignItems="center" justifyContent="center" height={500} width="100%">
						<CircularProgress size="7rem" color="primary" thickness={5} variant="indeterminate" />
					</Box>
				) : (
					<>
						{memoizedTable}
						{perPage < totalItems && (
							<TablePagination
								count={totalItems}
								page={page}
								onChange={(event, page) => {
									if (user?.managedLocations)
										dispatch(
											fetchQuestions({
												page,
												searchText,
												locationId: user?.managedLocations.locationId,
												...(currentCourseId !== "" ? { courseId: +currentCourseId } : undefined),
												typeId: questionTypeFilter
											})
										);
								}}
								rowsPerPage={perPage}
							/>
						)}
					</>
				)}

				<DeleteModal
					open={deleteModal}
					title="Delete Question"
					message={`Are you sure you want to delete this question${
						!!deleteQuestion?.clonedBy?.length ? " and it’s copy in community pool" : ""
					}?`}
					cancelBtnText="No, Cancel"
					deleteBtnText="Yes, Delete"
					deleteLoading={isDeleting}
					onClose={() => setDeleteModal(false)}
					onDelete={deleteQuestionHandler}
				>
					{!!deleteQuestion?.clonedBy?.length && (
						<Box>
							<FormControlLabel
								control={
									<Checkbox
										value="refund"
										checked={deleteClones}
										onChange={() => setDeleteClones(c => !c)}
										color="primary"
									/>
								}
								label="Delete copy in community pool"
							/>
						</Box>
					)}
				</DeleteModal>
				<CloneQuestionModal
					title="Add question to community pool"
					disableEnforceFocus
					disableAutoFocus
					subjects={subjects}
					isOpen={!!addToCommunityPoolModal}
					onClose={() => setAddToCommunityPoolModal(0)}
					onClone={addQuestionToCommunityHandler}
					isCloning={isCloning}
					isCloningButtonDisabled={isCloning}
				/>
			</Box>
			{isQuestionPreview && (
				<PreviewQuestion
					question={questionToPreview as Question}
					onClose={() => setQuestionPreview(false)}
					onAddToMyPool={
						user?.managedLocations?.id === questionToPreview?.ownerId
							? () => setAddToCommunityPoolModal(questionToPreview?.id as number)
							: undefined
					}
				/>
			)}
		</Container>
	);
};

export default QuestionBank;
