import * as Yup from "yup";

export const couponsSchema = Yup.object().shape({
	name: Yup.string().required(),
	code: Yup.string().required(),
	amount: Yup.number().min(1).required(),
	type: Yup.string().required(),
	isPercentage: Yup.boolean().required(),
	quantity: Yup.number().required(),
	quantity_type: Yup.string().required(),
	isAffiliateCoupon: Yup.boolean(),
	products_shipping: Yup.string().required(),
	courses: Yup.array().min(1).required(),
	validFrom: Yup.date().required(),
	startTime: Yup.date().required(),
	expiresOn: Yup.date().required(),
	endTime: Yup.date().required()
});
