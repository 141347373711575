import React from "react";

import InstitutePlus from "@remar/shared/dist/assets/icons/institutePlus.svg";
import InstituteAdd from "@remar/shared/dist/assets/icons/institutionAdd.svg";
// import MoneyBox from "assets/images/money-box.svg";
import StudentsImg from "@remar/shared/dist/assets/icons/students.svg";
import VideoImg from "@remar/shared/dist/assets/icons/video.svg";

import DashboardBg from "assets/images/dashboardHeader.png";

import { routes } from "core/constants";

import {
	BackgroundImg,
	Container,
	Gradient,
	Header,
	Info,
	InfoContainer,
	InfoHead,
	// MetricsContainer,
	QuickLink,
	QuickLinksContainer,
	SectionContainer
} from "./styles";

const Home = () => {
	const quickLinksData = [
		{
			title: "Add New Question",
			img: InstitutePlus,
			link: `${routes.questionbank.getPath()}/add-new-question`
		},
		{
			title: "Add New Lesson",
			img: VideoImg,
			link: routes.createLesson.getPath()
		},
		// {
		// 	title: "Manage Plans",
		// 	img: MoneyBox,
		// 	link: "/"
		// },
		{
			title: "Manage Students",
			img: StudentsImg,
			link: routes.manageStudents.getPath()
		},
		{
			title: "Manage Locations",
			img: InstituteAdd,
			link: routes.manageLocations.getPath()
		},
		{
			title: "Community Pool",
			img: InstituteAdd,
			link: routes.communityPool.getPath()
		}
	];

	return (
		<Container>
			<Header>Dashboard </Header>
			<Gradient />
			<BackgroundImg src={DashboardBg} />
			<InfoContainer>
				<InfoHead>Hi there!</InfoHead>
				<Info>What would you like to do today?</Info>
			</InfoContainer>
			<SectionContainer>
				<QuickLinksContainer>
					{quickLinksData.map(el => (
						<QuickLink to={el.link} key={el.title}>
							<img alt={`${el.title} preview icon`} className="quicklinks-img" src={el.img} />
							<div className="title">{el.title}</div>
						</QuickLink>
					))}
				</QuickLinksContainer>
				{/* <MetricsContainer>Metrics</MetricsContainer> */}
			</SectionContainer>
		</Container>
	);
};

export default Home;
