import React, { useState } from "react";

import { Box, Modal, Theme, createStyles, makeStyles, useTheme } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as IconEquals } from "@remar/shared/dist/assets/icons/icon-equals.svg";
import { ReactComponent as IconOvalCta } from "@remar/shared/dist/assets/icons/icon-oval-cta.svg";
import { ActionMenu } from "@remar/shared/dist/components/ActionMenu";
import { LessonTypes } from "@remar/shared/dist/constants";
import { useStyles as useSharedStyles } from "@remar/shared/dist/styles";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import IconRoundButton from "@remar/shared/dist/ui/Buttons/IconRoundButton";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
	fetchChapter,
	getFullState,
	reorderLesson,
	sectionLessonDelete,
	setStateValue
} from "store/features/Course/course.slice";
import { updatePublishedState } from "store/features/CreateLesson/createLesson.slice";

import { routes } from "core/constants";
import EmptyViewCard from "modules/Components/EmptyViewCard";

import { DeleteModalContent } from "./DeleteModalContent";

import { ListItems } from "./styles";

import { computeReorder } from "../utils";

// TODO: temp solution, need to get type name from BE
const typesMap = {
	1: "Video Only",
	2: "Interactive",
	3: "Test Only"
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		lessonName: {
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			overflow: "hidden",
			marginLeft: "16px",
			width: "220px"
		},
		th: {
			background: "#393e4b",
			display: "flex",
			alignItems: "center",
			width: "100%",
			padding: theme.spacing(1, 3, 1, 7),
			borderRadius: theme.shape.borderRadius,
			"& > p": {
				fontSize: theme.typography.pxToRem(12),
				color: "rgba(255, 255, 255, 0.6)"
			}
		}
	})
);

const LessonReordering = ({ id }) => {
	const dispatch = useDispatch();
	const theme: IExtendedTheme = useTheme();
	const styles = useSharedStyles(theme);
	const classes = useStyles();
	const history = useHistory();
	const { activeChapter } = useSelector(getFullState);
	const [deleteModal, setDeleteModal] = useState(false);
	const [lessonInfo, setLessonInfo] = useState<{ lessonId: number; lessonType: string }>({
		lessonId: 0,
		lessonType: typesMap[1]
	});
	const { id: chapterId, sections } = activeChapter!;

	const onDragEnd = async result => {
		if (!result.destination) {
			return;
		}
		const arg = {
			startSrc: result.source,
			endSrc: result.destination,
			sections: sections || []
		};
		const { newOrder, selectedLesson } = computeReorder(arg);
		if (selectedLesson) {
			await dispatch(
				reorderLesson({
					filters: {
						id: selectedLesson.id
					},
					data: {
						order: arg.endSrc.index + 1
					}
				})
			);
		}
		dispatch(setStateValue({ key: "activeChapter.sections", value: newOrder }));
	};

	const sectionLessons = sections?.find(i => i.id === id)?.sectionLessons || [];
	const sortedLessons = sectionLessons.concat().sort((a, b) => a.order - b.order);

	const onDeleteClick = (id, lessonTypeId) => {
		setLessonInfo({
			lessonId: id,
			lessonType: typesMap[lessonTypeId]
		});
		setDeleteModal(true);
	};

	const onEditClick = (lessonId, selectedSectionId) => {
		history.push(`${routes.createLesson.getPath()}/${lessonId}?selectedSectionId=${selectedSectionId}`);
	};

	const onUnpublishClick = lessonId => {
		(
			dispatch(
				updatePublishedState({ lessonIdOverride: lessonId, newPublishedState: false })
			) as unknown as Promise<void>
		).then(() => dispatch(fetchChapter(chapterId)));
	};

	const onPreviewClick = (lessonId, selectedSectionId) => {
		history.push(
			`${routes.createLesson.getPath()}/${lessonId}?selectedSectionId=${selectedSectionId}&previewOpen=true`
		);
	};

	const onViewCommentsClick = lessonId => {
		history.push(`${routes.lesson.getPath()}/${lessonId}`);
	};

	return (
		<Box bgcolor={theme.palette.colors.basic[800]} ml="auto" width={580}>
			<Box className={classes.th}>
				<Box width={170} component="p">
					Lesson name
				</Box>
				<Box component="p" textAlign="center" width={100} pl={2}>
					Lesson type
				</Box>
				<Box component="p" textAlign="center" width={100} pl={2}>
					State
				</Box>
				<Box component="p" textAlign="center" width={100} pl={3}>
					Action
				</Box>
			</Box>
			{sortedLessons.length > 0 ? (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={`${id}`}>
						{provided => (
							<Box px={3} py={1} {...provided.droppableProps} ref={provided.innerRef}>
								{sortedLessons.map((item, index) => {
									const draftData = item.lesson!.drafts!.length ? item.lesson!.drafts![0].data : null;
									return (
										<Draggable key={item.id} draggableId={`${item.id}`} index={index}>
											{provided => (
												<ListItems ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
													<SvgIcon fontSize="small">
														<IconEquals />
													</SvgIcon>{" "}
													<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
														<Box
															component="p"
															my={0}
															color={item.lesson!.isActive ? "white" : "#999"}
															className={classes.lessonName}
														>
															{index + 1}. {item.lesson!.isActive || !draftData ? item.lesson!.name : draftData.name}
														</Box>
														<Box
															component="p"
															my={0}
															ml={2}
															textAlign="center"
															color="rgba(255, 255, 255, 0.6)"
															width={100}
														>
															{typesMap[item.lesson!.typeId] || item.lesson!.typeId}
														</Box>
														<Box
															component="p"
															my={0}
															textAlign="center"
															color={item.lesson!.isActive ? "#23b34a" : "#999"}
															width={100}
														>
															{item.lesson!.isActive ? "Active" : "Draft"}
														</Box>
													</Box>
													<IconRoundButton
														color={"primary"}
														variant={"contained"}
														Icon={<IconOvalCta width={15} height={15} />}
														onClick={() => onEditClick(item.lesson!.id, item.sectionId)}
													/>
													{draftData ? (
														<ActionMenu
															customMenuItems={[
																{
																	label: "Edit",
																	onClick: () => onEditClick(item.lesson!.id, item.sectionId),
																	visible: true,
																	disabled: false
																},
																{
																	label: "Delete",
																	onClick: () => onDeleteClick(item.id, item.lesson!.typeId),
																	visible: true,
																	disabled: false
																},
																{
																	label: "Unpublish",
																	onClick: () => onUnpublishClick(item.lesson!.id),
																	visible: item.lesson!.isActive,
																	disabled: false
																},
																{
																	label: "Preview",
																	onClick: () => onPreviewClick(item.lesson!.id, item.sectionId),
																	visible: Boolean(
																		(item.lesson!.typeId === LessonTypes.Interactive &&
																			draftData.interactiveBlocks &&
																			draftData.interactiveBlocks.length >= 1) ||
																			(item.lesson!.typeId === LessonTypes.TestOnly &&
																				draftData.questions &&
																				draftData.questions.length)
																	),
																	disabled: false
																},
																{
																	label: "View comments",
																	onClick: () => onViewCommentsClick(item.lesson!.id),
																	visible: true,
																	disabled: false
																}
															]}
														/>
													) : (
														<ActionMenu
															customMenuItems={[
																{
																	label: "Edit",
																	onClick: () => onEditClick(item.lesson!.id, item.sectionId),
																	visible: true,
																	disabled: false
																},
																{
																	label: "Delete",
																	onClick: () => onDeleteClick(item.id, item.lesson!.typeId),
																	visible: true,
																	disabled: false
																},
																{
																	label: "Unpublish",
																	onClick: () => onUnpublishClick(item.lesson!.id),
																	visible: item.lesson!.isActive,
																	disabled: false
																},
																{
																	label: "View comments",
																	onClick: () => onViewCommentsClick(item.lesson!.id),
																	visible: item.lesson!.isActive,
																	disabled: false
																}
															]}
														/>
													)}
												</ListItems>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</Box>
						)}
					</Droppable>
				</DragDropContext>
			) : (
				<EmptyViewCard title="This Section does not have a lesson yet" />
			)}
			{/* TODO: replace with useConfirm */}
			<Modal
				className={styles.modal}
				disableEnforceFocus
				disableAutoFocus
				open={deleteModal}
				onClose={() => setDeleteModal(false)}
			>
				<DeleteModalContent
					onDelete={(deleteVideo: boolean) => {
						dispatch(sectionLessonDelete({ lessonId: lessonInfo.lessonId, deleteVideo }, chapterId));
						setDeleteModal(false);
					}}
					lessonType={lessonInfo.lessonType}
					onClose={() => setDeleteModal(false)}
				/>
			</Modal>
		</Box>
	);
};

export default LessonReordering;
