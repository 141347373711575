import { Course } from "@remar/shared/dist/models";

import { FormStateModel } from "@remar/shared/dist/utils/form/form.model";

import {
	CourseFeaturesFormInputs,
	CourseFormInputs,
	UserSubscriptionTypesFormInputs,
	UserSubscriptionTypesFormTemplates
} from "./form";
import { CourseFeaturesFormRawData, CourseFormRawData, UserSubscriptionTypesFormRawData } from "./rawData";

export interface CourseAddEditState {
	canSaveForm: boolean;
	courseForm: FormStateModel<CourseFormInputs, CourseFormRawData, {}>;
	courseFeaturesForm: FormStateModel<CourseFeaturesFormInputs, CourseFeaturesFormRawData, {}>;
	data: CourseData;
	isLoading: boolean;
	newCourseId?: number;
	publishIsLoading: boolean;
	saveIsLoading: boolean;
	externalIntegrationData: {
		currency: string;
		planName: string;
		bookName: string;
	};
	userSubscriptionTypesForm: FormStateModel<
		UserSubscriptionTypesFormInputs,
		UserSubscriptionTypesFormRawData,
		UserSubscriptionTypesFormTemplates
	>;
}

export interface CourseData extends Course {
	externalIntegrationData: {
		additionalInitial: CourseDataExternalIntegrationDataItem[];
		initial: CourseDataExternalIntegrationDataItem;
		recurring: CourseDataExternalIntegrationDataItem;
		recurringQuestionBankOnly: CourseDataExternalIntegrationDataItem;
		trial: CourseDataExternalIntegrationDataItem;
	};
	data: {
		additionalInformation: string;
		marketingPoints: string[];
	};
}

export interface CourseDataExternalIntegrationDataItem {
	id?: number;
	stripePlanId?: string;
	shipStationProductSKU?: string;
	appleProductSKU?: string;
	digitalAssetProductKey?: string;
	isDigital?: boolean;
	isDefault?: boolean;
	isQuestionBankOnly?: boolean;
	addons?: {
		stripePlanId: string;
		shipStationProductSKU?: string;
		digitalAssetProductKey?: string;
		isDefault?: boolean;
		isDigital: boolean;
		mainImageKey?: string;
		mainImageUrl?: string;
	}[];
}

export const DefaultCourseItem = {
	id: 0,
	data: { marketingPoints: [], additionalInformation: "" },
	name: "",
	description: "",
	endOfCourseSummary: "",
	totalTimeLengthInMinutes: 0,
	manualTimeLengthComponent: 0,
	totalChapters: 0,
	totalSections: 0,
	totalLessons: 0,
	isActive: true,
	isDefault: false,
	hasTrial: false,
	calculateTimeLengthAutomatically: true,
	freeShipping: false
};
