import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Card, Grid, Typography } from "@material-ui/core";

import SharedButton from "@remar/shared/dist/components/Button";
import CourseCompletionSummary from "@remar/shared/dist/components/CourseCompletion/CourseCompletionSummary";
import { useCourseCompletionDetails } from "@remar/shared/dist/components/CourseCompletion/useCourseCompletionDetails";
import { IColumn, MaterialTable } from "@remar/shared/dist/components/MaterialTable";
import StatusComponent from "@remar/shared/dist/components/StatusComponent";
import StudentAccountInfoCard from "@remar/shared/dist/components/StudentAccountInfoCard";
import StudentSubscriptionCard from "@remar/shared/dist/components/StudentSubscriptionCard";
import {
	ColumnHeader,
	StyledCellWrapper,
	THeaderTitle,
	THeaderWrapper
} from "@remar/shared/dist/components/Table/styles";
import { TablePagination } from "@remar/shared/dist/components/TablePagination";
import { Wrapper } from "@remar/shared/dist/layouts";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { SimpleModal } from "@remar/shared/dist/modals/SimpleModal";
import {
	BaseModel,
	IUserCourseCompletionDetailForAdmin,
	UserSubscription,
	UserSubscriptionType
} from "@remar/shared/dist/models";
import { useStyles } from "@remar/shared/dist/styles";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { formatDate } from "@remar/shared/dist/utils/myAccountUtils";

import { pick } from "lodash";

import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "store";
import { fetchCountries } from "store/features/Auth/auth.slice";
import { fetchAllCourses, getFullState as getFullCourseState } from "store/features/Course/course.slice";

import {
	changeSubStartDate,
	getStudentAccount,
	getStudentCourseCompletion,
	getStudentPaymentHistory,
	pauseSubscription,
	resumeSubscription
} from "store/features/Students/students.slice";

import { routes } from "core/constants";

import ChangeSubscriptionModal, { IChangeSubscription } from "modules/Components/ChangeSubscriptionModal";

import theme from "theme/default";

import AddDaysModal from "./AddDaysModal";
import ChangeSubStartDate from "./ChangeSubStartDate";
import PauseSubModal from "./PauseSubModal";

interface IAddDays {
	fullName: string;
	userId: number;
	userEmail: string;
	subscription: UserSubscription;
}
interface IPaymentHistory extends BaseModel {
	billingDate: string;
	amount: number;
}

const StudentAccount = () => {
	const { id } = useParams<{ id: string }>();
	const [showChangeSubscriptionModal, setShowChangeSubscriptionModal] = useState<IChangeSubscription | null>(null);
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		studentAccountInfo,
		studentSubscription,
		studentAccountLoading,
		paymentHistory,
		paymentHistoryLoading,
		changeSubStartDateLoading,
		changeSubStartTodayLoading,
		courseCompletion,
		pauseResumeSubLoading
	} = useSelector((state: RootState) => state.students);
	const [showAddDaysModal, setShowAddDaysModal] = useState<IAddDays | null>(null);
	const [showPauseSubModal, setShowPauseSubModal] = useState<boolean>(false);
	const [showResumeSubModal, setShowResumeSubModal] = useState<boolean>(false);
	const [showChangeSubModal, setShowChangeSubModal] = useState(false);
	const { courses } = useSelector(getFullCourseState);
	const { countries } = useSelector((state: RootState) => state.auth);
	const classes = useStyles();
	const { nextLesson } = useCourseCompletionDetails(courseCompletion as IUserCourseCompletionDetailForAdmin);

	useEffect(() => {
		if (!courses?.length) {
			dispatch(fetchAllCourses(true));
		}
	}, [dispatch, courses]);

	useEffect(() => {
		if (!countries?.length) {
			dispatch(fetchCountries(0));
		}
	}, [countries, dispatch]);

	useEffect(() => {
		if (id) {
			dispatch(getStudentAccount({ id }));
			dispatch(getStudentPaymentHistory({ id }));
		}
	}, [id, dispatch]);

	const courseId = useMemo(() => studentSubscription?.subscription.type?.allowedCourses[0].id, [studentSubscription]);
	useEffect(() => {
		if (id && studentSubscription?.subscription) {
			dispatch(
				getStudentCourseCompletion({
					userId: id,
					courseId: studentSubscription.subscription.type?.allowedCourses[0].id as number
				})
			);
		}
	}, [dispatch, id, courseId]);

	const {
		items: paymentList,
		perPage,
		totalItems,
		page
	} = paymentHistory || ({} as { items: []; perPage: number; totalItems: number; page: number });

	const { firstName, lastName, email } = useMemo(() => {
		if (!studentAccountInfo) {
			return {};
		}

		return pick(studentAccountInfo, ["firstName", "lastName", "email"]);
	}, [studentAccountInfo]);

	const breadcrumb = useMemo(
		() => [
			{ title: "Dashboard", key: 0, link: "/" },
			{ title: "Manage Students", key: 1, link: routes.manageStudents.getPath() },
			{ title: `${firstName} ${lastName}`, key: 2, link: `${routes.manageStudents.getPath()}/${id}` }
		],
		[firstName, lastName, id]
	);

	const address = useMemo(() => {
		if (!studentAccountInfo) {
			return "";
		}
		const { userShippingDetails } = studentAccountInfo;
		return userShippingDetails ? userShippingDetails : "";
	}, [countries, studentAccountInfo]);

	const { expiresOn, subscriptionName, price, billingDate, isTrial, isActive, startDay, isPaused, hasStarted } =
		useMemo(() => {
			if (!studentSubscription) {
				return {} as {
					expiresOn: Date;
					subscriptionName: string;
					price: string;
					billingDate: Date;
					isTrial: boolean;
					startDay: Date;
				};
			}
			let subscriptionName = "";
			const {
				subscription: { expiresOn, type, data, isPaused, hasStarted },
				upcomingInvoice: { price, billingDate }
			} = studentSubscription;
			if (type?.allowedCourses?.length) {
				subscriptionName = type.allowedCourses[0].name;
			}
			return {
				expiresOn,
				subscriptionName,
				isTrial: type?.isTrial,
				isActive: type?.isActive,
				price,
				billingDate,
				startDay: data?.subscriptionStartDate ? new Date(data?.subscriptionStartDate) : null,
				isPaused,
				hasStarted
			};
		}, [studentSubscription]);

	const handleSelectPagination = (_, page) => dispatch(getStudentPaymentHistory({ id, page }));

	const handleSubChange = studentAccountInfo => {
		const { firstName, lastName, id } = studentAccountInfo;
		const fullName = [firstName, lastName].join(" ");
		const subscription = studentSubscription?.subscription;
		const { id: subscriptionId, hasExpired, isCancelled, type } = subscription as UserSubscriptionType;
		const { isTrial, allowedCourses } = type ?? {};
		const notAllowedCourses = allowedCourses
			? courses?.filter(course => course.id !== allowedCourses![0].id) || []
			: courses;

		setShowChangeSubscriptionModal({
			fullName,
			userId: id,
			subscriptionId,
			hasExpired,
			isCancelled,
			isTrial,
			allowedCourses,
			notAllowedCourses,
			subscription
		});
	};

	const tableColumns = useMemo(
		() => [
			{
				alignment: "start",
				label: <ColumnHeader>Purchase</ColumnHeader>,
				Cell: ({ rowData: { name } }) => <StyledCellWrapper>{name}</StyledCellWrapper>,
				dataKey: "name"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Date</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { billingDate } }) => <StyledCellWrapper center>{formatDate(billingDate)}</StyledCellWrapper>,
				dataKey: "date"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Amount</ColumnHeader>,
				width: 200,
				Cell: ({ rowData: { amount } }) => <StyledCellWrapper center>{formatUSD(amount)}</StyledCellWrapper>,
				dataKey: "amount"
			},
			{
				alignment: "center",
				label: <ColumnHeader>Status</ColumnHeader>,
				width: 200,
				Cell: ({
					rowData: {
						paymentData: { subscriptionPaymentIntentStatus }
					}
				}) => {
					const text =
						!subscriptionPaymentIntentStatus || subscriptionPaymentIntentStatus === "succeeded"
							? "Successful"
							: "Failed";
					const color = text === "Successful" ? "success" : "danger";
					return (
						<StyledCellWrapper center>
							<StatusComponent color={color} text={text} />
						</StyledCellWrapper>
					);
				},
				dataKey: "status"
			}
		],
		[]
	);
	const pauseSub = () => {
		setShowPauseSubModal(true);
	};
	const handleResumeSub = () => {
		setShowResumeSubModal(true);
	};

	const handleSubStartDateChange = date => {
		const subscription = studentSubscription?.subscription as UserSubscriptionType;
		const { id: subscriptionId } = subscription;
		dispatch(
			changeSubStartDate({
				userId: id,
				subscriptionId,
				startDate: date,
				cb: () => {
					dispatch(getStudentAccount({ id }));
					setShowChangeSubModal(false);
				}
			})
		);
	};

	const handlePauseSub = (days: number) => {
		dispatch(
			pauseSubscription({
				userId: Number(id),
				noOfDays: Number(days),
				cb: () => {
					setShowPauseSubModal(false);
					dispatch(getStudentAccount({ id }));
				}
			})
		);
	};

	if (studentAccountLoading) {
		return <ContentLoader />;
	}

	return (
		<Wrapper
			heading={`${firstName} ${lastName}`}
			breadcrumb={breadcrumb}
			actions={
				<Box display="flex" gridGap={16}>
					<Button className={classes.cancelBtn} onClick={() => history.push(routes.manageStudents.getPath())}>
						Back
					</Button>
					<Button
						color={"primary"}
						variant={"contained"}
						onClick={() =>
							history.push({
								pathname: `${routes.manageStudents.getPath()}/${id}/test-results`,
								state: {
									studentInfo: studentAccountInfo
								}
							})
						}
					>
						Test Results
					</Button>
					<Button
						color={"primary"}
						variant={"contained"}
						onClick={() =>
							history.push({
								pathname: `${routes.manageStudents.getPath()}/${id}/reports`,
								state: {
									studentInfo: studentAccountInfo
								}
							})
						}
					>
						Reports
					</Button>
				</Box>
			}
		>
			<Grid container spacing={3}>
				<Grid item xs={8}>
					<StudentAccountInfoCard
						profilePictureUrl={studentAccountInfo?.profileImageUrl}
						firstName={firstName}
						lastName={lastName}
						email={studentAccountInfo?.email}
						schoolName={studentAccountInfo?.school?.name}
						phoneNumber={studentAccountInfo?.phoneNumber}
						address={address}
						badgeType={studentAccountInfo?.badges[0]?.typeId}
						countries={countries}
					/>
					{paymentList && (
						<Box mt={2}>
							<Card>
								<THeaderWrapper>
									<THeaderTitle>Payments History</THeaderTitle>
								</THeaderWrapper>
								{paymentHistoryLoading ? (
									<ContentLoader />
								) : (
									<>
										<MaterialTable
											columns={tableColumns as IColumn<IPaymentHistory>[]}
											data={paymentList}
											height={600}
										/>
									</>
								)}

								{perPage < totalItems && (
									<TablePagination
										count={totalItems}
										page={page}
										onChange={handleSelectPagination}
										rowsPerPage={perPage}
									/>
								)}
							</Card>
						</Box>
					)}
					{courseCompletion && (
						<Box mt={1}>
							<CourseCompletionSummary
								courseCompletion={courseCompletion}
								courseName={studentSubscription?.subscription?.subscriptionName as string}
								nextLesson={nextLesson}
								seeDetails={() => {
									history.push(`${routes.manageStudents.getPath()}/${id}/courseCompletion`);
								}}
							/>
						</Box>
					)}
				</Grid>
				<Grid item xs={4}>
					<StudentSubscriptionCard
						location={"ADMIN"}
						subscriptionName={subscriptionName}
						expiresOn={expiresOn!}
						billingDate={billingDate}
						price={!isTrial ? price : ""}
						subscriptionStartDate={startDay!}
						coursesProgress={studentAccountInfo?.coursesProgress || 0}
						onChangeSub={() => handleSubChange(studentAccountInfo)}
						handleAddDays={() =>
							setShowAddDaysModal({
								fullName: `${firstName} ${lastName}`,
								userId: +id,
								userEmail: email!,
								subscription: studentSubscription?.subscription
							})
						}
						handlePauseSub={pauseSub}
						handleResumeSub={handleResumeSub}
						isPaused={isPaused}
						hasStarted={hasStarted}
						viewAddDays={isActive && !isTrial}
						changeSubStartDate={() => setShowChangeSubModal(true)}
					/>
				</Grid>
			</Grid>

			{!!showChangeSubscriptionModal && (
				<ChangeSubscriptionModal
					setShowChangeSubscriptionModal={setShowChangeSubscriptionModal}
					showChangeSubscriptionModal={showChangeSubscriptionModal}
					fetchAccount={true}
				/>
			)}
			{!!showAddDaysModal && (
				<AddDaysModal
					setShowAddDaysModal={setShowAddDaysModal}
					showAddDaysModal={showAddDaysModal}
					fetchAccount={true}
				/>
			)}
			{showChangeSubModal && (
				<ChangeSubStartDate
					onClose={() => setShowChangeSubModal(false)}
					changeStartSubDate={handleSubStartDateChange}
					changeSubStartDateLoading={changeSubStartDateLoading}
					changeSubStartTodayLoading={changeSubStartTodayLoading}
					startDay={startDay}
				/>
			)}
			{showPauseSubModal && (
				<PauseSubModal
					setShowPauseSubModal={setShowPauseSubModal}
					handlePause={handlePauseSub}
					pauseSubLoading={pauseResumeSubLoading}
				/>
			)}
			<SimpleModal
				open={showResumeSubModal}
				theme={theme}
				title={"Resume Subscription"}
				onClose={() => {
					setShowResumeSubModal(false);
				}}
				footer={
					<Box>
						<SharedButton variant={"outlined"} color={"basic"} onClick={() => setShowResumeSubModal(false)}>
							Cancel
						</SharedButton>
						<SharedButton
							loading={pauseResumeSubLoading}
							variant={"filled"}
							color={"primary"}
							style={{ marginLeft: "10px" }}
							disabled={pauseResumeSubLoading}
							onClick={() => {
								dispatch(
									resumeSubscription({
										userId: id,
										cb: () => {
											dispatch(getStudentAccount({ id }));
											setShowResumeSubModal(false);
										}
									})
								);
							}}
						>
							Resume
						</SharedButton>
					</Box>
				}
				extraContent={
					<Box my={2}>
						<Typography>{`Are you sure you want to resume ${firstName}'s subscription?`}</Typography>
					</Box>
				}
			/>
		</Wrapper>
	);
};

export default StudentAccount;
