import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	Country,
	ILocation,
	IStudentAccountInfo,
	IStudentPaymentHistory,
	IUserCourseCompletionDetailForAdmin,
	UserGetSubscriptionInfoResponseDto
} from "@remar/shared/dist/models";
import { ICertificate } from "@remar/shared/dist/models/certificate.model";
import { IStudentReport } from "@remar/shared/dist/models/studentReport";
import { IUserCustomTestResponse } from "@remar/shared/dist/services/userCustomTests/dto";
import { getResetState, setStateValue as utilsSetStateValue } from "@remar/shared/dist/utils/stateUtils";

import { isToday } from "date-fns";
import { RootState } from "store";
import { emit } from "store/features/notifications/notifications.slice";
import { UserModelDto, assetsService, studentsService, usersService } from "store/services";

type StudentStat = {
	totalStudents: number;
	totalRecurring: number;
	courses: {
		courseId: number;
		courseName: string;
		recurringUsersCount: number;
		trialUsersCount: number;
		initialUsersCount: number;
		totalUsersCount: number;
	}[];
};

interface StudentState {
	students: UserModelDto[] | null;
	page: number;
	perPage: number;
	totalItems: number;
	isLoading: boolean;
	errorMessage: string;
	manageSubscriptionLoading: boolean;
	changeEmailLoading: boolean;
	addDaysLoading: boolean;
	studentAccountInfo: IStudentAccountInfo | null;
	studentSubscription: UserGetSubscriptionInfoResponseDto | null;
	studentAccountLoading: boolean;
	paymentHistoryLoading: boolean;
	paymentHistory: IStudentPaymentHistory | null;
	changeSubStartDateLoading: boolean;
	changeSubStartTodayLoading: boolean;
	loadingStudentReports: boolean;
	loadingStudentLoginReports: boolean;
	loginReports: number[];
	studentReport: IStudentReport | null;
	loadingStudentTests: boolean;
	studentTests: IUserCustomTestResponse | null;
	courseCompletion: IUserCourseCompletionDetailForAdmin | null;
	courseCompletionLoading: boolean;
	catQuotaLoading: boolean;
	catQuota: {
		totalAvailable: number;
		totalUsed: number;
	} | null;
	addCatQuotaLoading: boolean;
	isStudentsCountLoading: boolean;
	pauseResumeSubLoading: boolean;
	studentStats?: StudentStat;
	isLoadingCertificateLogs: boolean;
	certificateLogs: ICertificate[];
	resetUserPasswordLoadingId: number | null;
	isResettingCourseProgress: boolean;
}

const initialState: StudentState = {
	students: null,
	isLoading: false,
	manageSubscriptionLoading: false,
	changeEmailLoading: false,
	addDaysLoading: false,
	errorMessage: "",
	page: 1,
	perPage: 10,
	totalItems: 0,
	studentAccountInfo: null,
	studentAccountLoading: false,
	paymentHistoryLoading: false,
	paymentHistory: null,
	changeSubStartDateLoading: false,
	changeSubStartTodayLoading: false,
	loadingStudentReports: false,
	loadingStudentLoginReports: false,
	loginReports: [],
	studentReport: null,
	studentSubscription: null,
	loadingStudentTests: false,
	studentTests: null,
	courseCompletion: null,
	courseCompletionLoading: false,
	catQuotaLoading: false,
	catQuota: null,
	addCatQuotaLoading: false,
	isStudentsCountLoading: false,
	pauseResumeSubLoading: false,
	isLoadingCertificateLogs: false,
	certificateLogs: [],
	resetUserPasswordLoadingId: null,
	isResettingCourseProgress: false
};

const utilsResetState = getResetState<StudentState>(initialState);

export const studentSlice = createSlice({
	name: "student",
	initialState,
	reducers: {
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.isLoading = action.payload;
		},
		setManageSubscriptionLoading: (state, action: PayloadAction<boolean>) => {
			state.manageSubscriptionLoading = action.payload;
		},
		setChangeEmailLoading: (state, action: PayloadAction<boolean>) => {
			state.changeEmailLoading = action.payload;
		},
		setAddDaysLoading: (state, action: PayloadAction<boolean>) => {
			state.addDaysLoading = action.payload;
		},
		failed: (state, action: PayloadAction<{ errorMessage: string }>) => {
			state.errorMessage = action.payload.errorMessage;
		},
		clearResetPwdLink: (state, { payload }: PayloadAction<number>) => {
			const index = state.students?.findIndex(s => s.id === payload);
			if (state.students && index! > -1) {
				state.students[index!].resetPwdLink = undefined;
			}
		},
		resetState: utilsResetState,
		setStateValue: utilsSetStateValue
	},
	extraReducers: builder => {
		builder
			.addCase(getTotalStudentsByCourse.pending, state => {
				state.isStudentsCountLoading = true;
			})
			.addCase(getTotalStudentsByCourse.fulfilled, (state, { payload }) => {
				state.studentStats = payload.reduce(
					(acc, curr) => {
						acc.totalStudents += curr.totalUsersCount;
						acc.totalRecurring += curr.recurringUsersCount;
						acc.courses.push(curr);
						return acc;
					},
					{ totalRecurring: 0, totalStudents: 0, courses: [] } as StudentStat
				);

				state.isStudentsCountLoading = false;
			})
			.addCase(getTotalStudentsByCourse.rejected, (state, { payload }) => {
				state.isStudentsCountLoading = false;
				state.errorMessage = payload.message;
			})
			.addCase(getAllStudents.pending, state => {
				state.isLoading = true;
			})
			.addCase(getAllStudents.fulfilled, (state, { payload: { items, page, perPage, totalItems } }) => {
				state.page = page;
				state.perPage = perPage;
				state.students = items;
				state.totalItems = totalItems;
				state.isLoading = false;
			})
			.addCase(getAllStudents.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.errorMessage = payload.message;
			})
			.addCase(getStudentAccount.pending, state => {
				state.studentAccountLoading = true;
			})
			.addCase(getStudentAccount.fulfilled, (state, { payload }) => {
				state.studentAccountInfo = payload.account;
				state.studentSubscription = payload.subscription;
				state.studentAccountLoading = false;
			})
			.addCase(getStudentAccount.rejected, state => {
				state.studentAccountLoading = false;
			})
			.addCase(getStudentPaymentHistory.pending, state => {
				state.paymentHistoryLoading = true;
			})
			.addCase(getStudentPaymentHistory.fulfilled, (state, action) => {
				state.paymentHistoryLoading = false;
				state.paymentHistory = action.payload;
			})
			.addCase(getStudentPaymentHistory.rejected, state => {
				state.paymentHistoryLoading = false;
			})
			.addCase(changeSubStartDate.pending, (state, { meta: { arg } }) => {
				const { startDate } = arg;
				if (isToday(new Date(startDate))) {
					state.changeSubStartTodayLoading = true;
				} else {
					state.changeSubStartDateLoading = true;
				}
			})
			.addCase(changeSubStartDate.fulfilled, state => {
				state.changeSubStartDateLoading = false;
				state.changeSubStartTodayLoading = false;
			})
			.addCase(changeSubStartDate.rejected, state => {
				state.changeSubStartDateLoading = false;
				state.changeSubStartTodayLoading = false;
			})
			.addCase(getStudentReport.pending, state => {
				state.loadingStudentReports = true;
			})
			.addCase(
				getStudentReport.fulfilled,
				(
					state,
					{
						payload: {
							coursesProgress,
							quizzesProgress,
							quizzesPassed,
							quizzesFailed,
							quizPassingPercentage,
							loginCount,
							timeSpentTrainingInMinutes,
							subscriptions,
							testPassingPercentage
						}
					}
				) => {
					state.studentReport = {
						coursesProgress,
						quizzesProgress,
						quizzesPassed,
						quizzesFailed,
						quizPassingPercentage,
						loginCount,
						timeSpentTrainingInMinutes,
						locationPackageTypeId: subscriptions[0]?.type?.allowedLocationPackages[0]?.locationPackageTypeId,
						testPassingPercentage
					};
					state.loadingStudentReports = false;
				}
			)
			.addCase(getStudentReport.rejected, state => {
				state.loadingStudentReports = false;
			})
			.addCase(getStudentLoginReport.pending, state => {
				state.loadingStudentLoginReports = true;
			})
			.addCase(getStudentLoginReport.fulfilled, (state, { payload: { dataPoints } }) => {
				state.loadingStudentLoginReports = false;
				state.loginReports = dataPoints;
			})
			.addCase(getStudentLoginReport.rejected, state => {
				state.loadingStudentLoginReports = false;
			})
			.addCase(getStudentTests.pending, state => {
				state.loadingStudentTests = true;
			})
			.addCase(getStudentTests.fulfilled, (state, { payload }) => {
				state.loadingStudentTests = false;
				state.studentTests = payload;
			})
			.addCase(getStudentTests.rejected, state => {
				state.loadingStudentTests = false;
			})
			.addCase(getStudentTest.pending, (state, action) => {
				if (state.studentTests) {
					const { testId } = action.meta.arg;
					const testIndex = state.studentTests?.items.findIndex(t => t.id === testId);
					if (testIndex > -1) {
						state.studentTests.items[testIndex].isLoadingResults = true;
					}
				}
			})
			.addCase(getStudentTest.fulfilled, (state, action) => {
				state.loadingStudentTests = false;
				if (state.studentTests) {
					const { testId } = action.meta.arg;
					const testIndex = state.studentTests?.items.findIndex(t => t.id === testId);
					if (testIndex > -1) {
						state.studentTests.items[testIndex].isLoadingResults = false;
					}
				}
			})
			.addCase(getStudentTest.rejected, (state, action) => {
				if (state.studentTests) {
					const { testId } = action.meta.arg;
					const testIndex = state.studentTests?.items.findIndex(t => t.id === testId);
					if (testIndex > -1) {
						state.studentTests.items[testIndex].isLoadingResults = false;
					}
				}
			})
			.addCase(getStudentCourseCompletion.pending, state => {
				state.courseCompletionLoading = true;
			})
			.addCase(getStudentCourseCompletion.fulfilled, (state, { payload }) => {
				state.courseCompletionLoading = false;
				state.courseCompletion = payload;
			})
			.addCase(getStudentCourseCompletion.rejected, state => {
				state.courseCompletionLoading = false;
			})
			.addCase(getStudentCATQuota.pending, state => {
				state.catQuotaLoading = true;
			})
			.addCase(getStudentCATQuota.fulfilled, (state, { payload }) => {
				state.catQuotaLoading = false;
				const { totalUsed, totalAvailable } = payload;
				state.catQuota = {
					totalAvailable,
					totalUsed
				};
			})
			.addCase(getStudentCATQuota.rejected, state => {
				state.catQuotaLoading = false;
			})
			.addCase(addStudentCATQuota.pending, state => {
				state.addCatQuotaLoading = true;
			})
			.addCase(addStudentCATQuota.fulfilled, state => {
				state.addCatQuotaLoading = false;
			})
			.addCase(addStudentCATQuota.rejected, state => {
				state.addCatQuotaLoading = false;
			})
			.addCase(pauseSubscription.pending, state => {
				state.pauseResumeSubLoading = true;
			})
			.addCase(pauseSubscription.fulfilled, state => {
				state.pauseResumeSubLoading = false;
			})
			.addCase(pauseSubscription.rejected, state => {
				state.pauseResumeSubLoading = false;
			})
			.addCase(resumeSubscription.pending, state => {
				state.pauseResumeSubLoading = true;
			})
			.addCase(resumeSubscription.fulfilled, state => {
				state.pauseResumeSubLoading = false;
			})
			.addCase(resumeSubscription.rejected, state => {
				state.pauseResumeSubLoading = false;
			})
			.addCase(fetchStudentCertificateLogs.pending, state => {
				state.isLoadingCertificateLogs = true;
			})
			.addCase(fetchStudentCertificateLogs.fulfilled, (state, { payload }) => {
				state.isLoadingCertificateLogs = false;
				state.certificateLogs = payload.items as unknown as ICertificate[];
			})
			.addCase(fetchStudentCertificateLogs.rejected, state => {
				state.isLoadingCertificateLogs = false;
			})
			.addCase(resetUserPassword.pending, (state, { meta: { arg } }) => {
				state.resetUserPasswordLoadingId = arg;
			})
			.addCase(resetUserPassword.fulfilled, (state, { meta: { arg }, payload }) => {
				const index = state.students?.findIndex(s => s.id === arg);
				if (state.students && index! > -1) {
					state.students[index!].resetPwdLink = payload.url;
				}
				state.resetUserPasswordLoadingId = null;
			})
			.addCase(resetUserPassword.rejected, state => {
				state.resetUserPasswordLoadingId = null;
			})
			.addCase(resetCourseProgress.pending, state => {
				state.isResettingCourseProgress = true;
			})
			.addCase(resetCourseProgress.fulfilled, state => {
				state.isResettingCourseProgress = false;
			})
			.addCase(resetCourseProgress.rejected, state => {
				state.isResettingCourseProgress = false;
			});
	}
});

export const getAllStudents = createAsyncThunk(
	"students/getAllStudents",
	async (
		{
			page: optPage,
			perPage: optPerPage,
			searchText,
			selectedCountries,
			selectedLocations
		}: {
			page?: number;
			perPage?: number;
			searchText?: string;
			selectedCountries?: Country[];
			selectedLocations?: ILocation[];
		},
		{ getState, rejectWithValue }
	) => {
		const { page, perPage } = (getState() as RootState).students;

		const payload = {
			page: optPage || page,
			perPage: optPerPage || perPage,
			orderBy: { createdAt: "DESC" },
			include: ["userShippingDetails", "subscriptions.type.allowedCourses", "badges"],
			searchKeyword: searchText || ""
		};

		if (selectedCountries) {
			payload["filters[userShippingDetails.countryId]"] = selectedCountries.map(({ id }) => id);
		}

		if (selectedLocations) {
			payload["filters[allowedLocations.id]"] = selectedLocations?.map(({ id }) => id);
		}

		return await studentsService.find(payload).catch(rejectWithValue);
	}
);

export const cancelSubscription = createAsyncThunk(
	"students/cancelSubscription",
	async (
		options: {
			subscriptionId: number;
			refund: boolean;
			amount?: number;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { subscriptionId, amount, sideEffect, refund } = options;
		try {
			const { manageSubscriptionLoading } = (getState() as { students: StudentState }).students;
			if (!manageSubscriptionLoading) {
				dispatch(setManageSubscriptionLoading(true));
			}
			await studentsService.cancelSubscription({ subscriptionId, ...(refund && { amount: Number(amount) }) });
			dispatch(setManageSubscriptionLoading(false));
			sideEffect("Subscription Cancelled", "success");
		} catch (e) {
			dispatch(setManageSubscriptionLoading(false));
			sideEffect(e, "error");
			return { error: e };
		}
	}
);

export const assignCourseSubscription = createAsyncThunk(
	"students/assignCourse",
	async (
		options: {
			subscriptionTypeId: number;
			refund: boolean;
			userId: number;
			amount?: number;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { subscriptionTypeId, amount, sideEffect, refund, userId } = options;
		const { manageSubscriptionLoading } = (getState() as { students: StudentState }).students;
		if (!manageSubscriptionLoading) {
			dispatch(setManageSubscriptionLoading(true));
		}
		studentsService
			.subscriptionAssignCourse({
				subscriptionTypeId,
				userId,
				...(refund && { amount: Number(amount) })
			})
			.then(() => {
				sideEffect("Course has been assigned", "success");
			})
			.catch(e => {
				sideEffect(e, "error");
			})
			.finally(() => {
				dispatch(setManageSubscriptionLoading(false));
			});
	}
);

export const changeEmail = createAsyncThunk(
	"students/changeEmail",
	async (
		options: {
			payload;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { payload, sideEffect } = options;
		try {
			const { changeEmailLoading } = (getState() as { students: StudentState }).students;
			if (!changeEmailLoading) {
				dispatch(setChangeEmailLoading(true));
			}
			await studentsService.changeEmail(payload);
			dispatch(setChangeEmailLoading(false));
			sideEffect("Email Changed", "success");
			dispatch(setChangeEmailLoading(false));
		} catch (e) {
			dispatch(setChangeEmailLoading(false));
			sideEffect(e, "error");
			return { error: e };
		}
	}
);

export const addDaysInSubscription = createAsyncThunk(
	"students/addDaysInSubscription",
	async (
		options: {
			payload;
			// eslint-disable-next-line no-unused-vars
			sideEffect: (e: string | unknown, notificationType: string) => void;
		},
		{ dispatch, getState }
	) => {
		const { payload, sideEffect } = options;
		try {
			const { addDaysLoading } = (getState() as { students: StudentState }).students;
			if (!addDaysLoading) {
				dispatch(setAddDaysLoading(true));
			}
			await studentsService.addDays(payload);
			dispatch(setAddDaysLoading(false));
			sideEffect("Days added", "success");
			dispatch(setAddDaysLoading(false));
		} catch (e) {
			dispatch(setAddDaysLoading(false));
			sideEffect(e, "error");
			return { error: e };
		}
	}
);

export const getStudentAccount = createAsyncThunk(
	"students/getStudentAccount",
	async ({ id }: { id: string }, { rejectWithValue }) => {
		try {
			const account = await usersService.getAccount(id);
			const subscription = await usersService.getSubscription(id);
			return { account, subscription };
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const changeSubStartDate = createAsyncThunk(
	"students/changeSubStartDate",
	async (
		{
			userId,
			subscriptionId,
			startDate,
			cb
		}: { userId: string; subscriptionId: number; startDate: string; cb: () => void },
		{ dispatch, rejectWithValue }
	) => {
		return await studentsService
			.changeSubStartDate({ userId, subscriptionId, startDate })
			.then(() => {
				dispatch(emit({ message: "Start Date has been changed successfully", color: "success" }));
				cb();
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			});
	}
);

export const pauseSubscription = createAsyncThunk(
	"students/pauseSubscription",
	async (
		{ userId, noOfDays, cb }: { userId: number; noOfDays: number; cb: () => void },
		{ dispatch, rejectWithValue }
	) => {
		return await studentsService
			.pauseSubscription({ userId, noOfDays })
			.then(() => {
				dispatch(emit({ message: "Subscription has been paused successfully", color: "success" }));
				cb();
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			});
	}
);

export const resumeSubscription = createAsyncThunk(
	"students/resumeSubscription",
	async ({ userId, cb }: { userId: number; cb: () => void }, { dispatch, rejectWithValue }) => {
		return await studentsService
			.resumeSubscription({ userId })
			.then(() => {
				dispatch(emit({ message: "Subscription has been resumed successfully", color: "success" }));
				cb();
			})
			.catch(e => {
				dispatch(emit({ message: e.message, color: "error" }));
				return rejectWithValue(e.message);
			});
	}
);

export const getStudentPaymentHistory = createAsyncThunk(
	"students/getStudentPaymentHistory",
	async ({ id, page }: { id: string; page: number }, { rejectWithValue }) => {
		const query = { page: page ? page : 1 };
		return await usersService.getPaymentHistory(id, query).catch(e => rejectWithValue(e.message));
	}
);

export const getStudentReport = createAsyncThunk(
	"students/getStudentReport",
	async ({ userId, filters }: { userId: string; filters: IDateFilters }, { rejectWithValue }) => {
		return await usersService.getStudentReport(userId, filters).catch(e => rejectWithValue(e.message));
	}
);

export const getStudentLoginReport = createAsyncThunk(
	"students/getStudentLoginReport",
	async ({ userId, filters }: { userId: string; filters: ILoginReportFilter }, { rejectWithValue }) => {
		return await usersService.getStudentLoginReport(userId, filters).catch(e => rejectWithValue(e.message));
	}
);

export const getStudentTests = createAsyncThunk(
	"students/getStudentTests",
	async (
		{ userId, typeId, page = 1, perPage = 10 }: { userId: string; typeId?: string[]; page?: number; perPage?: number },
		{ rejectWithValue }
	) => {
		const filters = {};
		if (typeId) {
			filters["typeId"] = typeId;
		}
		return await usersService
			.getStudentTests(userId, {
				filters,
				include: ["userQuestionAttempts"],
				orderBy: { createdAt: "DESC" },
				page,
				perPage
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const getStudentTest = createAsyncThunk(
	"students/getStudentTest",
	async (
		{
			userId,
			testId,
			sideEffect
		}: { userId: string; testId: number; sideEffect: (test: IUserCustomTestResponse) => void },
		{ rejectWithValue }
	) => {
		try {
			const test = await usersService.getStudentTest(userId, testId);
			sideEffect(test);
			return test;
		} catch (e) {
			return rejectWithValue(e.message);
		}
	}
);

export const getStudentCourseCompletion = createAsyncThunk(
	"students/getStudentCourseCompletion",
	async ({ userId, courseId }: { userId: number; courseId: number }, { rejectWithValue }) => {
		return await usersService.getStudentCourseCompletion(userId, courseId).catch(rejectWithValue);
	}
);

export const getStudentCATQuota = createAsyncThunk(
	"students/getStudentCATQuota",
	async ({ userId }: { userId: number }, { rejectWithValue }) => {
		return await usersService.getStudentCATQuota(userId).catch(rejectWithValue);
	}
);

export const fetchStudentCertificateLogs = createAsyncThunk(
	"students/fetchStudentCertificateLogs",
	async ({ userId, typeId }: { userId: number; typeId: number }, { rejectWithValue }) =>
		await assetsService.find({ orderBy: { createdAt: "DESC" }, filters: { userId, typeId } }).catch(rejectWithValue)
);

export const addStudentCATQuota = createAsyncThunk(
	"students/addStudentCATQuota",
	async (
		{ userId, quantity, cb }: { userId: number; quantity: number; cb: () => void },
		{ dispatch, rejectWithValue }
	) => {
		try {
			const res = await usersService.addStudentCATQuota(userId, quantity);
			dispatch(emit({ message: "CAT exams have been added successfully", color: "success" }));
			cb();
			return res;
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
			return rejectWithValue(e.message);
		}
	}
);

export const getTotalStudentsByCourse = createAsyncThunk(
	"students/getTotalStudentsByCourse",
	async (_, { rejectWithValue }) => {
		return await usersService.getTotalStudentsByCourse().catch(rejectWithValue);
	}
);

export const resetUserPassword = createAsyncThunk(
	"students/resetUserPassword",
	async (userId: number, { dispatch, rejectWithValue }) => {
		try {
			const r = await usersService.resetPassword(userId);
			dispatch(emit({ message: "Password reset link has been sent to the user", color: "success" }));
			return r;
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
			return rejectWithValue(e.message);
		}
	}
);

export const resetCourseProgress = createAsyncThunk(
	"students/resetCourseProgress",
	async ({ userId, cb }: { userId: number; cb: () => void }, { dispatch, rejectWithValue }) => {
		try {
			const r = await usersService.resetCourseProgress(userId);
			dispatch(emit({ message: "Course progress has been successfully rested ", color: "success" }));
			cb();
			return r;
		} catch (e) {
			dispatch(emit({ message: e.message, color: "error" }));
			return rejectWithValue(e.message);
		}
	}
);

export function getFullState(state: RootState): StudentState {
	return state.students;
}

export const {
	setLoading,
	setManageSubscriptionLoading,
	setChangeEmailLoading,
	setAddDaysLoading,
	clearResetPwdLink,
	failed,
	setStateValue,
	resetState
} = studentSlice.actions;

export default studentSlice.reducer;
