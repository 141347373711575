import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "store";

import { ICouponProducts, ICouponTypes, ICoupons } from "./models";

import { couponService } from "../../services/coupons/coupons.service";
import { CouponCreateDto, CouponEditDto } from "../../services/coupons/dto/coupon.create.dto";
import { emit } from "../notifications/notifications.slice";

interface IInitialState {
	couponTypes: ICouponTypes | null;
	loadingCouponTypes: boolean;
	couponProducts: ICouponProducts | null;
	loadingCouponProducts: boolean;
	loadCreateCoupon: boolean;
	loadDeleteCoupon: boolean;
	couponsLoading: boolean;
	Coupons: ICoupons | null;
}

const initialState: IInitialState = {
	couponTypes: null,
	loadingCouponTypes: false,
	couponProducts: null,
	loadingCouponProducts: false,
	loadCreateCoupon: false,
	loadDeleteCoupon: false,
	couponsLoading: false,
	Coupons: null
};

export const getCoupons = createAsyncThunk(
	"coupon/getCoupons",
	async ({ page = 1, searchText }: { page?: number; searchText?: string }, { rejectWithValue }) => {
		const filters = {};
		if (searchText) {
			filters["name"] = {
				$ilike: `%${searchText}%`
			};
		}
		return await couponService
			.find({
				orderBy: { id: "DESC" },
				perPage: 10,
				page,
				include: ["products", "courses", "type"],
				filters
			})
			.catch(e => rejectWithValue(e.message));
	}
);

export const getCouponTypes = createAsyncThunk("coupon/getCouponTypes", async (_, { rejectWithValue }) => {
	return await couponService.getCouponTypes().catch(e => rejectWithValue(e.message));
});

export const getCouponProducts = createAsyncThunk("coupon/getCouponProducts", async (_, { rejectWithValue }) => {
	return await couponService.getCouponProducts().catch(e => rejectWithValue(e.message));
});

type CouponActionArgs<T extends Partial<CouponCreateDto>> = {
	body: T;
	cb: () => void;
};

export const createCoupon = createAsyncThunk(
	"coupon/createCoupon",
	async ({ body, cb }: CouponActionArgs<CouponCreateDto>, { rejectWithValue, dispatch }) => {
		return await couponService
			.createCoupon(body)
			.then(res => {
				cb();
				dispatch(emit({ message: "Coupon has been created successfully", color: "success" }));
				return res;
			})
			.catch(({ message }) => {
				dispatch(emit({ message: message, color: "error" }));
				return rejectWithValue(message);
			});
	}
);

export const updateCoupon = createAsyncThunk(
	"coupon/updateCoupon",
	async ({ body, cb }: CouponActionArgs<CouponEditDto>, { dispatch, rejectWithValue }) => {
		const { id, ...data } = body;
		return await couponService
			.update({ filters: { id }, data })
			.then(r => {
				cb();
				dispatch(emit({ message: "Coupon has been updated successfully", color: "success" }));
				return r;
			})
			.catch(({ message }) => {
				dispatch(emit({ message: `Coupon update failed: ${message}`, color: "error" }));
				return rejectWithValue(message);
			});
	}
);

export const deleteCoupon = createAsyncThunk(
	"coupon/deleteCoupon",
	async (id: number, { dispatch, rejectWithValue }) => {
		return await couponService
			.delete({ filters: { id } })
			.then(r => {
				dispatch(emit({ message: "Coupon has been deleted successfully", color: "success" }));
				dispatch(getCoupons({}));
				return r;
			})
			.catch(({ message }) => {
				dispatch(emit({ message: `Coupon delete failed: ${message}`, color: "error" }));
				return rejectWithValue(message);
			});
	}
);

export const couponSlice = createSlice({
	name: "coupon",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder
			.addCase(getCouponTypes.pending, state => {
				state.loadingCouponTypes = true;
			})
			.addCase(getCouponTypes.fulfilled, (state, action) => {
				state.loadingCouponTypes = false;
				state.couponTypes = action.payload;
			})
			.addCase(getCouponTypes.rejected, state => {
				state.loadingCouponTypes = false;
			})
			.addCase(getCouponProducts.pending, state => {
				state.loadingCouponProducts = true;
			})
			.addCase(getCouponProducts.fulfilled, (state, action) => {
				state.loadingCouponProducts = false;
				state.couponProducts = action.payload;
			})
			.addCase(getCouponProducts.rejected, state => {
				state.loadingCouponProducts = false;
			})
			.addCase(createCoupon.pending, state => {
				state.loadCreateCoupon = true;
			})
			.addCase(createCoupon.fulfilled, state => {
				state.loadCreateCoupon = false;
			})
			.addCase(createCoupon.rejected, state => {
				state.loadCreateCoupon = false;
			})
			.addCase(getCoupons.pending, state => {
				state.couponsLoading = true;
			})
			.addCase(getCoupons.fulfilled, (state, action) => {
				state.Coupons = action.payload;
				if (!state.Coupons.totalCount) {
					state.Coupons.totalCount = action.payload.totalItems;
				}
				state.couponsLoading = false;
			})
			.addCase(getCoupons.rejected, state => {
				state.couponsLoading = false;
			})
			.addCase(deleteCoupon.pending, state => {
				state.loadDeleteCoupon = true;
			})
			.addCase(deleteCoupon.fulfilled, state => {
				state.loadDeleteCoupon = false;
			});
	}
});

export const {} = couponSlice.actions;

export const selectCouponFullState = (state: RootState): IInitialState => state.coupons;

export default couponSlice.reducer;
