import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, SvgIcon, Typography } from "@material-ui/core";

import { ReactComponent as IconEquals } from "@remar/shared/dist/assets/icons/icon-equals.svg";
import HeaderContainer from "@remar/shared/dist/components/HeaderContainer/HeaderContainer";
import { UserSubscriptionTypeCategoriesEnum } from "@remar/shared/dist/constants";
import { Package as LocationPackage } from "@remar/shared/dist/models";
import AddButton from "@remar/shared/dist/ui/Buttons/AddButton";
import copyPasteOnlyInput from "@remar/shared/dist/utils/copyPasteOnlyInput";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PAYMENT_CONSTANTS } from "store/features/CourseAddEdit/courseAddEdit.constants";
import {
	ManagePackageState,
	addPackageFeature,
	deletePackageFeature,
	fetchStripeData,
	getFullState,
	initializeForm,
	resetState,
	saveForm,
	setAddonItem,
	setStateValue,
	updatePackageFeatures,
	validateForm
} from "store/features/ManagePackage/managePackage.slice";

import { _emit } from "store/features/notifications/notifications.slice";

import { routes } from "core/constants";

import theme from "theme/default";

import { mapLocationPackage } from "./managePackageUtils";

import {
	CourseFeatures,
	DeleteFeature,
	DragFeatureContainer,
	FeatureText
} from "../../CourseAddEdit/courseAddEdit.styles";
import { CustomInput } from "../../FileVault/styles";
import { CheckedIcon, Spacer } from "../PackageOverview/styles";
import Package from "../components";
import Books from "../components/Books";

import { Container } from "../styles";

const breadcrumb = [
	{ title: "Dashboard", key: 0, link: "/" },
	{ title: "Manage Locations", key: 1, link: routes.manageLocations.getPath() },
	{ title: "Package Overview", key: 2, link: `${routes.manageLocations.getPath()}/package-overview` },
	{ title: "Manage Package", key: 3 }
];
const EDIT_MODE = "edit";
const getFeature = value => {
	return value.trim().length !== 0;
};
const ManagePackage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [isAddFeature, setIsAddFeature] = useState(false);
	const defaultCustomInputOptions = { _emit, dispatch, setStateValue, validateForm };
	const { courseId, locationPackageTypeId, action } = useParams<{
		courseId: string;
		locationPackageTypeId: string;
		action: string;
	}>();

	const {
		managePackageForm,
		packageFeaturesForm,
		userSubscriptionTypesForm,
		userSubscriptionTypesFormPerSeat,
		data: { description }
	} = useSelector(getFullState) as ManagePackageState;
	const { inputs: managePackageInputs } = managePackageForm;
	const { inputs: packageFeaturesFormInputs } = packageFeaturesForm;

	const { inputs: ustFormInputs } = userSubscriptionTypesForm;
	const { inputs: ustFormInputsPerSeat } = userSubscriptionTypesFormPerSeat;
	const { initial, recurring } = ustFormInputs;
	const { initial: initialPerSeat, recurring: recurringPerSeat } = ustFormInputsPerSeat;
	const { stripeItem: initialStripeItem } = initial;
	const initialPriceValue = initialStripeItem.price.value;
	const initialDuration = initialStripeItem.duration.value;
	const initialIsLoading = initialStripeItem.isLoading.value;
	const { stripeItem: recurringStripeItem } = recurring;
	const recurringPriceValue = recurringStripeItem.price.value;
	const recurringIsLoading = recurringStripeItem.isLoading.value;
	const recurringDuration = recurringStripeItem.duration.value;
	const { stripeItem: initialStripeItemPerSeat, addons: perSeatAddons } = initialPerSeat;
	const initialPriceValuePerSeat = initialStripeItemPerSeat.price.value;
	const initialIsLoadingPerSeat = initialStripeItemPerSeat.isLoading.value;
	const initialDurationPerSeat = initialStripeItemPerSeat.duration.value;
	const { stripeItem: recurringStripeItemPerSeat } = recurringPerSeat;
	const recurringIsLoadingPerSeat = recurringStripeItemPerSeat.isLoading.value;
	const recurringPriceValuePerSeat = recurringStripeItemPerSeat.price.value;
	const recurringDurationPerSeat = recurringStripeItemPerSeat.duration.value;
	const locationState = location.state as LocationPackage;
	let descriptionPointId = 0;
	const addons = perSeatAddons
		?.filter(f => (f.isDigital.value ? f.digitalAssetItem.deleted.value : f.shipStationItem.deleted.value) == false)
		.map(item => {
			const {
				shipStationItem: { name: skuName, isLoading: skuLoader },
				digitalAssetItem: { name: digitalAssetName, isLoading: digitalAssetLoader },
				isDigital
			} = item;
			return {
				name: isDigital.value ? digitalAssetName.value : skuName.value,
				loader: isDigital.value ? digitalAssetLoader.value : skuLoader.value
			};
		});
	const memoizedDescriptionPoints = useMemo(() => {
		if (description.length !== 0) {
			return description.map(item => {
				return { id: descriptionPointId++, feature: item };
			});
		}
	}, [description]);
	useEffect(() => {
		if (action === EDIT_MODE) {
			const mappedPackageState = mapLocationPackage(locationState);
			dispatch(initializeForm(mappedPackageState));

			const subscriptionType = locationState.allowedUST.find(
				f =>
					f.userSubscriptionTypeCategoryId === UserSubscriptionTypeCategoriesEnum.LocationPerSeat &&
					f.isRecurring == false &&
					f.isActive == true
			);
			dispatch(setAddonItem(subscriptionType!));
		}
	}, [action]);
	useEffect(() => {
		return () => {
			dispatch(resetState());
		};
	}, [dispatch]);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}
		const items = reorder(description, result.source.index, result.destination.index) as {
			id: number;
			feature: string;
		}[];
		dispatch(updatePackageFeatures(items));
	};

	const sideEffect = () => {
		history.push(`${routes.manageLocations.getPath()}/package-overview`);
	};

	return (
		<>
			<Container>
				<HeaderContainer heading={"Manage Package"} breadcrumb={breadcrumb} />

				<Box display={"flex"} mr={2} gridGap={"20px"}>
					<Box flex={2}>
						<Card>
							<Box>
								<Box>
									<Typography>Package Name</Typography>
								</Box>
								<CustomInput
									width={400}
									theme={theme}
									options={{ ...defaultCustomInputOptions, inputData: managePackageInputs.packageName }}
								/>
							</Box>
							<Box display={"flex"} mt={2}>
								<Box width={"50%"} mr={1}>
									<Box>
										<Typography>Package Price</Typography>
									</Box>
									<CustomInput
										theme={theme}
										inputProps={{
											onChange: e =>
												dispatch(
													fetchStripeData(e.target.value, PAYMENT_CONSTANTS.INITIAL, "userSubscriptionTypesForm")
												),
											onKeyPress: copyPasteOnlyInput
										}}
										options={{ ...defaultCustomInputOptions, inputData: initialStripeItem.planId }}
									/>
								</Box>
								<Box width={"50%"}>
									<Box>
										<Typography>Recurring Monthly Package Price</Typography>
									</Box>
									<CustomInput
										width={400}
										theme={theme}
										inputProps={{
											onChange: e =>
												dispatch(
													fetchStripeData(e.target.value, PAYMENT_CONSTANTS.RECURRING, "userSubscriptionTypesForm")
												),
											onKeyPress: copyPasteOnlyInput
										}}
										options={{ ...defaultCustomInputOptions, inputData: recurringStripeItem.planId }}
									/>
								</Box>
							</Box>
							<Box display={"flex"} mt={2}>
								<Box width={"50%"} mr={1}>
									<Box>
										<Typography>Price Per Seat</Typography>
									</Box>
									<CustomInput
										width={400}
										theme={theme}
										inputProps={{
											onChange: e =>
												dispatch(
													fetchStripeData(e.target.value, PAYMENT_CONSTANTS.INITIAL, "userSubscriptionTypesFormPerSeat")
												),
											onKeyPress: copyPasteOnlyInput
										}}
										options={{
											...defaultCustomInputOptions,
											inputData: initialStripeItemPerSeat.planId
										}}
									/>
								</Box>
								<Box width={"50%"}>
									<Box>
										<Typography>Recurring Monthly Seat Price</Typography>
									</Box>
									<CustomInput
										width={400}
										theme={theme}
										inputProps={{
											onChange: e =>
												dispatch(
													fetchStripeData(
														e.target.value,
														PAYMENT_CONSTANTS.RECURRING,
														"userSubscriptionTypesFormPerSeat"
													)
												),
											onKeyPress: copyPasteOnlyInput
										}}
										options={{
											...defaultCustomInputOptions,
											inputData: recurringStripeItemPerSeat.planId
										}}
									/>
								</Box>
							</Box>
							<Box mt={3}>
								<Spacer />
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="droppable">
										{provided => (
											<Box {...provided.droppableProps} ref={provided.innerRef}>
												{memoizedDescriptionPoints?.map(keyItem => (
													<Draggable key={keyItem.id} draggableId={`${keyItem.id}`} index={keyItem.id}>
														{provided => (
															<DragFeatureContainer
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															>
																<Box display={"flex"} alignItems="center">
																	<CheckedIcon fontSize="small" key={keyItem.id} />
																	<CourseFeatures>
																		<FeatureText title={keyItem.feature}>{keyItem.feature}</FeatureText>
																		<Box>
																			{" "}
																			<SvgIcon fontSize="small" cursor="grab">
																				<IconEquals />
																			</SvgIcon>{" "}
																			<DeleteFeature onClick={() => dispatch(deletePackageFeature(keyItem.feature))} />
																		</Box>
																	</CourseFeatures>
																</Box>
															</DragFeatureContainer>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</Box>
										)}
									</Droppable>
								</DragDropContext>
								{isAddFeature && (
									<Box ml={2} width={"50%"}>
										<CustomInput
											width={600}
											theme={theme}
											onBlur={e => {
												getFeature(e.target.value) && dispatch(addPackageFeature(e.target.value));
												getFeature(e.target.value) && setIsAddFeature(false);
											}}
											options={{ ...defaultCustomInputOptions, inputData: packageFeaturesFormInputs.feature }}
										/>
									</Box>
								)}
							</Box>
							{description.length !== 9 && (
								<AddButton
									onClick={() => {
										setIsAddFeature(true);
									}}
									label={"Add Feature"}
								/>
							)}
						</Card>
						<Books addons={perSeatAddons} />
					</Box>
					<Box>
						<Package
							packageName={managePackageInputs.packageName.value}
							initialIsLoading={initialIsLoading}
							recurringIsLoading={recurringIsLoading}
							initialIsLoadingPerSeat={initialIsLoadingPerSeat}
							recurringIsLoadingPerSeat={recurringIsLoadingPerSeat}
							initialPrice={initialPriceValue ? `$${initialPriceValue?.toFixed(2)}` : "-"}
							recurringPrice={recurringPriceValue ? `$${recurringPriceValue?.toFixed(2)}` : "-"}
							initialPricePerSeat={initialPriceValuePerSeat ? `$${initialPriceValuePerSeat?.toFixed(2)}` : "-"}
							recurringPricePerSeat={recurringPriceValuePerSeat ? `$${recurringPriceValuePerSeat?.toFixed(2)}` : "-"}
							locationPackageTypeId={+locationPackageTypeId}
							description={description}
							showStatus={false}
							showEdit={false}
							showDeletePackage={false}
							initialDuration={initialDuration}
							recurringDuration={recurringDuration}
							initialDurationPerSeat={initialDurationPerSeat}
							recurringDurationPerSeat={recurringDurationPerSeat}
							showActionButtons
							addons={addons}
							saveAction={() =>
								saveForm({ courseId: +courseId, locationPackageTypeId: +locationPackageTypeId, sideEffect })
							}
						/>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default ManagePackage;
