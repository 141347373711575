import React, { useState } from "react";

import { InputAdornment, TextField as MuiTextField, useTheme } from "@material-ui/core";

import { DatePicker } from "@remar/shared/dist/components/DatePicker";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { format } from "date-fns";

import { StyledCalenderIcon } from "./styles";
const TextField = props => <MuiTextField {...props} variant="filled" hiddenLabel />;
const DatePickerComponent = ({ name, value, onChange }) => {
	const theme = useTheme<IExtendedTheme>();
	const [isDateOpen, setIsDateOpen] = useState(false);

	return (
		<DatePicker
			onClose={() => setIsDateOpen(prevState => !prevState)}
			open={isDateOpen}
			name={name}
			fullWidth={true}
			autoOk
			format="EEE, dd MMM yyyy"
			value={value}
			minDate={new Date()}
			disableToolbar
			orientation="portrait"
			openTo="date"
			onChange={onChange}
			TextFieldComponent={() => (
				<TextField
					style={{ width: "100%" }}
					value={format(value || new Date(), "EEE, dd MMM yyyy")}
					disabled={false}
					placeholder="MM/DD/YYYY"
					color="primary"
					InputProps={{
						style: {
							cursor: "pointer",
							color: theme.palette.text.primary,
							backgroundColor: theme.palette.colors.basic[800],
							height: 48,
							borderRadius: 4,
							padding: 10,
							width: "100%"
						},
						disableUnderline: true,
						startAdornment: (
							<InputAdornment position="start">
								<StyledCalenderIcon />
							</InputAdornment>
						),
						onClick: () => setIsDateOpen(prevState => !prevState),
						readOnly: true
					}}
				/>
			)}
		/>
	);
};
export default DatePickerComponent;
